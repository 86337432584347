<template>
  <DonutWidget
      :title-object="{
        title: 'Nombre de régatiers en interséries'
      }"
      :composable="widget"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { regionFilter, yearFilter, clubFilter, ageFilterPS , pratiqueFilter, genreFilterPS  } from '@/composables/useFilters'
import {API_PARAMS, buildDataset, percentComparator} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";


const widget = useWidget('PSClassesInterseriesWidget', {
watchers: [
  yearFilter.apiParameterYears,
  regionFilter.selectedLigue,
  clubFilter.selectedClub,
  ageFilterPS.selectedMinAge,
  ageFilterPS.selectedMaxAge,
  pratiqueFilter.selectedPratique,
  genreFilterPS.selectedGenre
],

query: {
  url: 'indicateurs/ps/regatiers/interseries',
  params: [
      API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
      API_PARAMS.AGE_PS, API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS,
      API_PARAMS.A_DATE,
  ]
},

response: ([comparisonYear, selectedYear]) => {
  const total = {
      value: selectedYear.reduce((a, b) => a + b.nbAthletesInterseries, 0),
      comparator: percentComparator(
        selectedYear.reduce((a, b) => a + b.nbAthletesInterseries, 0),
        comparisonYear.reduce((a, b) => a + b.nbAthletesInterseries, 0)
      )
    }
  const legend = buildDataset(
      selectedYear,
      comparisonYear,
      'secteurGroupe',
      'nbAthletesInterseries',
      true,
      false,
      'nbAthletesInterseries',
      true,
  )

  return {
    total,
    graphData: legend.map(l => ({...l, label: l.legend})),
  }
}
})
</script>