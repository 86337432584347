<template>
  <FilterSimple
      name="Indicateurs"
      :elements="indicateurs.map((i) => ({
        ...i,
        label: i.name,
        isSelected: isIndicatorSelected(i),
      }))"
      :select-element="selectIndicator"
      :reset="resetIndics"
  />
</template>

<script setup>
import { resultatFilter } from '@/composables/useFilters'
import FilterSimple from "@/components/common/FilterSimple.vue";

defineProps({ isOpen: Boolean })

const {
  indicateurs,
  resetIndics,
  isIndicatorSelected,
  selectIndicator,
} = resultatFilter

</script>

<style scoped>
fieldset { width: 100%; }
</style>