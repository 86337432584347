<script setup>
import { toRefs, computed } from 'vue'
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import SimpleIndicator from "@/components/common/SimpleIndicator.vue";
import {yearFilter} from "@/composables/useFilters";

const props = defineProps({
  composable: Object,
  titleObject: Object,
})
const { composable, titleObject } = toRefs(props)

const composableData = computed(() => composable.value.data.value)
const hasError = computed(() => composable.value.error.value !== null)
const hasApiData = computed(() =>  !!composable.value.data.value)
</script>

<template>
  <BaseWidget
      :title-object="{...titleObject, alignLeft: true }"
      :api-error="!hasApiData"
      :front-error="hasError"
      :is-loading="!composable.loaded.value"
  >
    <!-- INDICATOR -->
    <div v-if="composableData.total && !composableData.total.hide" class="flex w-full">
      <SimpleIndicator
          :indicator-value="composableData.total.value"
          :evolution-object="composableData.total.comparator ? {
            comparatorValue: composableData.total.comparator.value,
            isPercent: composableData.total.comparator.percent,
            comparison: composableData.total.comparator.comparison || (yearFilter.singleYearFilter.value - 1).toString()
          } : null"
          :description="composableData.total.description"

      />
    </div>

    <!-- CHART -->
    <slot />
  </BaseWidget>
</template>