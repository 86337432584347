<template>
  <BaseChartWidget :composable="composable">
    <!-- DONUT -->
    <div
        v-show="
          !!composableData.graphData"
        class="flex justify-center items-center gap-1 flex-wrap-reverse"
        :class="{'flex-col-reverse': composableData.graphData.length > 6}"
    >
      <!-- LEGEND -->
      <LegendComponent
        :legend-object="composableData.graphData.map((legend, i) => ({
          value: legend.value,
          label: legend.label,
          evolutionObject: legend.comparator ? {
            value: legend.comparator.value,
            isPercent: legend.comparator.percent,
            comparison: legend.comparator.comparison,
          } : null,
          colorClass: !legend.color ? PALETTE.CHART_TW[i] : undefined,
          color: legend.color,
        }))"
        :with-columns="composableData.graphData.length > 6"
      />

      <!-- GRAPH -->
      <div class="relative w-52 h-52">
        <DoughnutChart
            :chartData="composableData.graphData"
            :with-tooltip="withTooltip"
        />
      </div>
    </div>
  </BaseChartWidget>
</template>

<script setup>
import LegendComponent from "@/components/common/LegendComponent.vue";
import DoughnutChart from "@/components/widgets/charts/DoughnutChart.vue";
import BaseChartWidget from "@/components/widgets/templates/BaseChartWidget.vue";
import {computed, toRefs} from "vue";
import {PALETTE} from "@/composables/widgets/_widgetUtils";

const props = defineProps({
  composable: Object,
  withTooltip: Boolean,
})
const { composable } = toRefs(props)

const composableData = computed(() => composable.value.data.value)
</script>
