import { regionFilter, clubFilter } from '@/composables/useFilters'
import {authorization} from "@/composables/useAuthorization";
import router from "@/router/index";

const LOGIN_REDIRECT_BASE_URL = "https://www.ffvoile.fr/ffv/identification/oauth.aspx?redirect_uri="

function setPageMetaTitle(to) {
  const DEFAULT_TITLE = 'FFVoile - Indicateurs'
  document.title = to.meta.title || DEFAULT_TITLE
}
async function handleFilters(to) {
  if (to.params.ligueId || to.params.clubId) {
    if (authorization.isAuthorized.value === null) {
      await authorization.checkAuthorization()

      if (authorization.mustRedirect.value) {
        if (!to.query.access_token)
          window.location.replace(getRedirectURI(to))
      }
    }
    if (authorization.isAuthorized.value && to.params.ligueId)
      await regionFilter.select(to.params.ligueId)
    else regionFilter.reset()

    if (authorization.isAuthorized.value && to.params.clubId)
      await clubFilter.select(to.params.clubId)
    else clubFilter.reset()
    if (!authorization.isAuthorized.value)
      await router.push('/')
  }
  else {
    regionFilter.reset()
    clubFilter.reset()
  }
}

const getRedirectURI = (to = undefined) => {
  let uri = to ? window.location.host + to.fullPath : window.location.host;
  if (window.location.host.includes('local'))
    return LOGIN_REDIRECT_BASE_URL + 'http://' + uri
  return LOGIN_REDIRECT_BASE_URL + 'https://' + uri
}

export function applyMiddlewares(router) {
  router.beforeEach(async (to, from, next) => {
    setPageMetaTitle(to)
    await handleFilters(to)
    next()
  })
}