import { ref, onMounted, onUnmounted, watch } from "vue"
import { yearFilter, regionFilter, clubFilter } from "../useFilters"
import Api from '@/composables/useApi'
import useDiscAndSuppFilter from "@/composables/filters/useDiscAndSuppFilter";

const useDiscAndSuppFilterWidget = (name, { type, indicator, endpoint, response }) => {
  const { filterData } = useDiscAndSuppFilter(name)

  const loaded = ref(false)
  const data = ref({})
  const watchers = ref([])
  const error = ref(null) 

  onMounted(async () => {
    await fetchData(indicator)
    watchers.value.push(
      watch(yearFilter.singleYearFilter, (val, oldVal) => { if (val !== oldVal) fetchData(indicator) }),
      watch(regionFilter.selectedLigue, (val, oldVal) => { if (val !== oldVal) fetchData(indicator) }),
      watch(clubFilter.selectedPPFStructure, (val, oldVal) => { if (val !== oldVal) fetchData(indicator) }),
      watch(filterData, (val) => { if (val) fetchData(indicator) }),
    )
  })

  onUnmounted(() => {
    watchers.value.forEach((unwatch) => unwatch())
  })

  async function fetchData(indicator) {
    loaded.value = false

    try {
      const query = {
        annees: {
          anneeDebut: yearFilter.singleYearFilter.value - 1,
          anneeFin: yearFilter.singleYearFilter.value,
        },
      }

      if (type)
        query.type = {type: type}
      if (indicator)
        query.indicateur = indicator.toString()
      if (filterData.secteurCode)
        query.discipline = {
          discipline: filterData.secteurCode ? [filterData.secteurCode] : []
        }
      if (filterData.codeBateau)
        query.support = {
          support: filterData.codeBateau ? [filterData.codeBateau] : []
        }
      if (regionFilter.selectedLigue.value)
        query.ligue = {
          ligue: regionFilter.selectedLigue.value.id,
        }
      if (clubFilter.selectedPPFStructure.value)
        query.structures = {
          structureCodes: [clubFilter.selectedPPFStructure.value.code]
        }

      const res = await Api.post(endpoint, query)
      const comparisonYear = res.data.filter((el) => el.annee === yearFilter.singleYearFilter.value - 1)
      const selectedYear = res.data.filter((el) => el.annee === yearFilter.singleYearFilter.value)

      data.value = response([comparisonYear, selectedYear])
    }
    catch (err) {
      console.error(name, err)
      data.value = null
      error.value = err
    }

    loaded.value = true
  }

  return ({
    loaded,
    data,
    error
  })
}

export default useDiscAndSuppFilterWidget
