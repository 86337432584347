<template>
  <BaseChartWidget :composable="composable">
    <!-- BARCHART -->
    <div class="flex flex-col gap-y-2" v-if="composable.loaded && composableData">
      <!-- LEGEND -->
      <LegendComponent
        v-if="composableData.graphData"
        :legend-object="composableData.graphData.map((legend) => ({
          value: legend.value,
          label: legend.label,
          evolutionObject: legend.comparator ? {
            value: legend.comparator.value,
            isPercent: legend.comparator.percent,
          } : null,
          colorClass: legend.colorClass,
        }))"
        :horizontal="true"
      />

      <!-- GRAPH -->
      <div class="chart-container relative" style="{ width: 100%; height: auto }">
        <VerticalBarChart
            :chartData="composableData.graph"
            :chartOptions="composableData.options"
        />
      </div>
    </div>
  </BaseChartWidget>
</template>

<script setup>

import { toRefs, computed } from 'vue'
import VerticalBarChart from "@/components/widgets/charts/VerticalBarChart.vue";
import LegendComponent from "@/components/common/LegendComponent.vue";
import BaseChartWidget from "@/components/widgets/templates/BaseChartWidget.vue";

const props = defineProps({ composable: Object })
const { composable } = toRefs(props)

const composableData = computed(() => composable.value.data.value)


</script>