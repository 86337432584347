<!--
  WIDGET Haute Performance -  Alimentation des Collectifs
-->

<template>
  <SquareIndicatorsWidget
      :composable="widget"
      :title-object="{
        title: 'Alimentation des collectifs',
        year: yearFilter.singleYearFilter
      }"
      :isLoading="!widget.loaded.value"
  />
</template>
      
<script setup>      
import { yearFilter } from '@/composables/useFilters'
import {buildDataset} from '@/composables/widgets/_widgetUtils'
import SquareIndicatorsWidget from "@/components/widgets/templates/SquareIndicatorsWidget.vue";
import useDiscAndSuppFilterWidget from "@/composables/widgets/useDiscAndSuppFilterWidget";
      
      
const widget = useDiscAndSuppFilterWidget("AlimentationCollectifs", {
  type: "liste_hn",
  endpoint: "/stats/IndicateursPerformance/alimentationcollectifs",
  response: ([comparisonYear, selectedYear]) => {
    if(!selectedYear.length) return null

    return {
      data: buildDataset(
          selectedYear,
          comparisonYear,
          'tcolLibelle',
          'nbAthletes',
          false,
          false,
          'ordre',
          false,
          false,
          true,
          (yearFilter.singleYearFilter.value - 1).toString()
      )
    }
  }
})
</script>