<template>
  <div class="bg-white border border-greyBorder p-4 rounded-2xl w-full flex flex-col gap-y-2 relative">
    <div class="carte row gap-x-2">
      <div
          class="absolute top-2 left-2 bg-lightGrey rounded bg-opacity-80 shadow"
          :class="{'p-2': !!regionNameHover}"
      >
        {{regionNameHover}}
      </div>

      <svg id="Calque_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 597.6 670.7" xml:space="preserve">
          <g>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/789`" @mousemove="showTooltip('789')" @mouseout="hideTooltip()">
              <path  class="region" d="M503.3,105.4l-5,3.9l-3.1,0.1l-5.8-2.6l-4-3.3h-7.7l-1.4,3.1l-6.3,0.8l-3.9-8.1l-3.5,0.3v-3.5l-6.8-1.7
                l-7.6-6.7l-6.601,0.2l1-7.3l-3.199-6.4v-4.6l3-5.8l-3-1.7l-4.7,4.8l-1.9,7.2l-9.8,4.4l-6-1.9l-3.2,0.7l0.9,6.4l-1.5,3.9l1.5,3.5
                l-5.601,8.1l-3.1,0.3l0.3,16.1l-1,3.1l-6.1-2.1l-8.9,4.5l-1.399,3l3.6,9.1l-6.1,2.5l1.8,2.7l-1,3.1l2.8,1.4l-8.5,11.4l-0.6,2.3
                l-2.801,2.5l3.9,9.3l2.9,1.9l-3.4,5.6l-3.3,0.8l0.5,9.6l4.1,1.7l5,6.2l2.8,9.3l2.2-2.5l4.101,4.7l5.6,12.3l14.1-1.8l3.301,1.5
                l2.5-2.2l6.899-1l5.3-4.4l4.301,0.6v-0.1l3.899,0.8l0.601,3.1l3,1.1l-0.4,3.2l3.3,0.3l2,2.6l1.101,3.4l-3.101,2.7l2.601,5.4
                l10.3,2.3l3.399,1.7v3.2l5.4-2.1l1.3-2.8l7.4-4.4l2.5,2l3.399-1.1l0.4-9.4l2.3-2.7l3.2,0.4l2.6-3.6l-0.3-1.6l9.101-6.5l7.899,4.8
                l6.3-1.8l5.5,4.1l5.801-2.5l9.399,7.4l1.101-0.7l8.5,5.6l0.5,10.2l3.199-0.2l2.301,5.9l3,0.6l-0.9,3.4h8.5l3-1.1l0.6-3.4l3.2-0.2
                l0.2-3.5l2.5-2.2l-3.2-8.7l3-17.3l-2.2-11.6l6.7-15.7l1.7-17.6l11.7-15.3l4.399-12l-9.5-3.4l-16.6-0.6l-9.4-8.4l-7,4.7l-7.8-1.4
                l-2.899,1.4l-2.5-4.4l-3.4-1.6l-3.1,1.8l-0.301,3.3l-3.1-0.8l-9-10.3l-1.9-6.5l-3.399-2.3L503.3,105.4z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/102`" @mousemove="showTooltip('102')" @mouseout="hideTooltip()">
              <path  class="region" d="M335.6,1l-18.3,4.6l-15.4,4.8l-6.5,5.6v20.2l3.3,3.9l-3.1-1.9l-0.8,6.6l0.5,4.3l2.6,1.8l-4.2,1.6l-0.4,3.4
              l3.4,6.2l-3.1-1.7l-7.3,10l0,0l14.1,12l3.8,9.5l-3.4,5.2l1.6,9.9l-1.6,9l4,9.4l-4.5,2.9l1.8,3.6l6.3,1.3l7.1-2.1l10.3,2.5l2.5-1.8
              l12.2,7.1l7.7,1.2l2.3-2.5l2.8,1.8l9.101-3.3l3,0.8l1.899,2.8l-0.7,3.2l4.4,4.7l10.5,6.7l8.5-11.4l-2.8-1.4l1-3.1l-1.8-2.7l6.1-2.5
              l-3.6-9.1l1.399-3l8.9-4.5l6.1,2.1l1-3.1L406,99.5l3.1-0.3l5.601-8.1l-1.5-3.5l1.5-3.9l-0.9-6.4H413.4L409.3,76l3.7-7.1l-3.4-5.9
              l2.301-7.3l-3.101,0.7l-4.6-5.5l-6.8,1.2l-6.101-1.4l-2.5,2.2L386.4,42.7l-6.101-1.5l-1.3-3l-3.2,2.4l-3.6-1.2l-3.4-9.5l0.5-3.4
              l-4.399-4.9l-6.601,0.5l-6.2,4.3l-3.3-1.4l-3.2-5.9H341.9l-1.5-3l1.199-6.4L338.7,2.9L335.6,1z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/20`" @mousemove="showTooltip('20')" @mouseout="hideTooltip()">
              <path class="region" title="Ile-de-France" d="M328.3,131.8l-2.5,1.8l-10.3-2.5l-7.1,2.1l-6.3-1.3l-1.8-3.6l-4.4,10.6h-4.3l-2.7,2.8l2,6.6l4.2,7.4
              l0.5,12.2l6.9,6.1l1.4,6.3l2.5,2.4l3.5-0.7l3.199,11.9l0,0l7.801-0.9l2.1-3.1l1.7,2.9l3.1-1.9l4.2,0.6l0.8,3.5l4.7,4.8l-0.6,3
              l-3,1.7l10.3,0.8l12.6-3l4.4-6l0.2-6.5l17.3-3.5l-0.5-9.6l3.3-0.8l3.4-5.6l-2.9-1.9l-3.9-9.3l2.801-2.5l0.6-2.3l-10.5-6.7l-4.4-4.7
              l0.7-3.2l-1.899-2.8l-3-0.8l-9.101,3.3l-2.8-1.8l-2.3,2.5l-7.7-1.2L328.3,131.8z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/156`" @mousemove="showTooltip('156')" @mouseout="hideTooltip()">
              <path class="region" title="Bourgogne-Franche-Comte" d="M422.4,215.5l-2.5,2.2l-3.301-1.5l-14.1,1.8l-5.6-12.3L392.8,201l-2.2,2.5l-2.8-9.3l-5-6.2l-4.1-1.7
                l-17.3,3.5l-0.2,6.5l-4.4,6l7.5,8.5l0.5,6.4l-5.2,5.5l1.2,3.3l-1.8,2.7l-5.9,3.7l3.4,3.2l2.5,7.8l-3.2,0.7l-1.7,2.7l2.2,7.3
                l-1.899,6.5l3.1,2.4l3.5,8.5l0.3,6.6l2.5,2.2l-0.3,6.6l-1.5,10l6.5,7.1l7.2,0.2l3.2-1.9l4.8,4.1l6.7-6.7l7.6,15.1l8.8,3.3l1.8,2.7
                l-0.5,6.2l-4.199,5.2v3.4l5.8,3.6l9-1.7l2.3,2.4l6.4-4.3l0.399-3.4l2.601-2.1l2.399,2l2.601-1.9l2.8,1.8l1.8-2.6l6.4,8.6l6.6-23.5
                l6.6,1.7l6-1.4l5.4,3.6h0.1l4.801,8.7h3.3v3.4l3.3-0.2l4.5-4.8l3.8,1.8l0.4,3l4.399-0.1l6.101-3.7l5.3-7.3l-0.2-0.1l4-13.6
                l13.2-12.1l-0.9-10.9l9.601-6.7l13.2-16.2l-0.301-3.2l2.601-2.4l-4.8-4l1.1-3.3l4.2-4.8l4.399-0.6l0.301,0.1l-2.301-5.9l-3.199,0.2
                l-0.5-10.2l-8.5-5.6l-1.101,0.7l-9.399-7.4l-5.801,2.5l-5.5-4.1l-6.3,1.8l-7.899-4.8l-9.101,6.5l0.3,1.6l-2.6,3.6l-3.2-0.4
                l-2.3,2.7l-0.4,9.4l-3.399,1.1l-2.5-2l-7.4,4.4l-1.3,2.8l0,0l3.1-1.9l-3.1,1.9l0,0l-5.4,2.1v-3.2l-3.399-1.7l-10.3-2.3l-2.601-5.4
                l3.101-2.7l-1.101-3.4l-2-2.6l-3.3-0.3l0.4-3.2l-3-1.1l-0.601-3.1l-3.899-0.8v0.1l-4.301-0.6l-5.3,4.4L422.4,215.5z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/21`" @mousemove="showTooltip('21')" @mouseout="hideTooltip()">
              <path class="region" title="Centre-Val-de-Loire" d="M344.2,205.3l-10.3-0.8l3-1.7l0.6-3l-4.7-4.8l-0.8-3.5l-4.2-0.6l-3.1,1.9l-1.7-2.9l-2.1,3.1l-7.801,0.9l0,0
              L309.9,182l-3.5,0.7l-2.5-2.4l-1.4-6.3l-6.9-6.1l-0.5-12.2l-4.2-7.4l-6.7,7.8l0.3,3.1l-6.7,1.5l-3.2-1.8l-6,3.6l-6.7,1.2l-2.7,2.9
              l0.2,3.6l5.3,4.9l-0.2,5.1l0.6,5.1l-8.5,5.8l1.2,8.4h0.1l2.3,4.3l-0.5,2v0.1l-2.3,2.3l2,2.5l0.4,6.2l-7,12l-3.6,1.1l-0.7,4l-8.6,5
              l-3.1-0.3l0.5,3.7l-6.8-2.9l-0.9,2.7l-1.9,10.4l-6.4,17.2v0.1l6.1,3.6l0.3,3.6l3.3-0.3l1.5,6.8l3.1,1.6l11.3-0.5l-1.7-3.2l6.1,3.3
              l0.2,3.5l10.4,14.7l-1,6.5l5.3,4.9h3.2l3,6.1l2.8,1.4l-1.8,3.2l3,1.2l6.5-1.2l1.9,2.7l3.1-3.2l12.9-1.2l2.1-2.8l14.9,3l3.101-0.9
              l5.5,0.2l0,0l3.899-6.7l9.8-1.1l1.9-2.7l-1.9-6l2.5-2.1l9.5-3.2l5.4-4.2h4.5l1.5-10l0.3-6.6l-2.5-2.2l-0.3-6.6l-3.5-8.5l-3.1-2.4
              l1.899-6.5l-2.2-7.3l1.7-2.7l3.2-0.7l-2.5-7.8l-3.4-3.2l5.9-3.7l1.8-2.7l-1.2-3.3l5.2-5.5l-0.5-6.4l-7.5-8.5L344.2,205.3z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/333`" @mousemove="showTooltip('333')" @mouseout="hideTooltip()">
              <path class="region" title="Auvergne-Rhone-Alpes" d="M342.6,304.3l-2.5,2.1l1.9,6l-1.9,2.7l-9.8,1.1l-3.899,6.7l0,0l1.199,4.4c2.801,1.8,5.601,3.6,8.301,5.5
              l4.1,9.3l-0.6,4.6l2.6,7.6l-10.5,9.7l5,6.2l1.4,6.2l-2,2.8l2.199,15.6l-7-2.3l-0.699,3.9l-8.5,10.7l2,2.5L321.3,411.8l-0.3,3.801
              l-3.5,1.3l1.7,3.7l-3.7,2.199l1.1,6.101l4.301,6.8l-2,6.4l3.699,5.6l5.801-3.6l7.1,1.8l4.3-5.5l3.4-10.101l5.3-4.8l5.9,4.5
              l1.399,4.7l2.7,1.8l-0.6,3.4l2.899,5.7l7.8-17.801l2,2.5l8.7-7.5l2.5,1.9l1.7,6.4l7.2,1.399l0.2-3.2l3.199,0.301l10.101,8.6
              l2.3,10.2l7.9,16l8.699,5.6l7.9-4.899l0.2,3.5l2.8-2.5h3.1l6.801,3.8L443.8,465.8l6.5,0.801l4,4.8l9.8-4.4l2.7,1.601l2.8-2.5
              l0.5,6.5l10.5,0.899l0.101,3.2l5.899,2.601l4.801-5.4l2.6-0.1l1.1-0.2l0.2-5.301l-11.2-6.399l-1.699-3l3.699-5.7l4.601,1.6l2.8-2.8
              l-3.3-2.5l2.8-7.6l6.4-0.4l0.399-3.8l1.101-3.4l6.199-1.399l1-3.2l14.2-4.3l3.4,1.3l0.399-6.6l-4.899-3.5l-1.9-4.7l1.7-4.3
              l8.7,3.899l2.3-2.2l6.8-1.3l0,0v-0.1l21.7-9.3l2.2-4.101l-1.3-2.899l3.1-5.801l-8.2-5.8l-2.5-6.1l0.8-3.5l-6.3-2.601l-3.2-3
              l-0.5-3.6l0.101-0.1L554,352l1.1-3.5l-3.8-5.1l-8.7-8.3l2.5-6.9l-4.1-6.1l0.6-3.2l-3.8-0.8l-9.5,0.7l-5.7,4.7l-3.8-1.6l-3.2,6.5
              l2.7,3.4l-7.8,6.1l-7.9,1V338.8l0.601-5.6l6.5-4l1.2-7.8l-3.9-2.2l-5.3,7.3l-6.101,3.7l-4.399,0.1l-0.4-3l-3.8-1.8l-4.5,4.8
              l-3.3,0.2v-3.4h-3.3l-4.801-8.7H471l-5.4-3.6l-6,1.4l-6.6-1.7l-6.6,23.5l-6.4-8.6l-1.8,2.6l-2.8-1.8l-2.601,1.9l-2.399-2
              l-2.601,2.1l-0.399,3.4l-6.4,4.3l-2.3-2.4l-9,1.7l-5.8-3.6v-3.4l4.199-5.2l0.5-6.2l-1.8-2.7l-8.8-3.3l-7.6-15.1l-6.7,6.7l-4.8-4.1
              l-3.2,1.9l-7.2-0.2l-6.5-7.1h-4.5l-5.4,4.2L342.6,304.3z M464.6,461.5l-2.699,4l-4.5,1.4l-2.2-2.5l1.399-3.4l3-2.5L464.6,461.5z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/892`" @mousemove="showTooltip('892')" @mouseout="hideTooltip()">
              <path class="region" title="Nouvelle-Aquitaine" d="M326.4,322.9l-5.5-0.2l-3.101,0.9l-14.9-3l-2.1,2.8l-12.9,1.2l-3.1,3.2l-1.9-2.7l-6.5,1.2l-3-1.2l1.8-3.2
              l-2.8-1.4l-3-6.1h-3.2l-5.3-4.9l1-6.5l-10.4-14.7l-0.2-3.5l-6.1-3.3l1.7,3.2l-11.3,0.5l-3.1-1.6l-1.5-6.8l-3.3,0.3l-0.3-3.6
              l-6.1-3.6l-7.5,6.7l-2.5-2.6l-10.9,0.2l-6.3,0.9l-4.1,5.4l-13.8,0.9l2.5,6.4l4.8,4.6l5.3,20.1l-0.3,8.4l2.8,2.2l-10.2,5l-2.6-2
              l-7.3,0.3l1.5-3.3l-9.9,3.9l-5.1,10.8l3.3,1l4.6,9l-3.1,0.5l1.8,6l-3.3,6.5l-5.3,3.601l1.4,4.899l8.8,5.5l11.7,12.5l2.4,8.4v0.1
              l0.6,3l-2.4,3.801l-2.8-8.801l-12-11.8l-1.1-3.1L159,385.3l-6.9,56.4l4.4-8.8l5,5.8l0.3,3.2l-7.2-1l-3.6,7.7l0.1,5.3l-12.3,56.2
              l-4.3,8h-0.1l-5.1,7.1l-7.1,5.2l6.5,3l0.6,3.6l2.5-2.399l7.4,1.5l1.6,3.199l-2.1,6.601l-2.9,2.6l1.4,3.2l4.4,1.1l0.5-4.1l3.3-1
              l0.3,3.8l6.6,3.5l10.8,4.2l7.1-0.4l2.9,5.301l8.8,7.199l2.6-1.8l2.6,1.601l6.7-3.5l0.1,0.1v-0.1l1.4-9.601l1.8-3.2l3.8-0.899
              l-0.5-3.3l9.1-10.4l-0.9-3.4l3-1.6L211,527h-3.2l1.6-3.1l-2.8-6.5l0,0l-7.4-0.4l-1.8-3l4.8-12.5l-0.5-7.1l10.4-3.1l0.8,3.399
              l2.8-1.5l0.3-3.2v-0.1l4.2,0.7l1.6-2.8l9.3,0.8l5.7-3.2l9.6,1.1l3-1.5l4-5.3l3.2,0.3l-1.5-3.1l5-6.101l-2.7-1.6v-3.3l8.4-2.2
              l-3-10.2l3.8-3.6l5.3-7.2l6.8-3.8l-0.8-4l5.1-3.601l1.6-6l0.8-5.699l9-0.801l7.6,6.801l5.9-3l7.6,0.199l3.7-2.199l-1.7-3.7l3.5-1.3
              l0.3-3.801l2.601-2.199l-2-2.5l8.5-10.7l0.699-3.9l7,2.3l-2.199-15.6l2-2.8l-1.4-6.2l-5-6.2l10.5-9.7l-2.6-7.6l0.6-4.6l-4.1-9.3
              c-2.7-1.9-5.5-3.7-8.301-5.5L326.4,322.9z M206.3,536.9l0.1,3.1l-2.5-1.8L206.3,536.9z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/304`" @mousemove="showTooltip('304')" @mouseout="hideTooltip()">
              <path class="region" title="Normandie" d="M178.5,114l-6,2.6l-7.2-11.8l2.8-8.8l-1.2-3.1l-7.3-0.8l-2.8,2l-7.3,0.5l-12.8-5.2l-0.2,3.2l3.7,2l0.6,5.5
              l-2.2,2.3l2.9,11.4l9.5,10.3l-1.5,11.3l2.2,3.2l-0.6,6.7l-2.5,6.4l4.7,10.5l2.9,1.5l2.8-1.8l-1.1,3.3l-7.9,0.9l5.3,10.7l3,1l8-5.3
              l6.1,2.6l9.7,0.9l3.7,4.2l2.7-1.8l2.8,1.9l7.6-3.7h10.2l0.8-3.1l3.1,0.3l2.2,6.8l3.6,1.7v3.3l3.4,0.1l12.3-6.5l3.1,1.7l1.8,9.4
              l8.1,5.6l3.2-1.1l3.6,3.9l3.5,0.8h0.1h-0.1l-1.2-8.4l8.5-5.8l-0.6-5.1l0.2-5.1l-5.3-4.9l-0.2-3.6l2.7-2.9l6.7-1.2l6-3.6l3.2,1.8
              l6.7-1.5l-0.3-3.1l6.7-7.8l-2-6.6l2.7-2.8h4.3l4.4-10.6l4.5-2.9l-4-9.4l1.6-9l-1.6-9.9l3.4-5.2l-3.8-9.5l-14.1-12l0,0l-0.2,0.3
              l-12.2,8.5l-24.8,6.2l-16,8.6l-6.5,14.2l1.9,2.5l9.9,2.2l0.2,0.1l-1.7,0.7l-7.9,1.8l-5.4,4.7l-10.9,3.1l-9.5-4l-14-1.6L178.5,114z"
              />
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/05`" @mousemove="showTooltip('05')" @mouseout="hideTooltip()">
              <path class="region" title="Bretagne" d="M114,161.8l-4,0.3l-12.1,10l-1-4.1l-4.5-2.9l-0.1-3.5l-5.1-8.8l-3.3-0.7l0.7-3.2l-3.4,0.4l-1.9,3.3l1.5-7.4
              l-5.3,3.6l-0.6-4l-9.9,4.1l-1.6-2.7l-5.1,4.1l-0.4,6.9l-3.6,0.6l-5.5-3.4l-3.2,0.7l-0.7,3.8l-2.5-3l-1.8,3.6l-0.1-7.3l-16.2,4.9
              l-1.7-3l-11.4,6.2l2.9,1.5l-9.4-1l-2.1,2.7L1,173.6l2,2.6l3.1-1.3l2.9,1.4l13.8-4.1l-4.2,2L18,178l6.7-1.6l-0.5,3.2l3.3,1.4
              l-3.5,0.7l7.1,3l-6.7-1.6l-1.6-2.8l-4.2,1.2l-6.5-1.3l-1.9-2.7l-1.7,4.1l2.7,5.9l2-3.1l3.1,0.3l6.1,3.5l1,3.6l-1.8,2.8l-2.7-1.5
              l-14.2,1.4L3,197.3l10.7,4l4.2,7l-1.2,6.2l8.2,0.7l4.1-4.7v-0.4V210l-0.9-3.2l1.2-0.2l0.4-3.5l1.6,3.2l-2,0.3l-0.3,3.5l3.3,2.2
              l5.2-1.9l5.3,6.7l3.2-0.7v-3.2l0.4,3.1v0.1l2.9-1.1l-0.7,3.2l3.2,0.7l4.3-1.7l0.2-3.4l0.8,8.6l2.8,1.8L63,223v-0.1l-0.5-6.5
              l0.6,3.4l3.3,0.6l-2.4,2.4l1.7,3l4.1,1.7l3-1.3l-2.8,1.4l0.4,3.1l2.4,3.2l-0.9,4.1l2.4,2.6l-1.3-3.2l3-2.7l3.6-0.8l2.7,2l-1.4-6.6
              l3.5,5.5l1.7-2.9l4.1,0.7l2.4,2.7l-2.2,2.8l-7.1-0.9l5.4,4.2l13.9-1.1l3.1,1.7l-3.4,0.2l1.8,2.9l17.3-5.1l0.4-6.7l5.9-3.8l14.7-0.5
              l1.8-3.2l10.1-4.4l7.7,4l0.5-2.4l4-9.7l6.2-2.5l-2.1-19.5l1.7-2.6l0.1-13.6l-6.1-2.6l-8,5.3l-3-1l-5.3-10.7h-13l0.3-6.3l-3.6,0.8
              l-4.5,4.3l2.9,6.3l-2.6-2.1l-2-5h-3.2v2.1l-4.6,0.4l-1.1-3.5l-3.4,1.6L114,161.8z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/07`" @mousemove="showTooltip('07')" @mouseout="hideTooltip()">
              <path class="region" title="Pays-de-la-Loire" d="M144.2,225.8l-1.8,3.2l-14.7,0.5l-5.9,3.8l-0.4,6.7l-17.3,5.1l-5,4.9l2.7,6.9l6.8,1.2l2.3,2.2l5.5-4.1
              l8.1-0.7l-7.6,2.9l-0.7,7.5l-3.4,1.4l6.7,2.4l5.4,5.6l-8.8,9.3l0.5,5l11.7,13.7l2.3,7.9l9.1,7.6l5.4,1.2l1.7,4l6.8,1.3l5.2,4.3
              l0.8-3.1l3.2,0.5l9.9-3.9l-1.5,3.3l7.3-0.3l2.6,2l10.2-5l-2.8-2.2l0.3-8.4l-5.3-20.1l-4.8-4.6l-2.5-6.4l13.8-0.9l4.1-5.4l6.3-0.9
              l10.9-0.2l2.5,2.6l7.5-6.7v-0.1l6.4-17.2l1.9-10.4l0.9-2.7l6.8,2.9l-0.5-3.7l3.1,0.3l8.6-5l0.7-4l3.6-1.1l7-12l-0.4-6.2l-2-2.5
              l2.3-2.3v-0.1l0.5-2l-2.3-4.3h-0.1l-3.5-0.8l-3.6-3.9l-3.2,1.1l-8.1-5.6l-1.8-9.4l-3.1-1.7l-12.3,6.5l-3.4-0.1v-3.3l-3.6-1.7
              l-2.2-6.8l-3.1-0.3l-0.8,3.1h-10.2l-7.6,3.7l-2.8-1.9l-2.7,1.8l-3.7-4.2l-9.7-0.9l-0.1,13.6l-1.7,2.6l2.1,19.5l-6.2,2.5l-4,9.7
              l-0.5,2.4l-7.7-4L144.2,225.8z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/124`" @mousemove="showTooltip('124')" @mouseout="hideTooltip()">
              <path class="region" title="Occitanie" d="M302,425.601l-7.6-6.801l-9,0.801l-0.8,5.699l-1.6,6l-5.1,3.601l0.8,4l-6.8,3.8l-5.3,7.2l-3.8,3.6l3,10.2
              l-8.4,2.2v3.3l2.7,1.6l-5,6.101l1.5,3.1l-3.2-0.3l-4,5.3l-3,1.5l-9.6-1.1l-5.7,3.2l-9.3-0.8l-1.6,2.8l-4.2-0.7v0.1l-0.3,3.2
              l-2.8,1.5l-0.8-3.399l-10.4,3.1l0.5,7.1l-4.8,12.5l1.8,3l7.4,0.4l0,0l2.8,6.5l-1.6,3.1h3.2l0.6,8.2l-3,1.6l0.9,3.4l-9.1,10.4
              l0.5,3.3l-3.8,0.899l-1.8,3.2l-1.4,9.601v0.1l12.6,11.2l13.7-1.9l2.8,2.4l11.1-0.2l7,0.4l2.6-2.5l-0.4-7.301l2.7-1.5l7.6,2.601
              l11.2,2.899l5.4,4.9l7.2-0.5l6.4,8.5l1.1-3.3l3.8-0.2l11.9,5.7l-2.2,5.5l9.9,2.899l2.601,6.4l3.6,0.2l5-4.5l3.6-0.2l10.601,2.5
              l5.2,4l7-0.5v-3.399l9.1-4.7l7.1-1.301l9.601,2.601l-6.5-8.101l-0.4-19.699l0.5-11.101l6.3-12l3-3l6.101-4.6h7.6l7.5-8.101
              l12.3-8.899l9.4-2.7l3.3,5.5l3.4,0.8h0.2l4.3-6.6l6.5-2l-1.101-3.1l4.7-5.4l3.7,1l2.1-11.9l9.5-10.199l-7.1-7.2L446.8,475
              l-2.899-5.1l-6.801-3.8H434l-2.8,2.5l-0.2-3.5L423.1,470l-8.699-5.6l-7.9-16l-2.3-10.2l-10.101-8.6L390.9,429.3l-0.2,3.2
              l-7.2-1.399l-1.7-6.4l-2.5-1.9l-8.7,7.5l-2-2.5l-7.8,17.801l-2.899-5.7l0.6-3.4l-2.7-1.8L354.4,430l-5.9-4.5l-5.3,4.8l-3.4,10.101
              l-4.3,5.5l-7.1-1.8l-5.801,3.6l-3.699-5.6l2-6.4l-4.301-6.8l-1.1-6.101l-7.6-0.199L302,425.601z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/112`" @mousemove="showTooltip('112')" @mouseout="hideTooltip()">
              <path class="region" title="Provence-Alpes-Cote-d-Azur" d="M558.1,425.4l-6.8-0.3l-5.899-3.7l-0.9-7.4l-3.1-0.1L538.8,408l0,0l-6.8,1.3l-2.3,2.2l-8.7-3.899l-1.7,4.3
              l1.9,4.7l4.899,3.5l-0.399,6.6l-3.4-1.3l-14.2,4.3l-1,3.2L500.9,434.3l-1.101,3.4l-0.399,3.8l-6.4,0.4l-2.8,7.6l3.3,2.5l-2.8,2.8
              l-4.601-1.6l-3.699,5.7l1.699,3l11.2,6.399l-0.2,5.301l-1.1,0.2l-2.6,0.1l-4.801,5.4L480.7,476.7l-0.101-3.2l-10.5-0.899l-0.5-6.5
              l-2.8,2.5L464.1,467l-9.8,4.4l-4-4.8l-6.5-0.801l0.101,4.101l2.899,5.1l0.2,6.101l7.1,7.2l-9.5,10.199l-2.1,11.9l-3.7-1l-4.7,5.4
              l1.101,3.1l-6.5,2l-4.3,6.6l16.1,0.5l2.2,2.8l-0.5,3.101l4.1,1l10.9-1.3L454.7,530l1.399-2.8l3.101,0.3l5.2,6.101l14.8-1.7
              l4.399,9.899l10.9,2.7l3.1-1l0.2,0.2l6.9,4l-0.4,3.1l10-2.899l6.7,1.8l1.3,3.2l2.3-6.2l8.301,1.4l0.1-3.2l6.3-1.4l3-2.8l4.3,0.4
              l2.5-6.101l-4-1.8l6.9-9.1l6.1-0.801l4.9-8.699l6.4-3l1.199-4.7l16.2-8.8L587.4,490l6.899-9.699l2.3-4.9l-4.5-5.9l-13.3,4.7
              l-10.3-5.5l-7.1-1.4l-6.9-8.3l-0.2-0.1l2.2-5.601l-4.4-6.1l2.5-2l1.5-6.7v-0.1l0.2-0.2l2-2.4l4,0.101L558.1,425.4z M470.5,525.2
              l3.7-1.5l-2.5,4.2l-3.601,1.3l-5.1-4.8l0.6-3.8h4.5L470.5,525.2z"/>
            </router-link>
            <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/29`" @mousemove="showTooltip('29')" @mouseout="hideTooltip()">
              <path class="region" title="Corse" d="M569.8,578.9l0.601-12l-2-10.2l-3.2-1.2l-2.601,2.3l0.5,19.5l-9.699-1.5l-3.9,5.5l-12.1,4.801l-0.9,3.199
              l-3.2,0.301L530.9,592.8l0.3,3.9l-4.4,5.601l-0.6,3.199l2.899-1.1l4.301,4.7l-5.9,4.2l1.2,6.199l8.899,5l-3,1.801l-3.3,9.399
              l8.101-2.899l1.1,6.5l-3,7.899l10.6,2.101L542.6,653l-0.199,3.4l5.399,4.9l12.601,3.8l1.399,3.2l3.3,1.399l6.601-16.6l-3.7-0.3
              l3-2.301l2.3-6.1l-0.8-9.7v-6.899l6.1-13.4l-4.3-29.2L569.8,578.9z"/>
            </router-link>
          </g>
        </svg>

      <div class="map--france-dt column align-center center width30'">
        <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/32`" tabindex="-1" aria-label="Guadeloupe" class="region btn-map row align-center center" @mousemove="showTooltip('32')" @mouseout="hideTooltip()">
          <span class="icon icon-Guadeloupe"></span>
        </router-link>
        <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/26`" tabindex="-1" aria-label="Martinique" class="region btn-map row align-center center" @mousemove="showTooltip('26')" @mouseout="hideTooltip()">
          <span class="icon icon-Martinique"></span>
        </router-link>
        <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/30`" tabindex="-1" aria-label="Guyane" class="region btn-map row align-center center" @mousemove="showTooltip('30')" @mouseout="hideTooltip()">
          <span class="icon icon-Guyane"></span>
        </router-link>
        <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/31`" tabindex="-1" aria-label="Reunion" class="region btn-map row align-center center" @mousemove="showTooltip('31')" @mouseout="hideTooltip()">
          <span class="icon icon-Reunion"></span>
        </router-link>
        <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/27`" tabindex="-1" aria-label="Nouvelle-Caledonie" class="region btn-map row align-center center" @mousemove="showTooltip('27')" @mouseout="hideTooltip()">
          <span class="icon icon-Nouvelle-Caldonie"></span>
        </router-link>
        <router-link @click.prevent="closeMenu" :to="`/${pageId}/ligue/25`" tabindex="-1" aria-label="Outre-mer" class="region btn-map row align-center center" @mousemove="showTooltip('25')" @mouseout="hideTooltip()">
          <span class="icon icon-autres_outre-mer"></span>
        </router-link>
      </div>
    </div>
    <div
        v-if="!!regionFilter.selectedLigue.value && !!regionFilter.selectedLigue.value.id"
        class="flex justify-between items-center p-4 bg-lightGrey text-dark rounded-lg"
    >
      <span class="w-full text-center">
        Ligue&nbsp;
        <span class="text-2xl font-bold font-regular">{{ regionFilter.selectedLigue.value.name }}</span>
      </span>
      <router-link @click.prevent="closeMenu" :to="`/${pageId}`" class="px-1 cursor-pointer text-2xl hover:text-grey">{{ "\u00d7" }}</router-link>
    </div>
  </div>
</template>

<script setup>
import { ref} from 'vue'
import { regionFilter } from '@/composables/useFilters'

defineProps({
  pageId: String,
  closeMenu: Function,
})

const {  ligues } = regionFilter

const regionNameHover = ref('')

function showTooltip(ligueId) {
  const ligue = ligues.value.find((ligue) => ligue.id === ligueId)
  regionNameHover.value = ligue.name
}

function hideTooltip() {
  regionNameHover.value = ""
}
</script>