<script setup>

import {ref, toRefs} from "vue";
  import ButtonComponent from "@/components/common/ButtonComponent.vue";

  const props = defineProps({
    name: String, // MANDATORY
    alignContentBox: String, // 'left' (default) ou 'right'
    displayValue: Boolean,
    onFilterClose: Function, // () => void
    withBorder: Boolean,
  })

  const refProps = toRefs(props)

  const toggleFilter = ref(false)
  const justOpen = ref(false)

  const onButtonClick = () => {
    toggleFilter.value = !toggleFilter.value
    justOpen.value = toggleFilter.value
    !toggleFilter.value && !!refProps.onFilterClose.value && refProps.onFilterClose.value()
  }

  const closeFilter = () => {
    toggleFilter.value = false
    !!refProps.onFilterClose.value && refProps.onFilterClose.value()
  }
</script>

<template>
  <div class="font-regular relative cursor-pointer">
    <button-component
        class="flex justify-between items-center gap-x-2 w-full border border-greyBorder"
        :on-button-click="onButtonClick"
        :button-style="withBorder ? 'thinBorder' : undefined"
    >
      <div class="flex gap-x-2 items-center">
        <span v-if="!!displayValue" class="px-2 py-1 bg-lightestBlue text-blue rounded text">
          <slot name="value"></slot>
        </span>
        <span>{{ name }}</span>
      </div>
      <span class="icon-arrow-menu-down" :class="{ 'rotate-180': toggleFilter }" />
    </button-component>

    <div
        v-if="!!toggleFilter"
        class="flex mt-2 bg-white rounded-lg p-2 absolute w-max shadow z-30"
        :class="{
          'right-0': !!alignContentBox && alignContentBox.includes('right'),
          'bottom-full': !!alignContentBox && alignContentBox.includes('bottom'),
        }"
        v-click-outside="() => {
          if (justOpen) {
            justOpen = false
          } else {
            closeFilter()
          }
        }"
    >
      <slot :closeFilter="closeFilter"></slot>
    </div>
  </div>
</template>

<style scoped>

</style>