<template>
  <BaseWidget
      :title-object="props.titleObject"
      :front-error="hasError"
      :api-error="!hasApiData"
      :is-loading="!composable.loaded.value"
  >
    <div class="flex gap-y-2 wrap justify-center">
      <SquareIndicator
          v-for="(data, index) in composableData.data"
          :key="index"
          :label="data.legend"
          :value="data.value"
          :evolution-object="data.comparator ? {
            comparatorValue: data.comparator.value,
            isPercent: data.comparator.percent,
            comparison: data.comparator.comparison
          } : null"
          :larger-label="true"
      />
    </div>
    <slot />
  </BaseWidget>
</template>
    
  <script setup>
  import { toRefs, computed } from 'vue'
  import SquareIndicator from "@/components/common/SquareIndicator.vue";
  import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
  
const props = defineProps({
  composable: Object,
  titleObject: Object, // { title: String, year: Number, isDeclarative: Boolean }
})
const { composable } = toRefs(props)

const composableData = computed(() => composable.value.data.value)
const hasError = computed(() => composable.value.error.value != null)
const hasApiData = computed(() =>  !!(composable.value.data.value))
</script>