<template>
  <header
      class="
        max-xl:fixed max-xl:top-0 max-xl:left-0 max-xl:z-50
        p-2 xl:p-0
        xl:h-full
      "
      :class="{'max-xl:bg-lightestBlue max-xl:shadow max-xl:overflow-y-scroll h-full': isMenuOpen}"
  >
    <div class="flex flex-col gap-y-2 w-full h-full md:max-w-sm xl:max-w-md">
      <button
          class="
            xl:hidden
            flex flex-col justify-center items-center
            gap-y-1 w-12 h-12 p-3
            shadow rounded-xl bg-white
          "
          aria-label="Menu"
          @click="openMenu"
      >
        <span class="h-1 w-full bg-dark rounded-full" />
        <span class="h-1 w-full bg-dark rounded-full" />
        <span class="h-1 w-full bg-dark rounded-full" />
      </button>
      <div
          class="
            max-xl:hidden flex flex-col gap-y-4 items-center h-full
          "
          :class="{'!flex': isMenuOpen}"
      >
        <div class="flex flex-col gap-y-4 p-4 bg-white border border-greyBorder rounded-2xl">
          <div class="flex flex-col gap-y-2 items-center">
            <img alt="Logo FFVoile" class="w-3/4" src="@/assets/images/logo-ff.png">
            <span class="font-regular text-darkBlue">Les indicateurs de la FFVoile</span>
          </div>
          <nav class="flex flex-col gap-y-1 w-full">
            <router-link
                :to="getRedirectPath('home')"
                class="flex gap-x-4 items-center px-4 py-3 w-full text-lg text-darkGrey rounded-lg hover:text-blue hover:bg-lighterBlue"
                :class="{'!text-blue bg-lighterBlue': !isActive('developpement') && !isActive('pratique') && !isActive('performance')}"
            >
              <span class="icon icon-accueil"></span>
              <span>Accueil</span>
            </router-link>
            <router-link
                :to="getRedirectPath('developpement')"
                @click.prevent="openMenu"
                class="flex gap-x-4 items-center px-4 py-3 w-full text-lg text-darkGrey rounded-lg hover:text-blue hover:bg-lighterBlue"
                :class="{'!text-blue bg-lighterBlue': isActive('developpement')}">
              <span class="icon icon-developpement"></span>
              <span>Développement</span>
            </router-link>
            <router-link
                :to="getRedirectPath('pratiques-sportives')"
                @click.prevent="openMenu"
                class="flex gap-x-4 items-center px-4 py-3 w-full text-lg text-darkGrey rounded-lg hover:text-blue hover:bg-lighterBlue"
                :class="{'!text-blue bg-lighterBlue': isActive('pratique')}">
              <span class="icon icon-pratiques-sportives"></span>
              <span>Pratiques Sportives</span>
            </router-link>
            <router-link
                :to="getRedirectPath('haute-performance')"
                @click.prevent="openMenu"
                class="flex gap-x-4 items-center px-4 py-3 w-full text-lg text-darkGrey rounded-lg hover:text-blue hover:bg-lighterBlue"
                :class="{'!text-blue bg-lighterBlue': isActive('performance')}">
              <span class="icon icon-haute-performance"></span>
              <span>Haute Performance</span>
            </router-link>
          </nav>
        </div>
        <div class="flex flex-col md:hidden gap-y-4 w-full">
          <!-- DATE FILTER -->
          <DateFilter
              align-content-box="right"
              :a-date="aDate"
              :display-comparison="true"
              :with-border="true"
          />
          <!-- HANDI FILTER-->
          <HandiFilter
              v-if="pageId === 'pratiques-sportives'"
              class="h-fit max-md:border max-md:border-greyBorder max-md:shadow-none"
          />

        </div>
        <div class="flex sticky top-1 flex-col items-end gap-y-4 pb-4">
          <LigueFilter :page-id="pageId" :closeMenu="openMenu" />
          <StructureFilter :page-id="pageId" :closeMenu="openMenu" />
          <div
              class="absolute w-full h-full bg-white bg-opacity-50 rounded-xl"
              :class="{ 'hidden': authorization.isAuthorized.value !== false }"
          />
        </div>
        <div class="h-full"></div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import {clubFilter, regionFilter} from "@/composables/useFilters";
import LigueFilter from "@/components/globalFilters/LigueFilter.vue";
import StructureFilter from "@/components/globalFilters/StructureFilter.vue";
import {authorization} from "@/composables/useAuthorization";
import DateFilter from "@/components/globalFilters/DateFilter.vue";
import HandiFilter from "@/components/globalFilters/HandiFilter.vue";

defineProps({
  pageId: String,
  isMenuOpen: Boolean,
  openMenu: Function,
  aDate: Boolean,
})

const getRedirectPath = (requestedPage) => {
  if (regionFilter.selectedLigue.value) {
    return `/${requestedPage}/ligue/${regionFilter.selectedLigue.value.id}`
  } else if (clubFilter.selectedClub.value && requestedPage !== 'haute-performance') {
    return `/${requestedPage}/structure/${clubFilter.selectedClub.value.code}`
  } else if (clubFilter.selectedPPFStructure.value && requestedPage === 'haute-performance') {
    return `/${requestedPage}/structure/${clubFilter.selectedPPFStructure.value.code}`
  } else return `/${requestedPage}`
}

const isActive = computed(() => (target) => {
  return useRoute().fullPath.includes(target)
})
</script>