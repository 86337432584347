import axios from 'axios'

const instance = axios.create({
  withCredentials: true,
  baseURL: window.VUE_APP_WEBAPI,
  headers: {
    "Content-Type": "application/json",
  }
})

const useApi = () => {
  async function get(url, options = {}) {
    try {
      return await instance.get(url, options)
    }
    catch(err) {
      return Promise.reject(err)
    }
  }
  
  async function post(url, data, options = {}) {
      try {
          return await instance.post(url, data, options)
      }
      catch(err) {
        return Promise.reject(err)
      }
  }

  return {
    get, post
  }
}

export default useApi()