<template>
  <div class="flex flex-col items-center">
    <FilterSimple
      name="Année"
      :elements="buildElements()"
      :select-element="(elt) => yearFilter.selectSingleYear(elt.year)"
      :single-choice="true"
      :value-to-display="yearFilter.singleYearFilter.value.toString()"
      align-content-box="right"
      class="w-full"
      :withBorder="withBorder"
    />
    <div
        v-show="displayComparison"
        class="bg-white text-greyDarkness text-xs text-center rounded-b p-1 w-full -mt-2 pt-3 flex justify-center"
        :class="{
          'border border-greyBorder': withBorder,
          'shadow': !withBorder,
        }"
    >
      <span>Comparaison&nbsp;:&nbsp;</span>
      <b>{{
          aDate && yearFilter.singleYearFilter.value === getCurrentYear()
            ? getComparisonDate()
            : yearFilter.singleYearFilter.value - 1
      }}</b>
    </div>
  </div>
</template>

<script setup>
import { yearFilter } from '@/composables/useFilters'
import FilterSimple from "@/components/common/FilterSimple.vue";
import {getComparisonDate, getCurrentYear} from "@/composables/widgets/_widgetUtils";

defineProps({
  aDate : Boolean,
  displayComparison: Boolean,
  withBorder: Boolean,
})
const buildElements = () => {
  return yearFilter.allYears.value.map((y) => ({
    year: y,
    label: y.toString(),
    isSelected: yearFilter.singleYearFilter.value.toString() === y.toString()
  }))
}
</script>