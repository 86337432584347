import { ref } from "vue"


const selectedLigue = ref(null)
const ligues = ref([
  {name : "Bretagne" , id : "05"},
  {name : "Pays de la Loire" , id : "07"},
  {name : "Ile de France" , id : "20"},
  {name : "Centre Val de Loire" , id : "21"},
  {name : "Outre Mer" , id : "25"},
  {name : "Martinique" , id : "26"},
  {name : "Nouvelle Calédonie" , id : "27"},
  {name : "Polynésie" , id : "28"},
  {name : "Corse" , id : "29"},
  {name : "Guyane" , id : "30"},
  {name : "La Réunion" , id : "31"},
  {name : "Guadeloupe" , id : "32"},
  {name : "Hauts de France" , id : "102"},
  {name : "Sud" , id : "112"},
  {name : "Occitanie" , id : "124"},
  {name : "Bourgogne Franche Comté" , id : "156"},
  {name : "Normandie" , id : "304"},
  {name : "Auvergne Rhône Alpes" , id : "333"},
  {name : "Grand Est" , id : "789"},
  {name : "Nouvelle Aquitaine" , id : "892"},
])

const useRegionFilter = () => {
  function select(ligueId) {
    selectedLigue.value = ligues.value.find((r) => r.id === ligueId)
  }

  function reset() {
    selectedLigue.value = null
  }

  return {
    ligues,
    selectedLigue, // state
    select, reset // methods
  }
}

export default useRegionFilter