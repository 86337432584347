<template>
  <DonutWidget :composable="widget" :with-tooltip="true" />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {
  clubFilter,
  regionFilter,
  yearFilter,
  ageFilterPS,
  pratiqueFilter,
  genreFilterPS,
  handiFilter
} from '@/composables/useFilters'
import {API_PARAMS, buildDataset, getAgeCategoryColor, getAgeCategoryOrder} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";


function processElt(elt) {
  if (elt.categorieAge === "POUSSINS") return { ...elt, categorieAge: "Poussin", ordre: getAgeCategoryOrder("Poussin")}
  if (elt.categorieAge === "BENJAMINS") return { ...elt, categorieAge: "Benjamin", ordre: getAgeCategoryOrder("Benjamin")}
  if (elt.categorieAge === "CADETS") return { ...elt, categorieAge: "Cadet", ordre: getAgeCategoryOrder("Cadet")}
  if (elt.categorieAge === "MINIMES") return { ...elt, categorieAge: "Minime", ordre: getAgeCategoryOrder("Minime")}
  if (elt.categorieAge === "JUNIORS") return { ...elt, categorieAge: "Junior", ordre: getAgeCategoryOrder("Junior")}
  if (elt.categorieAge === "ESPOIRS") return { ...elt, categorieAge: "Espoir", ordre: getAgeCategoryOrder("Espoir")}
  if (elt.categorieAge === "SENIORS 1") return { ...elt, categorieAge: "Senior 1", ordre: getAgeCategoryOrder("Senior 1")}
  if (elt.categorieAge === "SENIORS 2") return { ...elt, categorieAge: "Senior 2", ordre: getAgeCategoryOrder("Senior 2")}
  if (elt.categorieAge === "SENIORS 3") return { ...elt, categorieAge: "Senior 3", ordre: getAgeCategoryOrder("Senior 3")}
  if (elt.categorieAge === "SENIORS 4") return { ...elt, categorieAge: "Senior 4", ordre: getAgeCategoryOrder("Senior 4")}
  if (elt.categorieAge === "SENIORS 5") return { ...elt, categorieAge: "Senior 5", ordre: getAgeCategoryOrder("Senior 5")}
  if (elt.categorieAge === "VETERANS") return { ...elt, categorieAge: "Vétéran", ordre: getAgeCategoryOrder("Vétéran")}
  return { ...elt, ordre: getAgeCategoryOrder(elt.categorieAge)}
}


const widget = useWidget('PSRepartitionClassesGenreWidget', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    ageFilterPS.selectedMinAge,
    ageFilterPS.selectedMaxAge,
    pratiqueFilter.selectedPratique,
    genreFilterPS.selectedGenre,
    handiFilter.isOn,
  ],

  query: {
    url: 'indicateurs/ps/regatiers',
    params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.AGE_PS, API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS,
        API_PARAMS.HANDI, API_PARAMS.A_DATE,
    ]
  },


  response: ([comparisonYear, selectedYear]) => {

    const legend = buildDataset(
        selectedYear.map(e => processElt(e)),
        comparisonYear.map(e => processElt(e)),
        'categorieAge',
        'nbRegatiers',
        true,
        false,
        'ordre',
    )

    return {
      graphData: legend.map(l => ({...l, label: l.legend, color: getAgeCategoryColor(l.legend)})),
    }
  }
})

</script>
