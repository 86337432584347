<template>
  <FilterSimple
    name="Compétitions"
    :elements="groupedCompetitions
      .sort((g1, g2) => g1.categorieOrdre - g2.categorieOrdre)
      .map(group =>
        group['competitions'].map(c => ({
          ...c,
          label: c.libelle,
          isSelected: isCompetitionTmpSelected(c),
        })).sort((c1, c2) => c2.ordre - c1.ordre)
    )"
    :elements-categories="groupedCompetitions.sort(
        (g1, g2) => g1.categorieOrdre - g2.categorieOrdre
    ).map(g => g['categorie'])"
    :select-element="selectCompetition"
    :confirm-selection="confirmCompetitions"
    :reset="resetCompetitions"
  />
</template>

<script setup>
  import {resultatFilter} from '@/composables/useFilters'
  import FilterSimple from "@/components/common/FilterSimple.vue";
  import {computed} from "vue";

  const {
    competitions,
    resetCompetitions,
    isCompetitionTmpSelected,
    selectCompetition,
    confirmCompetitions
  } = resultatFilter

  const groupedCompetitions = computed(() => {
      return Object.values(
          competitions.value.reduce((grouped, c) => {
            if (c.categorie in grouped) {
              grouped[c.categorie]['competitions'].push(c)
            } else {
              grouped[c.categorie] = {}
              grouped[c.categorie]['categorie'] = c.categorie
              grouped[c.categorie]['categorieOrdre'] = c.categorieOrdre
              grouped[c.categorie]['competitions'] = [c]
            }
            return grouped
          }, {})
      )
  })
</script>