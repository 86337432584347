<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{
          title: 'Nombre de bénévoles',
          isDeclarative: true,
        }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value - 1
          : undefined
      "
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, getCurrentYear, percentComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('BenevolesWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub
  ],

  query: {
    url: 'indicateurs/dev/rh/benevoles',
    params: [API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE]
  },

  response: ([comparisonYear, selectedYear]) => {
    comparisonYear = comparisonYear[0]
    selectedYear = selectedYear[0]

    return {
      value: selectedYear ? selectedYear.nbBenevoles : undefined,
      comparator: percentComparator(
        selectedYear ? selectedYear.nbBenevoles : undefined,
        comparisonYear ? comparisonYear.nbBenevoles : undefined,
        false,
          (yearFilter.singleYearFilter.value === getCurrentYear())
              ? (yearFilter.singleYearFilter.value - 2).toString()
              : (yearFilter.singleYearFilter.value - 1).toString()
      ),
      additionalData: [
        {
          text: "dirigeants bénévoles",
          relatif: "dont",
          value: selectedYear ? selectedYear.nbDirigeantsBenevoles : undefined,
          comparator: percentComparator(
            selectedYear ? selectedYear.nbDirigeantsBenevoles : undefined,
            comparisonYear ? comparisonYear.nbDirigeantsBenevoles : undefined,
            false,
            (yearFilter.singleYearFilter.value === getCurrentYear())
                ? (yearFilter.singleYearFilter.value - 2).toString()
                : (yearFilter.singleYearFilter.value - 1).toString()
          )
        },
      ]
    }
  }
})
</script>
