<template>
  <BaseContainer class="flex flex-col justify-center items-center gap-y-4 z-20">
    <!--TOGGLE-->
    <div
        v-if="!!toggleObject"
        class="flex justify-end w-full -mb-2"
        :class="{
          'md:my-0 md:absolute md:top-4 md:right-6': toggleObject.absolutePosition,
          'xl:my-0 xl:absolute xl:top-4 xl:right-6': toggleObject.absolutePosition !== false && (titleObject && titleObject.alignLeft),
        }"
    >
      <ToggleComponent
        :text="toggleObject.text"
        :checked="!!toggleObject.checked"
        :on-click="toggleObject.onClick"
      />
    </div>
    <!-- WIDGET TITLE -->
    <SimpleTitle
        v-if="titleObject && titleObject.title"
        :title="titleObject.title"
        :year="yearInTitle !== undefined ?  yearInTitle : yearFilter.singleYearFilter.value"
        :is-declarative="titleObject.isDeclarative"
        :declarative-text="titleObject.declarativeText"
        :align-left="titleObject.alignLeft"
        :description="titleObject.description"
        :info="titleObject.info"
    />

    <!-- WIDGET NO CONTENT -->
    <!-- Si erreur lors du chargement / transformation des données, voir console -->
    <div v-if="frontError" class="flex flex-col gap-y-1 px-4 text-grey">
      <span class="text-6xl font-bold">:/</span>
      <span>Une erreur est survenue lors du chargement des données</span>
    </div>
    <!-- WIDGET LOADING -->
    <div v-else-if="isLoading" class="relative h-36">
      <LoadingSpinner />
    </div>
    <!-- Si l'API n'a renvoyé aucune donnée (pas la même chose que si les données de l'API sont = 0) -->
    <div v-else-if="apiError">
      <span class="text-grey">Pas de données</span>
    </div>

    <!-- WIDGET CONTENT -->
    <div v-else class="flex flex-col text-center gap-y-4 w-full">
      <slot/>
      <MessageBanner
          v-if="aDate"
          class="w-full"
      >
        Pour ces données, les comparaisons sont faites à date pour l'année {{ getCurrentYear() }} (c'est à dire au {{ getComparisonDate() }}).
      </MessageBanner>
    </div>
  </BaseContainer>
</template>

<script setup>
import { toRefs} from 'vue'
import SimpleTitle from "@/components/common/SimpleTitle.vue";
import LoadingSpinner from "@/components/common/LoadingSpinner.vue";
import BaseContainer from "@/components/common/BaseContainer.vue";
import ToggleComponent from "@/components/common/ToggleComponent.vue";
import {getComparisonDate, getCurrentYear} from "@/composables/widgets/_widgetUtils";
import MessageBanner from "@/components/common/MessageBanner.vue";
import {yearFilter} from "@/composables/useFilters";

const props = defineProps({
  titleObject: Object, // { title: String, year: Number, isDeclarative: Boolean, declarativeText: String, alignLeft: Boolean },
  toggleObject : Object, // { text: String, checked: Boolean, onClick: Function, absolutePosition: Boolean }
  frontError : Boolean,
  apiError: Boolean,
  isLoading: Boolean,
  yearInTitle: Number,
  aDate: Boolean
})
const { titleObject } = toRefs(props)


</script>
