import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { applyMiddlewares } from './middlewares'

const routes = [
  {
    path: '/',
    name: 'home',
    meta : { title : "FFVoile - Indicateurs" },
    component: HomeView
  },
  {
    path: '/home',
    name: 'ACCNationalView',
    props: true,
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/home/ligue/:ligueId',
    name: 'ACCRegionView',
    props: true,
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/home/structure/:clubId',
    name: 'ACCClubView',
    props: true,
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/developpement',
    name: 'DEVNationalView',
    props: true,
    component: () => import('@/views/DeveloppementView.vue'),
  },
  {
    path: '/developpement/ligue/:ligueId',
    name: 'DEVRegionView',
    props: true,
    component: () => import('@/views/DeveloppementView.vue'),
  },
  {
    path: '/developpement/structure/:clubId',
    name: 'DEVClubView',
    props: true,
    component: () => import('@/views/DeveloppementView.vue')
  },
  {
    path: '/haute-performance',
    name: 'HPNationalView',
    component: () => import('@/views/HPView.vue'),
  },
  {
    path: '/haute-performance/ligue/:ligueId',
    name: 'HPRegionView',
    props: true,
    component: () => import('@/views/HPView.vue'),
  },
  {
    path: '/haute-performance/structure/:clubId',
    name: 'HPStructureView',
    props: true,
    component: () => import('@/views/haute-performance/StructureView.vue'),
  },
  {
    path: '/pratiques-sportives',
    name: 'PSNationalView',
    component: () => import('@/views/PSView.vue'),
  },
   {
    path: '/pratiques-sportives/ligue/:ligueId',
    name: 'PSRegionView',
    props: true,
    component: () => import('@/views/PSView.vue'),
   },
  {
    path: '/pratiques-sportives/structure/:clubId',
    name: 'PSStructureView',
    props: true,
    component: () => import('@/views/PSView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const hasQueryParams = (route) => {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from) => {
  if(!hasQueryParams(to) && hasQueryParams(from)) {
    return ({ ...to, query: from.query });
  }
})

applyMiddlewares(router)

export default router
