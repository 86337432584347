<template>
  <div
      class="flex flex-col gap-y-2 bg-red w-full rounded-2xl p-4"
      v-click-outside="() => {
        searchInput = ''
      }"
  >
    <div v-if="hasStructureSelected()">
      <div class="flex justify-between items-center text-dark p-1 bg-white rounded-lg">
        <span class="w-full text-center text-xl font-bold font-regular">
          {{ getSelectedStructure() }}
        </span>
        <router-link @click.prevent="closeMenu" :to="`/${pageId}`" class="px-1 mb-1 cursor-pointer text-2xl hover:opacity-75">{{ "\u00d7" }}</router-link>
      </div>
    </div>
    <div class="flex flex-col gap-y-2">
      <div class="flex flex-col">
        <span class="text-lg font-bold font-regular">Rechercher une structure</span>
        <span class="text-xs">Entrer le nom d'une structure ou d'une ville</span>
      </div>
      <div class="relative">
        <div class="flex items-center gap-x-2 p-2 bg-white rounded-lg shadow-greyBorder shadow-inner border border-red hover:border-white focus-within:border-white">
          <span class="icon icon-search"></span>
          <input
              v-model="searchInput"
              @input="filterStr()"
              type="search"
              placeholder="Ex : Marseille"
              class="text-dark focus-visible:outline-none"
          >
        </div>
        <div
            class="absolute bottom-full mb-1 p-1 bg-white rounded-lg shadow z-20 w-full max-h-48 overflow-auto"
            :class="{'hidden': !searchInput
                || (isNumeric(searchInput) && removeSpaceAndPunctuation(searchInput).length < 2)
                || (!isNumeric(searchInput) && removeSpaceAndPunctuation(searchInput).length < 3)
            }"
        >
          <div v-if="results.length > 0" class="flex flex-col gap-y-1 w-full">
            <router-link
                v-for="club in results" :key="club.id"
                :to="`/${pageId}/structure/${club.id}`"
                class="text-dark text-sm p-2 rounded bg-lightGrey hover:bg-dark hover:text-white"
                @click="() => {
                  closeMenu()
                  resetSearch()
                }"
            >
              <span>{{ club.name }} - {{club.id}}</span>
            </router-link>
          </div>
          <div v-else class=" text-grey p-1">Pas de structure trouvée</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, toRefs} from 'vue'
import {clubFilter } from '@/composables/useFilters'
import {isNumeric, removeSpaceAndPunctuation} from "@/composables/widgets/_widgetUtils";

const props = defineProps({
  pageId: String,
  closeMenu: Function,
})

const { pageId } = toRefs(props)

const searchInput = ref("")
const results = ref([])

onMounted(async () => {
  if (clubFilter.allClubs.value.length === 0) await clubFilter.fetchStructures();
  if (clubFilter.allPPFStructures.value.length === 0) await clubFilter.fetchStructures(true);
})

function filterStr() {
  results.value = clubFilter.searchFor(searchInput.value, pageId.value === 'haute-performance').map((r) => ({
    'name': r.libelle,
    'id': r.code,
  }))
}

function resetSearch() {
  searchInput.value = ""
  results.value = []
}

const hasStructureSelected = () => {
  return (pageId.value === 'haute-performance') ? (
     (!!clubFilter.selectedPPFStructure.value  && !!clubFilter.selectedPPFStructure.value.code)
  ) : (!!clubFilter.selectedClub.value  && !!clubFilter.selectedClub.value.code)
}

const getSelectedStructure = () => {
  return (pageId.value === 'haute-performance') ? (
      clubFilter.selectedPPFStructure.value.libelle
    ) : (
      clubFilter.selectedClub.value.libelle
    )
}
</script>