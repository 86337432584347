<template>
  <Bar
    id="VerticalBarChart"
    :data="chartData"
    :options="verticalBarOptions"
  />
</template>

<script setup>

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

defineProps({
  chartData: Object,
  chartOptions: Object
})

const verticalBarOptions = {
  responsive: true,

  borderColor: 'white',
  borderWidth: 1,
  borderRadius: 5,
  barThickness: 'flex',
  barPercentage: 1,

  interaction: {
    intersect: false,
    mode: 'index',
    axis: 'x',
  },

  scales: {
    x: {
      stacked: true,
      grid: { display: false },
      beginAtZero: true
    },
    y: {
      stacked: true,
      grid: { color: '#F2F3F5' },
      beginAtZero: true
    }
  },

  plugins: {
    tooltip: {
      itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
      callbacks: {
        label: (ctx) => {
          const tLabel = ctx.dataset.tooltipLabel
          if(tLabel) {
            return (tLabel.before || "") + ctx.formattedValue + (tLabel.after || "")
          }
          
          return ctx.formattedValue
        }
      },
      backgroundColor: 'rgba(255, 255, 255, .9)',
      titleColor: 'black',
      bodyColor: 'black',
      borderColor: 'black',
      borderWidth: 1,
      titleMarginBottom: 10,
      padding: 10,
      bodySpacing: 10,
      boxPadding: 5,
      usePointStyle: true,
    },
    legend: { display: false },
    datalabels: { display: false }
  }
}
</script>