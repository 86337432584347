import { ref, onMounted, onUnmounted, watch, reactive } from 'vue';
import { yearFilter, clubFilter } from '../useFilters';
import Api from '@/composables/useApi';

const filterData = reactive({
  totalMembers : { previousYear: 0, currentYear: 0 , difference : 0},
  values: [],
  secteurCode: null,
  codeBateau: null
});



const rawData = ref(null);

const useDiscAndSuppFilter = (name) => {
  const selectedYear = ref([]);
  const comparisonYear = ref([]);

  onMounted(() => {
    fetchButtons();
    const watchers = [
      watch(yearFilter.singleYearFilter, fetchButtons),
      watch(clubFilter.selectedPPFStructure, fetchButtons),
    ];
    onUnmounted(() => watchers.forEach(unwatch => unwatch()));
  });

  /********************************************************
   🔀 API FETCH DATA
   after fetching, we start ProcessButtons() method
   *********************************************************/
  const fetchButtons = async () => {
    const query = {
      type: { type: 'structure' },
      annees: {
        anneeDebut: yearFilter.singleYearFilter.value - 1,
        anneeFin: yearFilter.singleYearFilter.value
      },
      structures: {
        structureCodes: clubFilter.selectedPPFStructure.value ? [clubFilter.selectedPPFStructure.value.code] : []
      }
    };

    try {
      const res = await Api.post('/stats/indicateursperformance/disciplineandsupport', query);
      rawData.value = res.data;
      processButtons();
    } catch (err) {
      console.error(name, err);
    }
  };

  /* Helper to clone object */
  const deepClone = obj => JSON.parse(JSON.stringify(obj));

  /* structure for results */
  const initializeStructure = () => ({
    currentYear: 0,
    previousYear: 0,
    difference: 0,
    secteurLibelle: null,
    boats: {}
  });


  const processYearData = (accumulator, dataItem, year) => {
    accumulator[year] += dataItem.nbAthletes || 0;
    if (!accumulator.boats[dataItem.codeBateau]) {
      accumulator.boats[dataItem.codeBateau] = { currentYear: 0, previousYear: 0, difference: 0, bateauLibelle: dataItem.bateauLibelle || 'Non défini' };
    }
    accumulator.boats[dataItem.codeBateau][year] = dataItem.nbAthletes || 0;
    accumulator.boats[dataItem.codeBateau].difference = accumulator.boats[dataItem.codeBateau].currentYear - accumulator.boats[dataItem.codeBateau].previousYear;
  }


  /********************************************************************************************
   *        🔀 processButtons - Processes results from API.                                    *
   *                                                                                           *
   *        - Filters data for the current and previous year.                                  *
   *        - Aggregates the total number of members for each year and computes the difference *
   *        - Structures the results by secteurCode and boat categories.                       *
   *        - For each secteurCode, calculates the difference between                          *
   *          the current and previous year's values.                                          *
   *                                                                                           *
   *        This function ensures that the filterData reactive                                 *
   *        object holds a structured and aggregated form of the                               *
   *        raw data for easy access and further computations.                                 *
   ********************************************************************************************/
  const processButtons = () => {
    if (!rawData.value) return;

    const currentYear = yearFilter.singleYearFilter.value;
    const previousYear = currentYear - 1;

    selectedYear.value = rawData.value.filter(el => el.annee === currentYear);
    comparisonYear.value = rawData.value.filter(el => el.annee === previousYear);

    filterData.totalMembers = {
      previousYear: comparisonYear.value.reduce((sum, item) => sum + item.nbAthletes, 0),
      currentYear: selectedYear.value.reduce((sum, item) => sum + item.nbAthletes, 0)
    }
    filterData.totalMembers.difference = filterData.totalMembers.currentYear - filterData.totalMembers.previousYear;

    filterData.values = rawData.value.reduce((acc, item) => {
      if (!item.secteurCode) {
        item.secteurCode = null
        item.secteurLibelle = 'Non défini'
      }

      if (!acc[item.secteurCode]) {
        acc[item.secteurCode] = deepClone(initializeStructure());
        acc[item.secteurCode].secteurLibelle = item.secteurLibelle || 'Non défini';
      }

      if (item.annee === currentYear) {
        processYearData(acc[item.secteurCode], item, 'currentYear');
      } else if (item.annee === previousYear) {
        processYearData(acc[item.secteurCode], item, 'previousYear');
      }

      acc[item.secteurCode].difference = acc[item.secteurCode].currentYear - acc[item.secteurCode].previousYear;

      return acc;
    }, {});
  };


  const selectValue = (secteurCode, codeBateau = null) => {
    filterData.secteurCode = secteurCode
    filterData.codeBateau = codeBateau
  }

  const isSelected = (secteurCode, codeBateau = null) => {
    return filterData.secteurCode === secteurCode && (
        codeBateau === null ||  filterData.codeBateau === codeBateau)
  }

  const resetSelect = () => {
    filterData.secteurCode = null
    filterData.codeBateau = null
  }

  const getSortedFilterData = () => Object.entries(filterData.values).sort((d1, d2) => d2[1].currentYear - d1[1].currentYear)


  return {
    filterData,
    getSortedFilterData,
    isSelected,
    selectValue,
    resetSelect,
  };
}

export default useDiscAndSuppFilter;