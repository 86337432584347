<template>
  <HorizontalBarWidget
      :title-object="{title: 'Répartition par grade'}"
      :composable="widget"
  />
</template>
  
<script setup>
import useButtonWidgetPS from '@/composables/widgets/useButtonWidgetPS'
import HorizontalBarWidget from "@/components/widgets/templates/HorizontalBarWidget.vue";
import {buildDataset} from "@/composables/widgets/_widgetUtils";


const widget = useButtonWidgetPS(
  "PSRepartitionGradeWidget",
  {
    type: "liste_eo",
    indicator: "grade",
    endpoint: '/indicateurs/ps/epreuves/organisees',
    response: ([comparisonYear, selectedYear]) => {
      return (selectedYear.length) ? {
        data: buildDataset(
            selectedYear,
            comparisonYear,
            'grade',
            'nombreEpreuves',
            true,
            false,
            'gradeOrdre',
        )
      } : null
    }
  }
)
</script>
