// Paramètres des requêtes pour widgets
import {yearFilter} from "@/composables/useFilters";

export const API_PARAMS = {
  YEARS: 'years',
  YEARS_WITH_PREVIOUS_FOR_CURRENT: 'years_previous_for_current',
  ARRAY_YEARS: 'yearInArray',
  AGE_PS: 'age_ps',
  AGE_DEV: 'age_dev',
  GENRE_PS: 'genre',
  GENRE_DEV: 'genre',
  LIGUE: 'ligue',
  STRUCTURE: 'structure',
  LICENCE: 'licence',
  PRATIQUE: 'discipline',
  A_DATE: 'a_date',
  HANDI: 'handi',
  ARBITRAGE_QUALIF: 'arbitrage_qualif',
  ARBITRAGE_NIVEAU: 'arbitrage_niveau',
}

// Palette de couleurs pour les graphs
export const PALETTE = {
  CHART: [
      // BECAREFUL DO NOT MODIFY COLORS BELOW WITHOUT MODIFYING tailwindconfig.js
    "#1c2d6f", // bleu marine
    "#e30000", // rouge
    "#0092dd", // bleu
    "#00c4ff", // bleu clair
    "#72e7ff", // bleu très léger
    "#CF6D17", // marron
    "#FC6F03", // orange
    "#FFA10A", // orange clair
    "#FFE222", // jaune
    "#1A7475", // vert foncé
    "#26B577", // vert clair
    "#01E486", // vert très clair
    "#B6F565",  // vert très très clair
  ],
  CHART_TW: [
    // BECAREFUL DO NOT MODIFY COLORS BELOW WITHOUT MODIFYING tailwindconfig.js
    "bg-chart0", // bleu marine
    "bg-chart1", // rouge
    "bg-chart2", // bleu
    "bg-chart3", // bleu clair
    "bg-chart4", // bleu très léger
    "bg-chart5", // marron
    "bg-chart6", // orange
    "bg-chart7", // orange clair
    "bg-chart8", // jaune
    "bg-chart9", // vert foncé
    "bg-chart10", // vert clair
    "bg-chart11", // vert très clair
    "bg-chart12",  // vert très très clair
  ],
  HALF_DONUT: [
    "#e30000", // rouge
    "#eaeaea"   // gris
  ],
  BUBBLE: [
    "#1c2d6fa2", // bleu marine
    "#e30000", // rouge
    "#1c2d6f" // transparent

  ],
  EMPTY: [
    "#eeeeee", // blanc
    "#eeeeee", // blanc
    "#eeeeee", // blanc
    "#eeeeee", // blanc
    "#eeeeee", // blanc
    "#eeeeee", // blanc
  ]
}

// Permet de mettre des interespaces pour les grands chiffres.
// Utilisé dans les composants Widgets
// ~> 4772863 => 4 772 863
export function formatNumber(number) {
  if (number === 0) return 0

  if (!number) return '-'

  let i = 0
  return Array.from(number.toString())
    .reverse()
    .reduce((acc, val) => {
      if (val.match(/\./)) i = 0
      if (val.match(/\d/)) i++
      
      if (i > 0 && i % 3 === 0) acc.push(val, " ")
      else acc.push(val)
      
      return acc
    }, [])
    .reverse()
    .join('')
    .replace(/^\s+|\s+$/g, '')
}

// ----------------------------------------------------------------------------------------------------
// COMPARATORS

// Calcule le pourcentage de différence entre 2 valeurs
// Utilisé dans les compsables widgets pour les comparateurs
export function processPercentageDiff(selectedValue, comparisonValue) {
  if ([null ,undefined].includes(selectedValue) || !comparisonValue) {
    return undefined
  }
  const dif = selectedValue - comparisonValue
  return Math.round(dif / comparisonValue * 100)
}

export function percentComparator(selectedValue, comparisonValue, reverse = false, comparison = undefined) {
  return {
    percent: true,
    value: processPercentageDiff(
        reverse ? comparisonValue : selectedValue,
        reverse ? selectedValue : comparisonValue,
    ),
    comparison: comparison === undefined
        ? getDefaultComparison()
        : comparison
  }
}

export function rawComparator(selectedValue, comparisonValue, decimals = 0, reverse = false, comparison = undefined) {
  if ([null ,undefined].includes(selectedValue) || [null ,undefined].includes(comparisonValue)) {
    return undefined
  }
  const diff = reverse
    ? (comparisonValue ? comparisonValue : 0) - (selectedValue ? selectedValue : 0)
    : (selectedValue ? selectedValue : 0) - (comparisonValue ? comparisonValue : 0)
  return {
    percent: false,
    value: decimals === 0 ? Math.round(diff) : Math.round(diff * 10 * decimals) / (10 * decimals),
    comparison: comparison === undefined ? getDefaultComparison() : comparison
  }
}

// Moyenne brute = total des âges / nombres d'âges différents
export function getAverage(datas) {
  const total = datas.map((data) => Number(data.groupByField), 0)
  const avg = total.reduce((tot, val) => tot + val, 0) / total.length
  return Math.round(avg)
}

// Moyenne pondérée = médiane
// https://la.utexas.edu/users/denbow/labs/Ageofdeath.htm
export function weightedMedian(datas) {
  if(!Array.isArray(datas)) return 0

  const total = datas.reduce((tot, val) => tot + val.nbAthletes, 0)
  const calc = datas.reduce((tot, val) => tot + Number(val.groupByField) * val.nbAthletes, 0)
  const median = calc / total

  if(isNaN(median)) return 0
  return Math.round(median.toFixed(2))
}

const createDataMap = (data, keyName, valueName) => {
  return data.reduce((map, el) => {
    if (!map[el[keyName]]) {
      map[el[keyName]] = el[valueName];
    } else {
      map[el[keyName]] += el[valueName];
    }
    return map;
  }, {});
}

export const buildReducedData = (
    selectedYear,
    comparisonYear,
    valueName,
    percentComparison = false,
    reverseComparison = false,
) => {
  const value = selectedYear.reduce((acc, val) => acc + val[valueName], 0)
  const compValue = comparisonYear.reduce((acc, val) => acc + val[valueName], 0)

  return ({
    value,
    comparator: percentComparison ? percentComparator(
        value,
        compValue,
        reverseComparison,
    ) : rawComparator(
        value,
        compValue,
        0,
        reverseComparison,
    )
  })
}

export const buildDataset = (
    selectedYear,
    comparisonYear,
    keyName,
    valueName,
    percentComparison = false,
    reverseComparison = false,
    sortKeyName = null,
    sortDesc = false,
    sortKeyReduce = false,
    replaceWithZero = true,
    comparison = undefined
) => {
  const selectedDataMap = createDataMap(selectedYear, keyName, valueName);
  const comparisonDataMap = createDataMap(comparisonYear, keyName, valueName);

  const allData = [...comparisonYear, ...selectedYear]
  const legendLabels = Array.from(new Set([
    ...allData.map(data => data[keyName]),
  ]))
  if (sortKeyName !== null) {
    legendLabels.sort((l1, l2) => {
      const filteredData1 = sortKeyReduce
          ? selectedYear.filter((el) => el[keyName] === l1)
          : selectedYear.filter((el) => el[keyName] === l1).concat(comparisonYear.filter((el) => el[keyName] === l1))
      const filteredData2 = sortKeyReduce
          ? selectedYear.filter((el) => el[keyName] === l2)
          : selectedYear.filter((el) => el[keyName] === l2).concat(comparisonYear.filter((el) => el[keyName] === l2))

      const value1 = getSortValue(filteredData1, sortKeyName, sortKeyReduce)
      const value2 = getSortValue(filteredData2, sortKeyName, sortKeyReduce)
      if (sortDesc)
        return value2 < value1 ? -1 : 1
      return value1 < value2 ? -1 : 1
    })
  }

  return legendLabels.map(
    (labelName) => {
      const selectedData = selectedDataMap[labelName] || (replaceWithZero ? 0 : null);
      const comparisonData = comparisonDataMap[labelName] || (replaceWithZero ? 0 : null);
      return {
        value: selectedData,
        comparator: percentComparison
            ? percentComparator(selectedData, comparisonData, reverseComparison, comparison)
            : rawComparator(selectedData, comparisonData, 0, reverseComparison, comparison),
        legend: labelName
      }
    }
  )
}

export const getSortValue = (data, sortKeyName, sortKeyReduce) => {
  if (sortKeyReduce) return data.reduce((acc, val) => acc + val[sortKeyName], 0)
  else {
    return data.length > 0 ? (data[0][sortKeyName] || 0) : 0
  }
}

export const isNumeric = (str) => !isNaN(str) && !isNaN(parseFloat(str))

export const searchStructure = (str, allStructures) => {
  const string = removeSpaceAndPunctuation(str)
  if (isNumeric(string) && string.length > 1) {
    return allStructures.filter((st) => (
        st.code.toString().slice(0, string.length).includes(string)
    )).sort((s1, s2) => s1.code - s2.code)
  }
  else if (!isNumeric(string) && string.length > 2) {
    return allStructures.filter((st) => (
        st.libelle
        && (
            removeSpaceAndPunctuation(st.libelle).toLowerCase().includes(string.toLowerCase())
            || (st.ville && removeSpaceAndPunctuation(st.ville).toLowerCase().includes(string.toLowerCase()))
        )
    )).sort((s1, s2) => s1.libelle < s2.libelle ? -1 : 1)
  } else {
    return []
  }
}

export const removeSpaceAndPunctuation = (str) => str.replace(/[\s-/.,]+/g, '')

export const sortAlphabetically = (e1, e2, reverse = false) => {
  const baseInt = reverse ? 1 : -1
  return e1.toLowerCase() <= e2.toLowerCase() ? baseInt : baseInt * -1
}

export const getCurrentYear = () => new Date().getFullYear()

export const getComparisonDate = () => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - 1)
  return date.toLocaleDateString()
}

export const getDefaultComparison = () => {
  return (yearFilter.singleYearFilter.value === getCurrentYear())
      ? getComparisonDate()
      : (yearFilter.singleYearFilter.value - 1).toString()
}

// TODO : Get orders from API return
export const getAgeCategoryOrder = (ageCategory) => {
  if (ageCategory === "Poussin 0") return 0
  else if (ageCategory === "Poussin") return 1
  else if (ageCategory === "Benjamin") return 2
  else if (ageCategory === "Minime") return 3
  else if (ageCategory === "Cadet") return 4
  else if (ageCategory === "Junior") return 5
  else if (ageCategory === "Espoir") return 6
  else if (ageCategory === "Senior 1")  return 7
  else if (ageCategory === "Senior 2") return 8
  else if (ageCategory === "Senior 3") return 9
  else if (ageCategory === "Senior 4") return 10
  else if (ageCategory === "Senior 5") return 11
  else if (ageCategory === "Vétéran") return 12
  else return 13
}

export const getAgeCategoryColor = (ageCategory) => {
  if (ageCategory === "Poussin 0") return '#8396e0'
  else if (ageCategory === "Poussin") return '#647bd8'
  else if (ageCategory === "Benjamin") return '#4461d0'
  else if (ageCategory === "Minime") return '#2c46ae'
  else if (ageCategory === "Cadet") return '#1c2d6f'
  else if (ageCategory === "Junior") return '#ffbcbc'
  else if (ageCategory === "Espoir") return '#ff9595'
  else if (ageCategory === "Senior 1")  return'#ff6d6d'
  else if (ageCategory === "Senior 2") return '#ff4646'
  else if (ageCategory === "Senior 3") return '#ff1f1f'
  else if (ageCategory === "Senior 4") return '#e30000'
  else if (ageCategory === "Senior 5") return '#bc0000'
  else if (ageCategory === "Vétéran") return '#950000'
  else return '#eeeeee'
}

export const processGender = (label) => {
  if (label === "M") return "Homme"
  else if (label === "F") return "Femme"
  else return "Non renseigné"
}