<template>
  <FilterComponent
      name="Disciplines"
      :on-filter-close="confirmSeriesSelection"
  >
    <div>
      <div class="flex flex-col gap-y-2 max-w-lg max-h-96 overflow-y-scroll text-sm">
        <div v-for="categorie in series" :key="categorie.id" @click.stop="">
          <SerieFilterCategorie
              :name="categorie.name"
              :selected="categorie.selected"
              :select="() => SUPER_TOGGLE(categorie)"
              :open="() => openCategorie(categorie)"
              :is-open="categorie.isOpen"
          />
          <div v-if="categorie.isOpen" class="flex flex-col ml-2 mt-2 gap-y-2">
            <div v-for="sCategorie in categorie.children" :key="sCategorie.id" @click.stop="">
              <SerieFilterCategorie
                :name="sCategorie.name"
                :selected="sCategorie.selected || categorie.selected"
                :select="() => SUPER_TOGGLE(sCategorie)"
                :open="() => openCategorie(sCategorie)"
                :is-open="sCategorie.isOpen"
              />
              <div v-if="sCategorie.isOpen" class="flex flex-col ml-2 mt-2 gap-y-2">
                <div
                    v-for="serie in sCategorie.children"
                    :key="serie.id"
                    class="flex gap-x-4 bg-lightGrey p-2 rounded-lg justify-between items-center"
                    @click="() => SUPER_TOGGLE(serie)"
                >
                  <span>{{serie.name}} {{ serie.sexe && `- ${serie.sexe}` }}</span>
                  <div class="check-animation">
                    <span class="icon icon-Vector-3" :class="{'animateCheck': serie.selected || sCategorie.selected || categorie.selected }"></span>
                    <span class="icon icon-check-alone" :class="{'animateCheck': serie.selected || sCategorie.selected || categorie.selected }"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResetFilterButton :on-button-click="resetSeries" class="w-full" />
    </div>
  </FilterComponent>
</template>

<script setup>

import { resultatFilter } from '@/composables/useFilters'
import FilterComponent from "@/components/common/FilterComponent.vue";
import SerieFilterCategorie
  from "@/components/pages/haute-performance/filtres/resultat-filter/SerieFilterCategorie.vue";
import ResetFilterButton from "@/components/common/ResetFilterButton.vue";

defineProps({ isOpen: Boolean })

const { series, confirmSeriesSelection, SUPER_TOGGLE, openCategorie, resetSeries } = resultatFilter

</script>