<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width="100%"
      height="100%"
      xml:space="preserve"
  >
      <g>
        <path d="M512.5,88.5c-234.2,0-424,189.8-424,424s189.8,424,424,424s424-189.8,424-424S746.7,88.5,512.5,88.5z    M562,714c0,13.6-5.7,25.6-14.6,35.4c-9,9.8-22.4,14.1-35.4,14.6c-12.5,0.6-26.7-6-35.4-14.6c-9.1-9.1-14.6-22.5-14.6-35.4V507   c0-13.5,5.7-25.6,14.6-35.3c9-9.8,22.4-14.1,35.4-14.7c12.5-0.6,26.7,6,35.4,14.7c9.1,9.1,14.6,22.5,14.6,35.3V714z M562,324v2   c0,9-2.3,17.4-6.8,25.2c-2.6,3.4-5.2,6.7-7.8,10.1c-6.2,6.2-13.6,10.5-22.1,12.9c-4.4,0.6-8.9,1.2-13.3,1.8   c-6.9,0.3-13.3-1.2-19.3-4.3c-6.2-2-11.6-5.5-16.1-10.3c-4.9-4.5-8.3-9.9-10.3-16.1c-3.1-6-4.6-12.4-4.3-19.3v-2   c0-9,2.3-17.4,6.8-25.2c2.6-3.4,5.2-6.7,7.8-10.1c6.2-6.2,13.6-10.5,22.1-12.9c4.4-0.6,8.9-1.2,13.3-1.8c6.9-0.3,13.3,1.2,19.3,4.3   c6.2,2,11.6,5.5,16.1,10.3c4.9,4.5,8.3,9.9,10.3,16.1C560.8,310.7,562.3,317.1,562,324z"/>
      </g>
  </svg>
</template>