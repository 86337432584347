import { ref } from "vue"
import Api from '@/composables/useApi'
const NIVEAUX = ref([{ codeNiveau: null, libelleNiveau: "Tous" }])
const selectedNiveau = ref([{ codeNiveau: null, libelleNiveau: "Tous" }])
const tmpSelectedNiveau = ref([{ codeNiveau: null, libelleNiveau: "Tous" }])
const useNiveauxArbitrageFilter = () => {
  async function fetchNiveaux() {
    const { data } = await Api.get('/indicateurs/arbitrage/niveaux')

    NIVEAUX.value.push(...data.sort(
        (n1, n2) => n1.ordreNiveau - n2.ordreNiveau
    ));

    selectedNiveau.value = [NIVEAUX.value[0]]
    tmpSelectedNiveau.value = [NIVEAUX.value[0]]
  }

  const isNiveauSelected = (niveau) =>  selectedNiveau.value.map((n) => n.codeNiveau).includes(niveau.codeNiveau)
  const isNiveauTmpSelected = (niveau) =>  tmpSelectedNiveau.value.map((n) => n.codeNiveau).includes(niveau.codeNiveau)

  const isAll = (q) => q.codeNiveau === null
  const isAllSelected = () => selectedNiveau.value.length === 1 && selectedNiveau.value.at(0).codeNiveau === null
  const isAllTmpSelected = () => tmpSelectedNiveau.value.length === 1 && tmpSelectedNiveau.value.at(0).codeNiveau === null

  function select(niveau) {
    if (isNiveauTmpSelected(niveau)) {
      tmpSelectedNiveau.value = tmpSelectedNiveau.value.filter((n) => n.codeNiveau !== niveau.codeNiveau)
      if (tmpSelectedNiveau.value.length === 0) {
        tmpSelectedNiveau.value = [NIVEAUX.value.at(0)]
      }
    } else if (isAllTmpSelected() || isAll(niveau)) {
      tmpSelectedNiveau.value = [niveau]
    } else {
      tmpSelectedNiveau.value = tmpSelectedNiveau.value.concat(niveau)
    }
  }

  const confirmSelection = () => selectedNiveau.value = tmpSelectedNiveau.value

  const reset = () => {
    if (NIVEAUX.value) {
      selectedNiveau.value = [NIVEAUX.value.at(0)]
      tmpSelectedNiveau.value = [NIVEAUX.value.at(0)]
    }
  }

  return {
    NIVEAUX,
    fetchNiveaux,
    selectedNiveau,
    isNiveauSelected,
    isNiveauTmpSelected,
    isAllSelected,
    select,
    confirmSelection,
    reset
  }
}

export default useNiveauxArbitrageFilter;