import { ref } from "vue"
import Api from '@/composables/useApi'

const QUALIFICATIONS = ref([{ tafId: null, tafLibelle: "Toutes" }])
const selectedQualif = ref([{ tafId: null, tafLibelle: "Toutes" }])
const tmpSelectedQualif = ref([{ tafId: null, tafLibelle: "Toutes" }])
const useQualifsArbitrageFilter = () => {
  async function fetchQualifs() {
    const { data } = await Api.get('/indicateurs/arbitrage/qualifications')

    QUALIFICATIONS.value.push(...data.sort(
        (q1, q2) => q1.tafLibelle < q2.tafLibelle ? -1 : 1
    ));

    selectedQualif.value = [QUALIFICATIONS.value[0]]
    tmpSelectedQualif.value = [QUALIFICATIONS.value[0]]
  }

  const isQualifSelected = (qualif) =>  selectedQualif.value.map((q) => q.tafId).includes(qualif.tafId)
  const isQualifTmpSelected = (qualif) =>  tmpSelectedQualif.value.map((q) => q.tafId).includes(qualif.tafId)

  const isAll = (q) => q.tafId === null
  const isAllSelected = () => selectedQualif.value.length === 1 && selectedQualif.value.at(0).tafId === null
  const isAllTmpSelected = () => tmpSelectedQualif.value.length === 1 && tmpSelectedQualif.value.at(0).tafId === null

  function select(qualif) {
    if (isQualifTmpSelected(qualif)) {
      tmpSelectedQualif.value = tmpSelectedQualif.value.filter((p) => p.tafId !== qualif.tafId)
      if (tmpSelectedQualif.value.length === 0) {
        tmpSelectedQualif.value = [QUALIFICATIONS.value.at(0)]
      }
    } else if (isAllTmpSelected() || isAll(qualif)) {
      tmpSelectedQualif.value = [qualif]
    } else {
      tmpSelectedQualif.value = tmpSelectedQualif.value.concat(qualif)
    }
  }

  const confirmSelection = () => selectedQualif.value = tmpSelectedQualif.value

  const reset = () => {
    if (QUALIFICATIONS.value) {
      selectedQualif.value = [QUALIFICATIONS.value.at(0)]
      tmpSelectedQualif.value = [QUALIFICATIONS.value.at(0)]
    }

  }
  

  return {
    QUALIFICATIONS,
    fetchQualifs,
    selectedQualif,
    isQualifSelected,
    isQualifTmpSelected,
    isAllSelected,
    select,
    confirmSelection,
    reset
  }
}

export default useQualifsArbitrageFilter