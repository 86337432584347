<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{
        title: `${licenceFilter.selectedLicence.value.title}`,
      }"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {
  licenceFilter,
  clubFilter,
  regionFilter,
  yearFilter,
  ageFilterDev, genreFilterDev
} from '@/composables/useFilters'
import {API_PARAMS, percentComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('TotalLicences Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    ageFilterDev.selectedMinAge,
    ageFilterDev.selectedMaxAge,
    licenceFilter.selectedLicence,
    genreFilterDev.selectedGenre
  ],
  
  query: {
    url: 'indicateurs/global/titres',
    params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.AGE_DEV, API_PARAMS.GENRE_DEV,
        API_PARAMS.A_DATE,
    ]
  },

  response: ([comparisonYear, selectedYear]) => {

    if(!selectedYear|| !selectedYear.length) return null

    selectedYear = selectedYear.find(d => d.licenceClasse === licenceFilter.selectedLicence.value.apiId)
    const selectedYearData = selectedYear ? selectedYear.quantity : undefined
    
    comparisonYear = comparisonYear.find(d => d.licenceClasse === licenceFilter.selectedLicence.value.apiId)
    const comparisonYearData = comparisonYear ? comparisonYear.quantity : undefined
    return {
      value: selectedYearData,
      comparator: percentComparator(selectedYearData, comparisonYearData),
    }
  }
})
</script>