import VueMatomo from 'vue-matomo';


import app from './app.js'
import router from './router'
import './index.css'

app
.use(VueMatomo, {
    // Configuration Options
    host: 'https://ffvoile.matomo.cloud/',
    siteId: 38,
    router,
})
.use(router)
.mount('#app')