<!--
  WIDGET HP- STRUCTURE - WIDGET / FILTRES
-->

<template>
  <BaseWidget transparent>
    <div class="flex flex-col items-center gap-y-4">
      <div
        class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2"
        :class="{
          '!grid-cols-1': getSortedFilterData().length === 0,
          '!grid-cols-2': getSortedFilterData().length === 1,
          'xl:!grid-cols-3': getSortedFilterData().length === 2,
          '2xl:!grid-cols-4': getSortedFilterData().length === 3,
        }"
      >
        <FilterButtonIndicator
          name="Tous les athlètes"
          :value="filterData.totalMembers.currentYear"
          :evolution-object="{
            comparatorValue: filterData.totalMembers.difference,
            isPercent: false,
          }"
          :selected="isSelected(null)"
          @click="resetSelect()"
          :larger-label="true"

        />
        <FilterButtonIndicator
          v-for="([secteurCode, button]) in getSortedFilterData()"
          :key="secteurCode"
          @click="selectValue(secteurCode)"
          :name="button.secteurLibelle"
          :value="button.currentYear"
          :evolution-object="{
            comparatorValue: button.difference,
            isPercent: false,
          }"
          :selected="isSelected(secteurCode)"
          :larger-label="true"
        />
      </div>
      <div class="flex w-full justify-center items-center text-darkGrey text-xs uppercase">
        <span class="h-px bg-greyBorder w-4" />
        <span
            class="whitespace-nowrap mx-2 pointer hover:text-greyDarkness"
            @click="() => {
              displayBoats = !displayBoats
              selectValue(filterData.secteurCode)
            }"
        >
          {{ displayBoats ? "Cacher" : "Afficher" }} les séries&nbsp;{{ displayBoats ? "\u25B2" : "\u25BC" }}
        </span>
        <span class="h-px bg-greyBorder w-4" />
      </div>
      <div
        v-if="displayBoats"
        class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2"
        :class="{
            '!grid-cols-1': getBoats().length === 1,
            'md:!grid-cols-2': getBoats().length === 2,
            'xl:!grid-cols-3': getBoats().length === 3,
            '2xl:!grid-cols-4': getBoats().length === 4,
          }"
      >
        <FilterButtonIndicator
            v-for="([codeBateau, data]) in getBoats()"
            :key="codeBateau"
            @click="selectValue(filterData.secteurCode, codeBateau)"
            :name="data.bateauLibelle"
            :value="data.currentYear"
            :evolution-object="{
              comparatorValue: data.difference,
              isPercent: false,
            }"
            :selected="isSelected(filterData.secteurCode, codeBateau)"
            :is-sub-indicator="true"
        />
      </div>
    </div>
  </BaseWidget>
</template>
  

<script setup>
import useDiscAndSuppFilter from '@/composables/filters/useDiscAndSuppFilter'
import FilterButtonIndicator from "@/components/common/FilterButtonIndicator.vue";
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import {toRef} from "vue";

const {
  filterData,
  getSortedFilterData,
  isSelected,
  selectValue,
  resetSelect,
} = useDiscAndSuppFilter('StructureButtonFilter')

const displayBoats = toRef(false);
const getBoats = () => {
  const boats = Object.entries(
      filterData.values
  ).filter(([secteurCode,]) => filterData.secteurCode ? secteurCode === filterData.secteurCode : true
  ).map(([, details]) => details.boats
  ).reduce((acc, val) => ({...acc, ...val}), {})
  return Object.entries(boats).sort((b1, b2) => b2[1].currentYear - b1[1].currentYear)
}

</script>