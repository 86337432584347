<script setup>

import {computed, toRefs} from "vue";
  import CheckboxComponent from "@/components/common/CheckboxComponent.vue";
  import ResetFilterButton from "@/components/common/ResetFilterButton.vue";
  import FilterComponent from "@/components/common/FilterComponent.vue";


  const props = defineProps({
      name: String, // MANDATORY
      elements: Array, // MANDATORY - T[][] where T has (at least) label and isSelected as keys
      selectElement: Function, // MANDATORY - (elt: T) => void
      confirmSelection: Function, // () => void
      elementsCategories: Array, // string[]
      reset: Function, // () => void
      singleChoice: [Boolean, Array], // Boolean or Boolean[] if elements is T[][]
      valueToDisplay: String,
      withBorder: Boolean,
  })
  const refProps = toRefs(props)
  const hasCategories = computed(() =>
      !!refProps.elements.value && Array.isArray(refProps.elements.value[0])
  )

</script>

<template>
  <FilterComponent
      :name="name"
      :display-value="!!valueToDisplay"
      :on-filter-close="confirmSelection"
      :with-border="withBorder"
  >
    <template #value>
      <span v-if="!!valueToDisplay">{{valueToDisplay}}</span>
    </template>
    <template v-slot="{closeFilter}">
      <div>
        <div v-if="elements.length > 0" class="flex flex-col max-h-96 overflow-y-scroll">
          <div v-if="!hasCategories">
            <CheckboxComponent
                v-for="[index, elt] in elements.entries()"
                :key="index"
                :label="elt.label"
                :is-checked="elt.isSelected"
                @click="() => {
                  selectElement(elt)
                  if (singleChoice) {
                    closeFilter()
                  }
                }"
                :rounded="singleChoice"
            />
          </div>
          <div v-else>
            <div
                v-for="[index, categoryElts] in elements.entries()"
                :key="index"
                :class="{'border-t border-t-greyBorder mt-2 pt-2': index > 0 }"
            >
              <div
                  v-if="elementsCategories && elementsCategories.length === elements.length"
                  class="text-dark font-bold px-2 mb-1"
              >
                {{elementsCategories[index]}}
              </div>
              <CheckboxComponent
                  v-for="[subIndex, elt] in categoryElts.entries()"
                  :key="subIndex"
                  :label="elt.label"
                  :is-checked="elt.isSelected"
                  @click="() => {
                    selectElement(elt)
                    if (singleChoice[index]) {
                      closeFilter()
                    }
                  }"
                  :rounded="singleChoice[index]"
              />
            </div>
          </div>
        </div>
        <div v-if="!!reset">
          <reset-filter-button :on-button-click="reset" class="w-full" />
        </div>
      </div>
    </template>
  </FilterComponent>
</template>

<style scoped>

</style>