import { ref } from "vue"
import Api from '@/composables/useApi'
import {searchStructure} from "@/composables/widgets/_widgetUtils";

/**
 * STATE
 */
const allClubs = ref([])
const allPPFStructures = ref([])
const selectedClub = ref(null)
const selectedPPFStructure = ref(null)

/**
 * METHODS
 */
const useClubFilter = () => {

  async function fetchStructures(ppf = false) {
    const { data } = await Api.get('/indicateurs/global/filtres/structures', { params: { ppf } })
    if (ppf) allPPFStructures.value = data
    else allClubs.value = data
  }

  const searchFor = (string, ppf = false) => {
    const allStructures = ppf ? allPPFStructures.value : allClubs.value
    return searchStructure(string, allStructures)
  }

  async function select(stCode) {
    if (!allClubs.value.length) await fetchStructures()
    if (!allPPFStructures.value.length) await fetchStructures(true)

    selectedClub.value = allClubs.value.find((club) => club.code === stCode)
    selectedPPFStructure.value = allPPFStructures.value.find((structure) => structure.code === stCode)
  }

  function reset() {
    selectedClub.value = null
    selectedPPFStructure.value = null
  }

  return {
    allClubs,
    allPPFStructures,
    selectedClub,
    selectedPPFStructure,
    fetchStructures,
    searchFor,
    select,
    reset
  }
}

export default useClubFilter