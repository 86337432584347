<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{
          title: handiFilter.isOn.value ? 'Licenciés régatiers en situation de handicap' : 'Licenciés régatiers',
          isDeclarative: handiFilter.isOn.value,
          declarativeText: 'Chaque licencié est libre de déclarer son handicap ou non.',
        }"
  />
</template>


<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter, ageFilterPS, pratiqueFilter, genreFilterPS, handiFilter  } from '@/composables/useFilters'
import {API_PARAMS, percentComparator, rawComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";
import {computed} from "vue";


const widget = useWidget('PSNombreLicenciesClassesWidget', {
  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    ageFilterPS.selectedMinAge,
    ageFilterPS.selectedMaxAge,
    pratiqueFilter.selectedPratique,
    genreFilterPS.selectedGenre,
    handiFilter.isOn,
  ],

  query: [
    {
      name: "regatiers",
      url: 'indicateurs/ps/regatiers',
      params: [
          API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
          API_PARAMS.AGE_PS, API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS,
          API_PARAMS.HANDI, API_PARAMS.A_DATE,
      ]
    },
    {
      name: "regatiersHandi",
      url: 'indicateurs/ps/regatiers',
      params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.AGE_PS, API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS,
        API_PARAMS.A_DATE,
      ],
      otherParams: {
        handi: { handi: true }
      },
      requestCondition: computed(() => handiFilter.isOn.value === false),
    },
  ],

  response: (response) => {
    const { responses: regatiersResponse } = response.find(r => r.name === 'regatiers')

    const [comparisonYear, selectedYear] = regatiersResponse
    const sumForCurrentYear = selectedYear.reduce((partialSum, a) => partialSum + a.nbRegatiers, 0);
    const sumForComparisonYear = comparisonYear.reduce((partialSum, a) => partialSum + a.nbRegatiers, 0);

    const sumForCurrentYearLicencies = selectedYear.reduce((partialSum, a) => partialSum + a.nbLicencies, 0);
    const sumForComparisonYearLicencies = comparisonYear.reduce((partialSum, a) => partialSum + a.nbLicencies, 0);
    const percentLicencies = Math.round((sumForCurrentYear / sumForCurrentYearLicencies) * 100)
    const percentLicenciesComp = Math.round((sumForComparisonYear / sumForComparisonYearLicencies) * 100)

    const additionalData = [
      {
        value: percentLicencies.toString() + '%',
        text: handiFilter.isOn.value ? "des licenciés en situation de handicap" : "des licenciés",
        relatif: "soit",
        comparator: {
          ...rawComparator(
              percentLicencies,
              percentLicenciesComp,
          ),
          comparison: (yearFilter.singleYearFilter.value - 1).toString()
        }
      },
    ]

    if (handiFilter.isOn.value === false) {
      const { responses: regatiersHandiResponse } = response.find(r => r.name === 'regatiersHandi')

      const [comparisonYearHandi, selectedYearHandi] = regatiersHandiResponse
      const sumForCurrentYearHandi = selectedYearHandi.reduce((partialSum, a) => partialSum + a.nbRegatiers, 0);
      const sumForComparisonYearHandi = comparisonYearHandi.reduce((partialSum, a) => partialSum + a.nbRegatiers, 0);

      additionalData.push({
        value: sumForCurrentYearHandi,
        text: "régatiers en situation de handicap",
        relatif: "dont",
        comparator: percentComparator(
            sumForCurrentYearHandi,
            sumForComparisonYearHandi,
            false,
            (yearFilter.singleYearFilter.value - 1).toString()
        ),
        isDeclarative: true,
        declarativeText: 'Chaque licencié est libre de déclarer son handicap ou non.',
      })
    }


    return {
      value: sumForCurrentYear,
      comparator: percentComparator(
          sumForCurrentYear,
          sumForComparisonYear
      ),
      additionalData,
    }
  }

})
</script>
