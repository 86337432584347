<template>
  <DonutWidget :composable="widget" />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {
  clubFilter,
  regionFilter,
  yearFilter,
  ageFilterPS,
  pratiqueFilter,
  genreFilterPS,
  handiFilter
} from '@/composables/useFilters'
import {API_PARAMS, buildDataset, processGender} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";


const widget = useWidget('PSRepartitionClassesGenreWidget', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    ageFilterPS.selectedMinAge,
    ageFilterPS.selectedMaxAge,
    pratiqueFilter.selectedPratique,
    genreFilterPS.selectedGenre,
    handiFilter.isOn,
  ],

  query: {
    url: 'indicateurs/ps/regatiers',
    params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.AGE_PS, API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS,
        API_PARAMS.HANDI, API_PARAMS.A_DATE,
    ]
  },

  response: ([comparisonYear, selectedYear]) => {
    const legend = buildDataset(
        selectedYear.map(data => ({ sexe: processGender(data.sexe), nbClasses: data.nbRegatiers })),
        comparisonYear.map(data => ({ sexe: processGender(data.sexe), nbClasses: data.nbRegatiers })),
        'sexe',
        'nbClasses',
        true,
        false,
        'sexe',
    )

    return {
      graphData: legend.map(l => ({...l, label: l.legend})),
    }
  }
})

</script>
