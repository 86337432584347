import Api from '@/composables/useApi'
import {ref, watch, onMounted, onUnmounted} from 'vue'
import {
  ageFilterPS,
  regionFilter,
  yearFilter,
  clubFilter,
  licenceFilter,
  genreFilterPS,
  pratiqueFilter,
  ageFilterDev, genreFilterDev, handiFilter, qualifArbitrageFilter, niveauArbitrageFilter
} from './useFilters'
import {API_PARAMS, getCurrentYear} from './widgets/_widgetUtils'

// @TODO FILTRE : rajouter l3 + dans function l172 pratiqueFilter


const useWidget = (name, { watchers, query, response, responseMulti = false, verbose = false }) => {

  let queries = []
  const abortController = ref()

  if (query.length) {
    queries = queries.concat(query)
  } else {
    queries.push(query)
  }

  /**
   * Pour le LoadingSpinner & éviter que le chart soit rendu avant que les données arrivent
   */
  const loaded = ref(false)

  /**
   * Watchers : surveiller le changement des valeurs des filtres sélectionnés
   */
  const watcherList = ref([])

  /**
   * Données passées au composant Chart
   */
  const data = ref(null)

  /**
   * Widget error
   */
  const error = ref(null)

  // ----------------------------------------------------------------------------

  /**
   * On rend les widget réactifs au filtres
   * Le composable est lié à un composant Widget, lui même lié à la page :
   * il faut enlever les watchers lorsque le composant Widget est unmounted,
   * cad au changement de page, sinon le code tourne quand même en arrière plan
   * même si les widgets ne sont plus sur la page consultée = 1 million de requêtes envoyées
   */
  onMounted(async () => {
    useWidgetLog('mounted', 'Composable activé')

    await refreshWidgetData()
    
    registerWatchers()
  })
  
  onUnmounted(() => {
    useWidgetLog('unMount', 'Composable désactivé')

    unregisterWatchers()
  })

  function registerWatchers() {
    watchers.forEach((target, oldTarget) => {
      const watcher = watch(target, (target) => {
        if(target !== oldTarget) {
          useWidgetLog('watch', `la valeur ${target} a changé : rafraîchissement des données`)
          refreshWidgetData()
        }
      }, { deep: true })
      watcherList.value.push(watcher)
    })
  }

  function unregisterWatchers() {
    watcherList.value.forEach((unwatch) => unwatch())
  }

  // ----------------------------------------------------------------------------

  /**
   * Gère l'envoi des requêtes et formate les réponses de l'API
   * pour qu'elles soient toujours du même format pour la méthode handleResponse.
   * Si la méthode responseMulti() est définie dans le compsant widget associé,
   * celui-ci sera réactifs aux changement de date lorsque les dates custom ou olympiades changent
   */
    async function refreshWidgetData() {

    // Ne pas envoyer de requête lorsque plusieurs années sont sélectionnées
    // et que le widget n'a pas la méthode responseMulti()
    if(yearFilter.isPeriodSelected.value && !responseMulti)
    return loaded.value = true

    loaded.value = false

    try {
      useWidgetLog("refreshWidgetData", "Nouvelle requête pour mettre à jour les données à l'url :", query.url)
      if (abortController.value) {
        await abortController.value.abort()
      }

      const queries = handleQueries()

      let normalized = []
      let allPromisesOk = true;
      if (arrayContainsOnlyPromises(queries)) {
        await Promise.all(queries).then(
            (responses) => {
              normalized = responses.map(
                  (res) => !!res && res.data ? res.data : res
              )
            }).catch(err => {
              manageError(err)
              allPromisesOk = false;
        })
      }
      else {
        for (let i = 0; i < queries.length; i++){
          let element = queries[i]
          await Promise.all(element.requests).then(
              (outRequests) => normalized.push({ name: element.name, responses : outRequests.map((res) => !!res && res.data ? res.data : res) })
          ).catch(err => {
            manageError(err)
            allPromisesOk = false;
          })
        }
      }
      if (allPromisesOk === true) {
        data.value = handleResponse(normalized)
        loaded.value = true
        error.value = null
      }
    }
    catch (err) {
      manageError(err)
    }
  }

  const manageError = (err) => {
    if (err.message.includes('canceled')) {
      useWidgetLog("refreshWidgetData", err)
    } else {
      useWidgetError("refreshWidgetData", err)
    }
  }

  // Check if it's an array of Promises
  function arrayContainsOnlyPromises(array) {
    return array.every(item => item instanceof Promise);
  }
  // ---------

  /**
   * Permet de préparer les requêtes automatiquement en fonction des données stockées dans les filtres
   * Si les paramètres à envoyer à l'API sont à modifier, c'est ici qu'il faut le faire
   * Il y a autant de requêtes que d'années sélectionnées. (+ année de comparaison si année unique)
   * Une fois les paramètres créés, la requête est envoyée par refreshWidgetData()
   * Puis la réponse est transformée, dans un premier temps, par handleResponse()
   * et enfin (et surtout) par la méthode response() ou responseMulti() dans le composant widget associé
   */
  const handleQueries = () => {
    abortController.value = new AbortController()
    useWidgetLog('handleQueries', "paramètres enregistrés pour cette requête", query)
    let results = []
    queries.forEach(
      (query) => {
        if (query.requestCondition !== undefined && !query.requestCondition) return;
        // parameters safety check
        query.params.forEach(param => {
          if (!Array.from(Object.values(API_PARAMS)).includes(param))
            useWidgetError('handleQueries', `"${param}" à été enregistré comme paramètre à envoyer dans la requête de ce widget, mais il n'est pas configuré dans useWidget()`)
        })

        try {
          /**
           * Prépare les années requises on fonction des valeurs du filtre yearFilter
           */

          let preRequests = yearFilter.apiParameterYears.value.map(year => {
            if (query.params.includes(API_PARAMS.ARRAY_YEARS))
              return ({ annees: { annees: [year] } })
            else if (query.params.includes(API_PARAMS.YEARS) || query.params.includes(API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT))
              if (query.params.includes(API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT) && yearFilter.singleYearFilter.value === getCurrentYear())
                return ({ annees: { anneeDebut: year - 1, anneeFin: year - 1 } })
              return ({ annees: { anneeDebut: year, anneeFin: year } })
          })

          /**
           * Ajoute les paramètres de manière dynamique
           */
          const parameters = []

          query.params.forEach((param) => {
            // -> region param (RegionFilter)
            if (param === API_PARAMS.LIGUE && regionFilter.selectedLigue.value)
              parameters.ligue = { ligue: regionFilter.selectedLigue.value.id.toString() }
          
            // -> club param (ClubFilter)
            if (param === API_PARAMS.STRUCTURE && clubFilter.selectedClub.value) {
              parameters.structures = { structureCodes: [clubFilter.selectedClub.value.code] }
            }
          
            // -> licence param (RepartitionLicences.vue) "club" | "passport" | "passffv"
            if (param === API_PARAMS.LICENCE) {
              parameters.licenceTypes = { licenceTypes: [licenceFilter.selectedLicence.value.apiId] }
            }
        

            // -> sexe param (GenreFilter)
            if (param === API_PARAMS.GENRE_PS) {
              if (genreFilterPS.selectedGenre.value.length === 1) {
                parameters.sexe = { sexe: genreFilterPS.selectedGenre.value.at(0).param }
              }
            }
            if (param === API_PARAMS.GENRE_DEV) {
              if (genreFilterDev.selectedGenre.value.length === 1) {
                parameters.sexe = { sexe: genreFilterDev.selectedGenre.value.at(0).param }
              }
            }

            // -> pratique param (pratiqueFilter)
            if (param === API_PARAMS.PRATIQUE && pratiqueFilter.selectedPratique.value.length > 0) {
              if (pratiqueFilter.selectedPratique.value.at(0).param !== "-") {
                parameters.discipline = { discipline: [pratiqueFilter.selectedPratique.value.map((p) => p.param).join(',')] }
              }
            }

            // -> age param (AgeFilter)
            if (param === API_PARAMS.AGE_PS) {
              if (!!ageFilterPS.selectedMinAge.value || !!ageFilterPS.selectedMaxAge.value) {
                parameters.ages = {
                  ageMin: ageFilterPS.selectedMinAge.value,
                  ageMax: ageFilterPS.selectedMaxAge.value,
                }
              }
            }
            if (param === API_PARAMS.AGE_DEV) {
              if (!!ageFilterDev.selectedMinAge.value || !!ageFilterDev.selectedMaxAge.value) {
                parameters.ages = {
                  ageMin: ageFilterDev.selectedMinAge.value,
                  ageMax: ageFilterDev.selectedMaxAge.value,
                }
              }
            }
            if (param === API_PARAMS.A_DATE) {
              parameters.aDate = {
                aDate: yearFilter.singleYearFilter.value === getCurrentYear(),
              }
            }
            if (param === API_PARAMS.HANDI) {
              parameters.handi = {
                handi: handiFilter.isOn.value,
              }
            }
            if (param === API_PARAMS.ARBITRAGE_QUALIF) {
              if (qualifArbitrageFilter.selectedQualif.value[0].tafId !== null) {
                parameters.typesQualifs = qualifArbitrageFilter.selectedQualif.value.map(
                    (q) => q.tafId
                )
              }
            }
            if (param === API_PARAMS.ARBITRAGE_NIVEAU) {
              if (niveauArbitrageFilter.selectedNiveau.value[0].codeNiveau !== null) {
                parameters.niveauQualif = niveauArbitrageFilter.selectedNiveau.value.map(
                    (n) => n.codeNiveau
                )
              }
            }
          })

          // Ajout des autres paramètres
          query.otherParams && Object.entries(query.otherParams).forEach((p)=> {
            parameters[p[0]] = p[1]
          })

          useWidgetLog('handleQueries', "Paramètres envoyés :", parameters)      
          // transform params into requests
          const signal = abortController.value.signal
          const requests = preRequests.map((years) => Api.post(
              query.url,
              { ...years, ...parameters },
              { signal }
          ))
          if (query.name) results.push({ name: query.name, requests })
          else results.push(requests)
        }
        catch(err) {
          useWidgetError("handleQueries", err)
        }
      })
    if (queries.length === 1 && results.length > 0) return results[0]
    return results
  }

  // ---------

  /**
   * Permet de gérer les données à envoyer aux graphs de ChartJS directement dans le composant Widget
   * Utile car l'API ne renvoie jamais le même type de données, cette methode permet de les
   * transformer dans un format unique attendu par les composants de type Charts
   * -> Structure de données attendues en fonction du type de graph : voir doc (@/src/documentation/widgets.md)
   */
    function handleResponse(apiResponses) {
    try {
      useWidgetLog('handleResponse', "Réponses de l'API :", apiResponses)

      // Si l'API ne renvoie aucune données pour ces années
      //   -> widget.data = null
      //   -> Affichage dans le widget : "Pas de données disponibles"
      if(!apiResponses || !apiResponses.some(response => response)) {
        useWidgetWarning('handleResponse', "Aucunes données disponibles. Réponses de l'API :", apiResponses)
        return null
      }

      // Sinon, transforme les données avec la méthode dans le composant Widget associé
      if(yearFilter.isSingleYearSelected.value)
      return response(apiResponses)
      
      else if(yearFilter.isPeriodSelected.value && responseMulti)
      return responseMulti(apiResponses)

      else
      useWidgetError("handleResponse", "Il manque probablement la méthode responseMulti sur ce widget")
    }
    catch(err) {
      useWidgetError("handleResponse", err)
    }
  }

  // ----------------------------------------------------------------------------

  /**
   * Utils
   */
  
  function useWidgetLog(methodName, msg = "", data = null) {
    if(verbose)
    console.info(`useWidget(${name}) - ${methodName}()\n`, msg, '\n', data)
  }

  function useWidgetWarning(methodName, msg = "", data = null) {
    if(verbose)
    console.warn(`useWidget(${name}) - ${methodName}()\n`, msg, '\n', data)
  }

  function useWidgetError(methodName, err) {
    loaded.value = false
    error.value = err
    console.error(`useWidget(${name}) - ${methodName}()\n`, err)
  }

  return {
    loaded,
    data,
    error
  }
}

export default useWidget