<template>
  <div class="relative" id="app--content">
    <router-view />
  </div>
</template>

<script>
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

export default {
  name: 'App',
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    enableDatadog(version) {
      datadogRum.init({
        applicationId: 'a274f4d9-2a3b-4980-a570-af8a0366cace',
        clientToken: 'pub5464be999da42121dcbb8d2f21ca9392',
        site: 'datadoghq.eu',
        service: 'ffvoile-stats',
        env: window.VUE_DDOG_ENV,
        version: version,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        allowedTracingOrigins: ["https://ffvoile-stats.webmakers.ovh/"]
      });

      datadogLogs.setLoggerGlobalContext({ env: window.VUE_DDOG_ENV })
      datadogRum.startSessionReplayRecording();
    }
  },

  async beforeMount() {
    var pjson = require('../package.json');
    if (window.VUE_ENABLE_DDOG == "true") this.enableDatadog(pjson.version)
  },

};
</script>

<style lang="scss">
  @import "@/assets/sass/_fonts.scss";
  @import "@/assets/sass/_normalize.scss";
  @import "@/assets/sass/_mediaqueries.scss";
  @import "@/assets/sass/_common-styles.scss";
  @import "@/assets/sass/_materials.scss";
  @import "@/assets/sass/_new-layout.scss";
  @import "@/assets/sass/_header.scss";
  @import "@/assets/sass/_form.scss";
  @import "@/assets/sass/_pages.scss";
  @import "@/assets/sass/_print.scss";
</style> 