<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{ title: 'Nombre d’épreuves organisées' }"
  />
</template>


<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import { API_PARAMS, percentComparator } from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('PSNombreLicenciesClassesWidget', {
  // à définir selon la requête :
  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
  ],

  query: {
    url: 'indicateurs/ps/epreuves/organisees',
    params: [API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE, API_PARAMS.A_DATE],
  },

  response: ([comparisonYear, selectedYear]) => {

    const sumForEpreuveOrga = selectedYear.reduce((partialSum, a) => partialSum + a.nombreEpreuves, 0);

    const sumForEpreuveOrgaComp = comparisonYear.reduce((partialSum, a) => partialSum + a.nombreEpreuves, 0);

    const sumForBoat = selectedYear.reduce((partialSum, a) => partialSum + a.bateauFourni, 0);
    const sumForBoatComparison = comparisonYear.reduce((partialSum, a) => partialSum + a.bateauFourni, 0);

    const value = sumForEpreuveOrga

    const comparator = percentComparator(
      sumForEpreuveOrga,
      sumForEpreuveOrgaComp
    )

    const additionalData = [
      {
        value: selectedYear ? sumForBoat : 0,
        text: "AVEC BATEAU FOURNIS",
        relatif: "dont",
        comparator: percentComparator(
            selectedYear ? sumForBoat : 0,
            comparisonYear ? sumForBoatComparison : 0
        )
      }
    ]
    // @chloé : additionalData : Bateau fourni

    /*const additionalData = [
      {
        value: selectedYear ? selectedYear.nbScolaires : 0,
        text: "scolaires",
        // si pas soit alors dont:
 
           relatif: "dont",
 
           
        comparator: percentComparator(
          selectedYear ? selectedYear.nbScolaires : 0,
          comparisonYear ? comparisonYear.nbScolaires : 0
        )
      },
      {
        value: selectedYear ? selectedYear.nbHdPersonnes : 0,
        text: "en situation de handicap",
        comparator : percentComparator(
          selectedYear ? selectedYear.nbHdPersonnes : 0,
          comparisonYear ? comparisonYear.nbHdPersonnes : 0
        )
      },
      {
        value: selectedYear ? selectedYear.nombrePublieDiffNB : 0,
        text: "personnes en difficulté",
        comparator: percentComparator(
          selectedYear ? selectedYear.nombrePublieDiffNB : 0,
          comparisonYear ? comparisonYear.nombrePublieDiffNB : 0
        )
      }
    ]*/

    return { value, comparator, additionalData }
  },

})
</script>
