<template>
  <BaseWidget
      :front-error="hasError"
      :api-error="!hasApiData"
      :is-loading="!widget.loaded.value"
  >
      <PSFidelisationWidget :composable="{ ...widget, data: toRef(widget.data.value.fidelisation) }" :transparent="true" />
      <PSNouveauxClassesWidget :composable="{ ...widget, data: toRef(widget.data.value.nvxRegatiers) }" :transparent="true" />
  </BaseWidget>
</template>


<script setup>
import PSFidelisationWidget
  from "@/components/pages/pratiques-sportives/widgets/classes-widget/PSFidelisationWidget.vue";
import PSNouveauxClassesWidget
  from "@/components/pages/pratiques-sportives/widgets/classes-widget/PSNouveauxClassesWidget.vue";
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";

import useWidget from '@/composables/useWidget'
import {
  clubFilter,
  regionFilter,
  yearFilter,
  ageFilterPS,
  pratiqueFilter,
  genreFilterPS,
  handiFilter
} from '@/composables/useFilters'
import {API_PARAMS, rawComparator} from '@/composables/widgets/_widgetUtils'
import {computed, toRef} from "vue";

const widget = useWidget("PSFidelisationtWidget", {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    ageFilterPS.selectedMinAge,
    ageFilterPS.selectedMaxAge,
    pratiqueFilter.selectedPratique,
    genreFilterPS.selectedGenre,
    handiFilter.isOn,
  ],

  query: {
    url: 'indicateurs/ps/regatiers/fidelisation',
    params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.AGE_PS, API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS,
        API_PARAMS.HANDI, API_PARAMS.A_DATE
    ]
  },
  response: ([comparisonYear, selectedYear]) => {
    comparisonYear = comparisonYear[0]
    selectedYear = selectedYear[0]

    const nvxRegatiersPct = Math.round(selectedYear.nvxRegatiers * 100 / selectedYear.regatiersAnnee)
    const fidelisationPct = Math.round(selectedYear.regatiersRenouveles * 100 / selectedYear.regatiersAnneePrec)

    const compNvxRegatiersPct = Math.round(comparisonYear.nvxRegatiers * 100 / comparisonYear.regatiersAnnee)
    const compFidelisationPct = Math.round(comparisonYear.regatiersRenouveles * 100 / comparisonYear.regatiersAnneePrec)

    return ({
      nvxRegatiers: {
        value: nvxRegatiersPct,
        comparator: rawComparator(nvxRegatiersPct, compNvxRegatiersPct)
      },
      fidelisation: {
        value: fidelisationPct,
        comparator: rawComparator(fidelisationPct, compFidelisationPct)
      },
    })
  }
})

const hasError = computed(() => widget.error.value !== null)
const hasApiData = computed(() =>  widget.data.value !== null && widget.data.value !== undefined)
</script>
