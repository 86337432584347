<script setup>
import EvolutionTag from "@/components/common/EvolutionTag.vue";

const props = defineProps({
  label : String,
  value: Number,
  evolutionObject : Object, // { comparatorValue : Number, isPercent : Boolean, comparison : Number },
  largerLabel: Boolean,
})
</script>

<template>
  <div class="flex flex-col items-center justify-end gap-y-2">
    <span class="text-sm w-20 text-center px-1" :class="{'!w-28': largerLabel}">{{label}}</span>
    <div class="flex flex-col items-center justify-center shadow-inner bg-lightGrey rounded-lg h-20 w-20">
      <span class="font-inter-bold text-2xl">{{(value !== undefined && value !== null) ? value : '-'}}</span>
      <EvolutionTag
          v-if="!!evolutionObject"
          :is-subtag="true"
          :comparator-value="props.evolutionObject.comparatorValue"
          :is-percent="props.evolutionObject.isPercent"
          :comparison="props.evolutionObject.comparison"
      />
    </div>
  </div>
</template>
