import { ref } from "vue"

const LICENCES = [
  { title: "Passeports", label: "Passeport", apiId: "PASSEPORT" },
  { title: "Licences Club (hors primo)", label: "Licence Club", apiId: "CLUB" },
  { title: "Pass FFVoile", label: "Pass FFVoile", apiId: "PASSFFV" },
]

const selectedLicence = ref(LICENCES.at(0))

const useLicenceFilter = () => {

  const isLicenceSelected = (licence) => {
    return licence.apiId === selectedLicence.value.apiId
  }

  function select(licence) {
    selectedLicence.value = licence
  }

  function reset() {
    selectedLicence.value = LICENCES.at(0)
  }

  return {
    LICENCES,
    isLicenceSelected,
    selectedLicence,
    select,
    reset,
  }
}

export default useLicenceFilter