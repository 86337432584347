import useYearFilter from "./filters/useYearFilter"
import useRegionFilter from "./filters/useRegionFilter"
import useAgeFilter from "./filters/useAgeFilter"
import useClubFilter from "./filters/useClubFilter"
import useLicenceFilter from "./filters/useLicenceFilter"
import useGenreFilter from "./filters/useGenreFilter"
import useResultatFilter from "./filters/useResultatFilter"
import usePratiqueFilter from "./filters/usePratiqueFilter"
import {useHandiFilter} from "@/composables/filters/useHandiFilter";
import useQualifsArbitrageFilter from "@/composables/filters/useQualifsArbitrageFilter";
import useNiveauxArbitrageFilter from "@/composables/filters/useNiveauxArbitrageFilter";

export const yearFilter = useYearFilter()
export const regionFilter = useRegionFilter()
export const clubFilter = useClubFilter()
export const handiFilter = useHandiFilter()

// Developpement
export const licenceFilter = useLicenceFilter()
export const genreFilterDev = useGenreFilter()
export const ageFilterDev = useAgeFilter()

// Pratiques sportives
export const pratiqueFilter = usePratiqueFilter()
export const genreFilterPS = useGenreFilter()
export const ageFilterPS = useAgeFilter()
export const qualifArbitrageFilter = useQualifsArbitrageFilter()
export const niveauArbitrageFilter = useNiveauxArbitrageFilter()
// Résultats
export const resultatFilter = useResultatFilter()
