<template>
  <Doughnut
    id="my-donut-id"
    :data="handleData(chartData)"
    :options="donutChartOptions"
    height="225"
    width="225"
  />
</template>

<script setup>

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Doughnut } from 'vue-chartjs'
import {formatNumber, PALETTE} from '@/composables/widgets/_widgetUtils'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const props = defineProps({
  chartData: Object,
  withTooltip: Boolean,
})

function handleData(composableData) {

  const total = composableData ? composableData.reduce((a, b) => a + b.value, 0) : null
  // Si pas de données : afficher un donut vide (en gris clair)
  if(!composableData
      || composableData.filter(v => !!v).length === 0
      || !total
  )
    return ({
      datasets: [{
        data: [],
        backgroundColor: PALETTE.EMPTY
      }]
    })

  const customColors = composableData.map((data) => data.color).filter((c) => !!c)

  return ({
    datasets: [{
      data: composableData.map((data) => data.value),
      percentages: composableData.map((data) => (data.value * 100 / total).toFixed()),
      backgroundColor: customColors.length === composableData.length ? customColors : PALETTE.CHART,
      labels: composableData.map((data) => data.label)
    }]
  })
}

const donutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: { padding: 28},
  borderRadius: 4,
  plugins: {
    // affichage des pourcentages autour du donut chart
    datalabels: {
      display: true,
      anchor: 'center',
      align: 'end',
      offset: 24,
      padding: 0,
      borderRadius: 3,
      font: { size: '14px', weight: 'bold' },
      color: (context) => context.dataset.backgroundColor[context.dataIndex],
      formatter: (value, context) => {
        const output = context.dataset.percentages[context.dataIndex]
        const nbLowPercentages = context.dataset.percentages.filter(p => p < 5).length
        if((output > 0 && nbLowPercentages === 1) || output >= 5) return  output + '%'
        return ""
      }
    },

    // affichage d'infos au hover sur le chart
    tooltip: {
      enabled: props.withTooltip || false,
      callbacks: {
        label: (context) => {
          return context.dataset.labels[context.dataIndex]
              + ' - ' + formatNumber(context.dataset.data[context.dataIndex])
              + ' (' + context.dataset.percentages[context.dataIndex] + '%)'
        }
      },
      boxPadding: 4,
    }
  }
}
</script>