import { ref } from "vue"

const useGenreFilter = () => {

  const GENRES = [
    { label: "Homme", param: "M" },
    { label: "Femme", param: "F" },
  ]

  const selectedGenre = ref(GENRES)

  const isGenreSelected = (genre) => {
    return selectedGenre.value.findIndex((g) => g.param === genre.param) > -1
  }

  const isAllSelected = () => selectedGenre.value.length === 2

  const select = (genre) => {
    if (!isGenreSelected(genre) || selectedGenre.value.length === 1) {
      selectedGenre.value = GENRES
    } else {
      selectedGenre.value = selectedGenre.value.filter((g) => g.param !== genre.param)
   }
  }

  function reset() {
    selectedGenre.value = GENRES
  }

  return {
    GENRES,
    selectedGenre,
    isGenreSelected,
    isAllSelected,
    select,
    reset,
  }
}

export default useGenreFilter