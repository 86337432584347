<template>
  <DonutWidget
      :title-object="{
          title: 'Équivalents temps plein',
          isDeclarative: true,
        }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value - 1
          : undefined
      "
      :composable="widget"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {clubFilter, regionFilter, yearFilter} from '@/composables/useFilters'
import {API_PARAMS, buildDataset, getCurrentYear, percentComparator} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";

function processLabel(data) {
  if(data === "F") return "Femme"
  if(data === "M") return "Homme"
  else return "Non renseigné"
}

const widget = useWidget('FullTimeWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
  ],

  query: {
    url: 'indicateurs/dev/rh/etp',
    params: [ API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE ]
  },

  response: ([comparisonYear, selectedYear]) => {

    const comparisonTotal = comparisonYear && comparisonYear.length > 0
      ? Math.round(comparisonYear.reduce((a, b) => a + b.equivalentsTempsPleins, 0))
      : undefined
    const selectedTotal = selectedYear && selectedYear.length > 0
      ? Math.round(selectedYear.reduce((a, b) => a + b.equivalentsTempsPleins, 0))
      : undefined
    const total = {
      value: selectedTotal,
      comparator: percentComparator(
          selectedTotal,
          comparisonTotal,
          false,
          (yearFilter.singleYearFilter.value === getCurrentYear())
              ? (yearFilter.singleYearFilter.value - 2).toString()
              : (yearFilter.singleYearFilter.value - 1).toString()
      )
    }

    const legend = buildDataset(
        selectedYear.map((e) => ({
          sexe: processLabel(e.sexe),
          etp: Math.round(e.equivalentsTempsPleins)
        })),
        comparisonYear.map((e) => ({
          sexe: processLabel(e.sexe),
          etp: Math.round(e.equivalentsTempsPleins)
        })),
        'sexe',
        'etp',
        true,
        false,
        'sexe',
        false,
        false,
        true,
        (yearFilter.singleYearFilter.value === getCurrentYear())
            ? (yearFilter.singleYearFilter.value - 2).toString()
            : (yearFilter.singleYearFilter.value - 1).toString()
    )

    return {
      total,
      graphData: legend.map(l => ({...l, label: l.legend})),
    }
  }
})
</script>