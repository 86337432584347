<template>
   <BaseChartWidget :composable="composable">
    <!-- GRAPH -->
    <HorizontalBarChart
        v-if="composableData.data && composableData.data.length > 0"
        :data="composableData.data"
        :chartOptions="composableData.options"
    />
    <slot />
  </BaseChartWidget>
</template>
  
<script setup>
import { toRefs, computed } from 'vue'
import BaseChartWidget from "@/components/widgets/templates/BaseChartWidget.vue";
import HorizontalBarChart from "@/components/widgets/charts/HorizontalBarChart.vue";

const props = defineProps({ composable: Object })
const { composable } = toRefs(props)


const composableData = computed(() => composable.value.data.value)

  </script>