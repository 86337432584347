import { ref } from "vue"
import Api from '@/composables/useApi'
const PRATIQUE = ref(null)
const selectedPratique = ref([])
const tmpSelectedPratique = ref([])
const usePratiqueFilter = () => {



  async function fetchPratiques() {
    const { data } = await Api.get('/indicateurs/ps/filtres/pratiques')
    PRATIQUE.value = Object.entries(data).map(([param, label]) => ({ label, param })).sort(
        (p1, p2) => p1.label < p2.label ? -1 : 1
    );

    const allIndex = PRATIQUE.value.findIndex((p) => isAll(p))
    const all = PRATIQUE.value[allIndex]
    PRATIQUE.value.splice(allIndex, 1)
    PRATIQUE.value = [all].concat(PRATIQUE.value)
    selectedPratique.value = [all]
    tmpSelectedPratique.value = [all]
  }

  const isPratiqueSelected = (pratique) =>  selectedPratique.value.map((p) => p.param).includes(pratique.param)
  const isPratiqueTmpSelected = (pratique) =>  tmpSelectedPratique.value.map((p) => p.param).includes(pratique.param)

  const isAll = (pratique) => pratique.param === '-'
  const isAllSelected = () => selectedPratique.value.length === 1 && selectedPratique.value.at(0).param === "-"
  const isAllTmpSelected = () => tmpSelectedPratique.value.length === 1 && tmpSelectedPratique.value.at(0).param === "-"

  function select(pratique) {
    if (isPratiqueTmpSelected(pratique)) {
      tmpSelectedPratique.value = tmpSelectedPratique.value.filter((p) => p.param !== pratique.param)
      if (tmpSelectedPratique.value.length === 0) {
        tmpSelectedPratique.value = [PRATIQUE.value.at(0)]
      }
    } else if (isAllTmpSelected() || isAll(pratique)) {
      tmpSelectedPratique.value = [pratique]
    } else {
      tmpSelectedPratique.value = tmpSelectedPratique.value.concat(pratique)
    }
  }

  const confirmSelection = () => selectedPratique.value = tmpSelectedPratique.value

  const reset = () => {
    if (PRATIQUE.value) {
      selectedPratique.value = [PRATIQUE.value.at(0)]
      tmpSelectedPratique.value = [PRATIQUE.value.at(0)]
    }

  }
  

  return {
    PRATIQUE,
    fetchPratiques,
    selectedPratique,
    isPratiqueSelected,
    isPratiqueTmpSelected,
    isAllSelected,
    select,
    confirmSelection,
    reset
  }
}

export default usePratiqueFilter