<template>
  <div class="bg-greyBtn flex justify-center">
    <div
        class="
          flex flex-col lg:flex-row justify-center
          p-1 pt-0 md:p-4 md:pt-0 xl:p-6 gap-x-4 w-full lg:max-w-screen-2xl
          font-inter-regular
        "
        :class="{ 'disparitionforPopup': filterItems }"
        @click="closeAllOpeningFilters()"
    >
      <!-- BUTTON TO SCROLL TO -->
      <ButtonComponent
          :on-button-click="scrollTop"
          class="
            fixed bottom-4 right-4 2xl:right-12 z-40
            flex justify-center items-center
            w-12 h-12
            text-dark font-bold text-2xl
            bg-white !rounded-full
      ">
        {{ "\u2191" }}
      </ButtonComponent>

      <!-- SIDE BAR -->
      <HeaderComponent
          :page-id="pageId"
          :openMenu="() => isMenuOpen= !isMenuOpen"
          :isMenuOpen="isMenuOpen"
          :aDate="aDate"
      />
      <!-- NO ACCESS POPUP -->
      <div
          v-if="authorization.displayNoAccessPopup.value"
          class="fixed w-full h-full z-40 flex justify-center items-center bg-dark bg-opacity-50 top-0"
          @click="() => authorization.displayNoAccessPopup.value = false"
      >
        <div
            class="relative bg-white shadow p-4 max-w-sm rounded-md text-sm flex gap-x-4"
            @click.stop
        >
          <span>Vous n'êtes pas autorisé à accéder aux pages des ligues et des structures</span>
          <span
              class="absolute top-1 right-2 cursor-pointer p-1"
              @click="() => authorization.displayNoAccessPopup.value = false"
          >X</span>
        </div>
      </div>
      <!-- MAIN -->
      <main class="w-full" :class="`${isMenuOpen && 'max-sm:hidden'}`">
        <div class="
          sticky top-0 p-2 flex flex-wrap justify-center md:justify-between items-center gap-x-4 gap-y-2 z-40
          bg-greyBtn/90 pl-14 xl:pl-1
        ">
          <div class="flex items-center gap-x-1 text-dark text-xl font-inter-bold xl:pl-1 max-xl:h-12">
            <span class="hidden xl:flex w-1 rounded-full h-4 bg-darkBlue"/>
            <span class="uppercase text-center">{{getViewName()}} - {{yearFilter.singleYearFilter.value}}</span>
            <router-link
                v-show="clubFilter.selectedClub.value || regionFilter.selectedLigue.value"
                :to="`/${pageId}`"
                class="cursor-pointer relative group flex items-center justify-center w-6 h-6 ml-1 pb-1 rounded-full text-white bg-darkBlue hover:bg-darkBlue/80"
            >
              {{ "\u00d7" }}
              <TooltipComponent
                  :is-on-bottom="true"
                  :is-on-right="true"
                  tooltip="Retour à la vue nationale"
              />
            </router-link>
          </div>
          <div class="hidden md:flex gap-x-4">
            <!-- HANDI FILTER-->
            <HandiFilter v-if="pageId === 'pratiques-sportives'" class="h-fit"/>
            <!-- DATE FILTER -->
            <DateFilter
                :class="`${isMenuOpen && 'max-sm:hidden'}`"
                align-content-box="right"
                :a-date="aDate"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y-4">
          <!-- PAGE CONTENT = VIEW -->
          <slot name="page"></slot>
        </div>
        <div class="w-full flex-inline text-center mt-4 text-dark italic text-sm">
          Pour toute remarque ou question, vous pouvez envoyer un mail à l'adresse
          <a class='text-darkBlue hover:opacity-80' href="mailto:informatique@ffvoile.fr">informatique@ffvoile.fr</a>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import HeaderComponent from "@/components/common/HeaderComponent.vue";
import DateFilter from "@/components/globalFilters/DateFilter.vue";
import ButtonComponent from "@/components/common/ButtonComponent.vue";
import HandiFilter from "@/components/globalFilters/HandiFilter.vue";
import {authorization} from "@/composables/useAuthorization";
import {clubFilter, regionFilter, yearFilter} from "@/composables/useFilters";
import TooltipComponent from "@/components/common/TooltipComponent.vue";

defineProps({
  pageId: String,
  aDate: Boolean,
  withHandiFilter: Boolean,
})

const filterItems = ref(false)
const isMenuOpen = ref(false)

function closeAllOpeningFilters() {
  filterItems.value = false
}

const scrollTop = () => {
  window.scrollTo({top: 0, behavior: 'smooth'})
}

const getViewName = () => {
  if (clubFilter.selectedClub.value) {
    return clubFilter.selectedClub.value.libelle
  }
  if (clubFilter.selectedPPFStructure.value) {
    return clubFilter.selectedPPFStructure.value.libelle
  }
  if (regionFilter.selectedLigue.value) {
    return 'Ligue ' + regionFilter.selectedLigue.value.name
  }
  return 'Vue nationale'
}
</script>