import { ref, onMounted, onUnmounted, watch, reactive } from 'vue'
import {yearFilter, regionFilter, clubFilter, handiFilter} from '../useFilters'
import Api from '@/composables/useApi'
import {getCurrentYear, percentComparator} from "@/composables/widgets/_widgetUtils";

const filterData = reactive({
  liste_eo:{
    rows: {
      first: []
    },
    selected: {
      first: null
    }
  }
})

const useButtonFilterPS = (name, { type }, mustFetch = false) => {

  const selectedYear = ref([])
  const comparisonYear = ref([])
  const watchers = ref([])

  onMounted(async () => {
    if (mustFetch) {
      await fetchButtons()
      watchers.value.push(
        watch(yearFilter.singleYearFilter, (val, oldVal) => { if (val !== oldVal) fetchButtons() }),
        watch(regionFilter.selectedLigue, (val, oldVal) => { if (val !== oldVal) fetchButtons() }),
        watch(clubFilter.selectedClub, (val, oldVal) => { if (val !== oldVal) fetchButtons() }),
        watch(handiFilter.isOn, (val, oldVal) => { if (val !== oldVal) fetchButtons() }),
      )
    }
  })

  onUnmounted(() => {
    watchers.value.forEach((unwatch) => unwatch())
  })

  async function fetchButtons() {
    try {
      const query = {
        type: {
          type: type
        },
        annees: {
          anneeDebut: yearFilter.singleYearFilter.value - 1,
          anneeFin: yearFilter.singleYearFilter.value
        },
        ligue: {
          ligue: regionFilter.selectedLigue.value
            ? regionFilter.selectedLigue.value.id
            : ""
        },
        discipline: {
          discipline: []
        },
        structures: {
          structureCodes: clubFilter.selectedClub.value
            ? [clubFilter.selectedClub.value.code]
            : []
        },
        aDate: {
          aDate: yearFilter.singleYearFilter.value === getCurrentYear(),
        },
        handi: {
          handi: handiFilter.isOn.value
        },
        
      }

      const res = await Api.post('/indicateurs/ps/epreuves/organisees', query)
      //console.log('button filter res', res) // Deux années 

      selectedYear.value = res.data.filter((el) => el.annee === yearFilter.singleYearFilter.value)
      comparisonYear.value = res.data.filter((el) => el.annee === yearFilter.singleYearFilter.value - 1)
      //console.log('button selectedyear value', selectedYear.value)

      createFirstRow()
      //createSecondRow()
      // console.log('btn results:', rawData.value)
    }
    catch (err) {
      console.error(name, err)
    }
  }

  function createFirstRow() {
    filterData[type].rows.first = selectedYear.value
      .reduce((acc, val) => {
        if (!acc.find((el) => el.id === val.secteurLibelle)) {
          const data = {
            id: val.secteurLibelle,
            name: val.secteurLibelle,
            secteurCode: val.secteurCode,
            value: selectedYear.value
              .filter((el) => el.secteurLibelle === val.secteurLibelle)
              .reduce((acc, val) => acc + val.nombreEpreuves, 0),
          }

          if(comparisonYear.value) {
            const compTotal = comparisonYear.value
              .filter((el) => val.secteurLibelle ? el.secteurLibelle === val.secteurLibelle : true)
              .reduce((acc, val) => acc + val.nombreEpreuves, 0)
            data.evolution = percentComparator(data.value, compTotal)
          }
          

          acc.push(data)
        }
        return acc
      }, [])
  }

  function isSelected(row, el) {
    if (row === "first")
      return filterData[type].selected[row] === el.secteurCode
  }

  /**
   * Sélection des boutons/filtres
   */

  function selectFirstRow(button) {
    if(filterData[type].selected.first === button.secteurCode) {
      filterData.liste_eo.selected.first = null;
      return
    }

    filterData[type].selected.first = button.secteurCode
  }

  const getSortedRows = () => filterData[type].rows.first.sort((e1, e2) => e2.value - e1.value)

  return {
    filterData,
    isSelected,
    selectFirstRow,
    getSortedRows,
  }
}

export default useButtonFilterPS