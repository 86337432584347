import { ref, computed } from "vue"
import router from "@/router";

const START_YEAR = 2012

const YEAR_FILTER_TYPE = {
  SINGLE_YEAR: "SINGLE_YEAR",
  PERIOD: "PERIOD",
}
const filterType = ref(YEAR_FILTER_TYPE.SINGLE_YEAR)

const allYears = ref([])
const singleYearFilter = ref(null)
const periodFilter = ref(null)

// Initialisation des années
const initYearFilter = () => {
  if(allYears.value.length) return

  allYears.value = []
  const thisYear = new Date().getFullYear()
  for(let i = START_YEAR; i < thisYear + 1; i++) {
    allYears.value.unshift(i)
  }
  const qsYear = new URLSearchParams(window.location.search).get("year")
  if (!!qsYear && qsYear.length > 0 && allYears.value.includes(parseInt(qsYear))) {
    singleYearFilter.value = parseInt(qsYear)
  } else {
    singleYearFilter.value = allYears.value[0]
  }
}

const isSingleYearSelected = computed(() => filterType.value === YEAR_FILTER_TYPE.SINGLE_YEAR)
const isPeriodSelected = computed(() => filterType.value !== YEAR_FILTER_TYPE.SINGLE_YEAR)

const apiParameterYears = computed(() => {
  if(isSingleYearSelected.value) return [singleYearFilter.value - 1, singleYearFilter.value]
  else return periodFilter.value
})


const selectSingleYear = (selectedYear) => {
  filterType.value = YEAR_FILTER_TYPE.SINGLE_YEAR
  singleYearFilter.value = selectedYear

  router.push({
    path: window.location.pathname,
    query: { year: selectedYear }
  }).then()
}

function reset() {
  filterType.value = YEAR_FILTER_TYPE.SINGLE_YEAR
  singleYearFilter.value = allYears.value[0]
  periodFilter.value = null
}

/**
 * ----------------------
 * DISPONIBLES DANS L'APP
 * ----------------------
 */

const useYearFilter = () => {

  initYearFilter()

  return {
    allYears,
    isSingleYearSelected, isPeriodSelected,
    singleYearFilter,
    apiParameterYears,
    selectSingleYear, reset
  }
}

export default useYearFilter