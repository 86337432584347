<template>
  <ProgressWidget
    :title-object="{
      title: 'Renouvellement',
    }"
    :composable="widget"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {
  clubFilter,
  regionFilter,
  yearFilter,
  licenceFilter,
  genreFilterDev, ageFilterDev
} from '@/composables/useFilters'
import {API_PARAMS, rawComparator} from "@/composables/widgets/_widgetUtils";
import ProgressWidget from "@/components/widgets/templates/ProgressWidget.vue";

const widget = useWidget("RenouvellementWidget Simple", {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    ageFilterDev.selectedMinAge,
    ageFilterDev.selectedMaxAge,
    licenceFilter.selectedLicence,
    genreFilterDev.selectedGenre
  ],

  query: {
    url: 'indicateurs/global/titres/renouvellement',
    params: [
      API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
      API_PARAMS.AGE_DEV, API_PARAMS.GENRE_DEV,
      API_PARAMS.A_DATE,
    ]
  },

  response: ([comparisonYear, selectedYear]) => {
    const renew = selectedYear.find(d => licenceFilter.selectedLicence.value.apiId === d.classeLicence)?.renouvellement
    const compRenew = comparisonYear.find(d => licenceFilter.selectedLicence.value.apiId === d.classeLicence)?.renouvellement

    return ({
      value: renew,
      comparator: rawComparator(renew, compRenew)
    })
  }
})
</script>