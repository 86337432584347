<!--
==========
FLIP BUTTON
==========

Widget "bouton" permettant de cacher le chart 

-->
<template>
    <button class="icon-right absolute" @click="flipAnim($event)">
    <span class="icon icon-hide"></span>
    <span class="icon icon-show"></span>
  </button>
</template>

<script setup>
const  flipAnim = (e) =>  e.target.closest('.flip-animation').classList.toggle("flip-container")
</script>