import { ref, onMounted, onUnmounted, watch } from "vue"
import {yearFilter, regionFilter, clubFilter, handiFilter} from "../useFilters"
import useButtonFilterPS from "../filters/useButtonFilterPS"
import Api from '@/composables/useApi'
import {getCurrentYear} from "@/composables/widgets/_widgetUtils";

const useButtonWidgetPS = (name, { type, indicator, endpoint, response }) => {
  const { filterData } = useButtonFilterPS(name, { type })

  const loaded = ref(false)
  const data = ref({})
  const watchers = ref([])
  const error = ref(null)

  onMounted(async () => {
    await fetchData(type, indicator)
    watchers.value.push(
      watch(yearFilter.singleYearFilter, (val, oldVal) => { if (val !== oldVal) fetchData(type, indicator) }),
      watch(regionFilter.selectedLigue, (val, oldVal) => { if (val !== oldVal) fetchData(type, indicator) }),
      watch(clubFilter.selectedClub, (val, oldVal) => { if (val !== oldVal) fetchData(type, indicator) }),
      watch(filterData, (val) => { if (val) fetchData(type, indicator) }),
      watch(handiFilter.isOn, (val, oldVal) => { if (val !== oldVal) fetchData(type, indicator) }),
    )
  })

  onUnmounted(() => {
    watchers.value.forEach((unwatch) => unwatch())
  })

  async function fetchData(type, indicator) { 
    loaded.value = false

    try {
      const query = {
        indicateur: indicator ? indicator.toString() : "",
        type: { type: type },
        discipline: { discipline: filterData[type].selected.first ? [filterData[type].selected.first] : [] },
        annees: {
          anneeDebut: yearFilter.singleYearFilter.value - 1,
          anneeFin: yearFilter.singleYearFilter.value,
        },
        supports: {
         support: [filterData[type].selected.third]
        },
        ligue: {
          ligue: regionFilter.selectedLigue.value
            ? regionFilter.selectedLigue.value.id
            : null
        },
        structures: {
          structureCodes: clubFilter.selectedClub.value
            ? [clubFilter.selectedClub.value.code]
            : []
        },
        aDate: {
          aDate: yearFilter.singleYearFilter.value === getCurrentYear(),
        },
        handi: {
          handi: handiFilter.isOn.value
        },
      }
      
      const res = await Api.post(endpoint, query)
      const comparisonYear = res.data.filter((el) => el.annee === yearFilter.singleYearFilter.value - 1)
      const selectedYear = res.data.filter((el) => el.annee === yearFilter.singleYearFilter.value)
      data.value = response([comparisonYear, selectedYear])
    }
    catch (err) {
      console.error(name, err)
      data.value = null
      error.value = err
    }

    loaded.value = true
  }

  return ({
    loaded,
    data,
    error
  })
}

export default useButtonWidgetPS
