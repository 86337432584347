<!--
  WIDGET Haute Performance -
  Alimentation des listes de haut niveau 
-->

<template>
  <SquareIndicatorsWidget
      v-if="yearFilter.singleYearFilter.value && widget.loaded.value"
      :composable="widget"
      :title-object="{
          title: 'Alimentation des listes HN',
          year: yearFilter.singleYearFilter
        }"
  />
</template>
      
<script setup>      
import { yearFilter } from '@/composables/useFilters'
import {buildDataset} from '@/composables/widgets/_widgetUtils'
import SquareIndicatorsWidget from "@/components/widgets/templates/SquareIndicatorsWidget.vue";
import useDiscAndSuppFilterWidget from "@/composables/widgets/useDiscAndSuppFilterWidget";
      
      
const widget = useDiscAndSuppFilterWidget("AlimentationListeHN", {
    type: null,
    endpoint: "/stats/IndicateursPerformance/alimentationlisteshautniveau",
    response: ([comparisonYear, selectedYear]) => {
        if (!selectedYear.length) return null
        return {
          data: buildDataset(
              selectedYear,
              comparisonYear,
              'listeLibelle',
              'nbAthletes',
              false,
              false,
              'ordre',
              false,
              false,
              true,
              (yearFilter.singleYearFilter.value - 1).toString()
          )
        }
    }
})
</script>