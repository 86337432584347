<template>
      <SimpleIndicatorWidget
        :composable="widget"
        :title-object="{ title: 'Structures affiliées' }"
      />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {clubFilter, regionFilter, yearFilter} from '@/composables/useFilters'
import {API_PARAMS, rawComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('ACCStructureWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub
  ],

  query: {
    url: 'indicateurs/dev/structuresaffiliees',
    params: [ API_PARAMS.ARRAY_YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE ],
  },

  response: ([comparisonYear, selectedYear]) => {
    const value = selectedYear.filter((e) => e.codeTypeStructure !== 'TI').reduce((a, b) => a + b.count, 0)

    const comparator = rawComparator(
      value,
      comparisonYear.filter((e) => e.codeTypeStructure !== 'TI').reduce((a, b) => a + b.count, 0),
      0,
      false,
      (yearFilter.singleYearFilter.value - 1).toString()
    )

    return { value, comparator }
  },
})
</script>