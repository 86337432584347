<template>
  
  <BaseWidget
      :title-object="{
        title: 'Labels FFVoile',
        alignLeft: true,
      }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value
          : undefined
      "
      :is-loading="!loaded"
      :front-error="hasError"
      :api-error="!hasApiData"
  >
      <div class="inter flex text-left" v-if="!displayNbStructures && widget.data.value.total.nbStructures === 0">
        La structure n’a pas de label FFVoile
      </div>
      <div v-else class="flex flex-col gap-y-8">
        <div class="flex flex-col gap-y-1 items-start">
          <SubIndicator
              v-if="displayNbStructures"
              :indicator-value="widget.data.value.total.nbStructures"
              label="structures labellisées"
              :evolution-object="{
                comparatorValue: widget.data.value.total.nbStructuresComp.value,
                isPercent: widget.data.value.total.nbStructuresComp.percent,
                comparison: widget.data.value.total.nbStructuresComp.comparison
              }"
              class="text-lg xl:text-xl"
              value-class="text-xl xl:text-2xl"
          />
          <SubIndicator
              v-if="displayNbStructures || (!displayNbStructures && widget.data.value.total.nbPersAccueillies !== 0)"
              label="personnes accueillies"
              :is-declarative="true"
              :indicator-value="widget.data.value.total.nbPersAccueillies"
              :evolution-object="{
                comparatorValue: widget.data.value.total.nbPersAccueilliesComp.value,
                isPercent: widget.data.value.total.nbPersAccueilliesComp.percent,
                comparison: widget.data.value.total.nbPersAccueilliesComp.comparison
              }"
              class="text-lg xl:text-xl"
              value-class="text-xl xl:text-2xl"
          />
        </div>

        <div class="flex flex-col gap-y-2 lg:gap-y-4 text-xs sm:text-sm lg:text-base max-sm:-mx-2">
          <div
              v-for="(value, key) in  widget.data.value.data"
              :key="key"
              v-show="displayNbStructures || (!displayNbStructures && value.nbStructures > 0)"
              class="flex items-center justify-center rounded-xl border border-greyBorder p-2 lg:px-4"
          >
            <a
                :href="value.link"
                target="_blank"
                class="flex justify-center w-1/4 2xl:w-3/8 h-fit"
            >
              <img
                  :alt="`${value.labelName}`"
                  :src="require(`@/assets/images/labelsFFVoile/${value.labelCode}.png`)"
                  :title="`${value.labelName}`"
              >
            </a>
            <div class="flex flex-col align-start ml-4 xl:ml-4 w-3/4 2xl:w-5/8">
              <SubIndicator
                  v-if="displayNbStructures"
                  :indicator-value="value.nbStructures"
                  label="Structures"
                  :evolution-object="{
                    comparatorValue: value.nbStructuresComp.value,
                    isPercent: value.nbStructuresComp.percent,
                    comparison: value.nbStructuresComp.comparison
                  }"
                  class="text-xs xl:text-sm"
              />
              <SubIndicator
                  v-if="displayNbStructures  || (!displayNbStructures && value.nbStructures > 0)"
                  label="Personnes accueillies"
                  :is-declarative="true"
                  :indicator-value="value.nbPersAccueillies"
                  :evolution-object="{
                    comparatorValue: value.nbPersAccueilliesComp.value,
                    isPercent: value.nbPersAccueilliesComp.percent,
                    comparison: value.nbPersAccueilliesComp.comparison
                  }"
                  class="text-xs xl:text-sm"
              />
            </div>
          </div>
      </div>
    </div>
  </BaseWidget>
</template>



<script setup>
import { computed } from 'vue'
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, getCurrentYear, percentComparator, rawComparator} from '@/composables/widgets/_widgetUtils'
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import SubIndicator from "@/components/common/SubIndicator.vue";

const displayNbStructures = computed(() => !clubFilter.selectedClub.value)
const hasError = computed(() => widget.error.value !== null)
const hasApiData = computed(() =>  !!widget.data.value)
const loaded = computed(() => widget.loaded.value)

const widget = useWidget('Labels Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub
  ],

  query: {
    url: 'indicateurs/dev/labels',
    params: [ API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE ],
  },

  response: ([comparisonYear, selectedYear]) => {
    const labels = [
        ['efv', 'École Française de Voile', 'https://www.efvoile.fr/'],
        ['eds', 'École de Sport', 'https://ecoledesport.ffvoile.fr/'],
        ['edc', 'École de Compétition', 'https://www.ffvoile.fr/ffv/web/services/developpement/competitions.asp'],
        ['csl', 'Club SPort Loisir', 'https://www.ffvoile.fr/ffv/web/services/developpement/loisir.asp'],
        ['plo', 'Point Location', 'https://pointlocation.ffvoile.fr/'],
        ['efc', 'École Française de Croisère', 'https://www.ffvoile.fr/ffv/web/services/developpement/labels.asp'],
        ['cpl', 'Coach Plaisance', 'https://www.ffvoile.fr/ffv/web/services/developpement/labels.asp']
    ].map(([labelCode, labelName, link]) => {
      const nbStructures = selectedYear[0] && selectedYear[0][labelCode]
      const prevNbStructures = comparisonYear[0] && comparisonYear[0][labelCode]
      const nbPersAccueillies = selectedYear.at(1) && selectedYear.at(1)[labelCode]
      const prevNbPersAccueillies = comparisonYear.at(1) && comparisonYear.at(1)[labelCode]

      return {
          labelCode,
          labelName,
          link,
          nbStructures: nbStructures,
          nbPersAccueillies: nbPersAccueillies,
          nbStructuresComp: rawComparator(
            nbStructures, prevNbStructures, 0, false, (yearFilter.singleYearFilter.value - 1).toString()
          ),
          nbPersAccueilliesComp: percentComparator(
            nbPersAccueillies, prevNbPersAccueillies, false, (yearFilter.singleYearFilter.value - 1).toString()
          )
        }
      }
    )

    const totalNbStructures = selectedYear[0] && selectedYear[0]['tousLabels']
    const totalPrevNbStructures = comparisonYear[0] && comparisonYear[0]['tousLabels']
    const totalNbPersAccueillies = selectedYear[1] && selectedYear[1]['tousLabels']
    const totalPrevNbPersAccueillies = comparisonYear[1] && comparisonYear[1]['tousLabels']

    return  {
      data: labels,
      total: {
        nbStructures: totalNbStructures,
        nbPersAccueillies: totalNbPersAccueillies,
        nbStructuresComp: rawComparator(
            totalNbStructures, totalPrevNbStructures, 0, false, (yearFilter.singleYearFilter.value - 1).toString()
        ),
        nbPersAccueilliesComp: percentComparator(
            totalNbPersAccueillies, totalPrevNbPersAccueillies, false, (yearFilter.singleYearFilter.value - 1).toString()
        )
      },
    }
  }
})
</script>