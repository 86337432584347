<template>
    <div class="spinner-container">
        <div class="loadingio-spinner-spin-a7gtasup1s8"><div class="ldio-idmpmqfsws">
        <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
        </div></div>
    </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
}
</script>
