<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{
          title: 'Licenciés régatiers',
        }"
  />
</template>


<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter  } from '@/composables/useFilters'
import {API_PARAMS, percentComparator, rawComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('PSNombreLicenciesClassesWidget', {
  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub
  ],

  query: {
    url: 'indicateurs/ps/regatiers',
    params: [API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE, API_PARAMS.A_DATE],
  },

  response: ([comparisonYear, selectedYear]) => {
    const sumForCurrentYear = selectedYear.reduce((partialSum, a) => partialSum + a.nbRegatiers, 0);
    const sumForComparisonYear = comparisonYear.reduce((partialSum, a) => partialSum + a.nbRegatiers, 0);

    const sumForCurrentYearLicencies = selectedYear.reduce((partialSum, a) => partialSum + a.nbLicencies, 0);
    const percentLicencies = Math.round((sumForCurrentYear / sumForCurrentYearLicencies) * 100)

    const sumForComparisonYearLicencies = comparisonYear.reduce((partialSum, a) => partialSum + a.nbLicencies, 0);
    const percentLicenciesComp = Math.round((sumForComparisonYear / sumForComparisonYearLicencies) * 100)

    const comparator = percentComparator(
        sumForCurrentYear,
        sumForComparisonYear
    )

    const additionalData = [{
      value: percentLicencies.toString() + '%',
      text: "des licenciés \"CLUB\"",
      relatif: "soit",
      comparator: rawComparator(
          percentLicencies,
          percentLicenciesComp,
      )
    }]
    return { value: sumForCurrentYear, comparator, additionalData }
  }

})
</script>
