<template>
  <BaseWidget
      :front-error="hasError"
      :api-error="!hasApiData"
      :is-loading="!loaded"
      :transparent="true"
  >
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="flex flex-col gap-y-4">
        <SimpleIndicatorWidget
          :composable="widget"
          :title-object="{
            title: 'Personnes accueillies',
            isDeclarative: true,
          }"
          :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
            ? yearFilter.singleYearFilter.value - 1
            : undefined
          "
        />
        <ImageComponent class="max-sm:hidden" src="https://media.ffvoile.fr/uploads/images/fullscreen/DSC_81617162_1a75b.jpg" />
      </div>
      <BaseWidget
        :title-object="{
          title: 'Publics cibles',
          isDeclarative: true,
        }"
        :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
            ? yearFilter.singleYearFilter.value - 1
            : undefined
          "
      >
        <BaseContainer
            v-for="{ name, data } in widget.data.value.detailedData"
            :key="name"
            :is-sub-container="true"
            class="gap-y-1"
        >
          <span class="uppercase inter-bold">{{ name }}</span>
          <SubIndicator
              v-for="indicator in data"
              :key="indicator.name"
              :label="indicator.name"
              :indicator-value="indicator.value"
              :evolution-object="{
                  comparatorValue: indicator.comparator.value,
                  isPercent: indicator.comparator.percent,
                  comparison: indicator.comparator.comparison,
                }"
          />
        </BaseContainer>
      </BaseWidget>
    </div>
  </BaseWidget>

</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, getCurrentYear, percentComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import SubIndicator from "@/components/common/SubIndicator.vue";
import {computed} from "vue";
import BaseContainer from "@/components/common/BaseContainer.vue";
import ImageComponent from "@/components/common/ImageComponent.vue";

const widget = useWidget('Accueil Widget', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
  ],

  query: {
    url: 'indicateurs/dev/publicscibles',
    params: [API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE]
  },

  response: ([comparisonYear, selectedYear]) => {
    comparisonYear = comparisonYear[0]
    selectedYear = selectedYear[0]

    const value = selectedYear ? selectedYear.nbPersonnesAccueillies : undefined

    const comparator =percentComparator(
        selectedYear? selectedYear.nbPersonnesAccueillies : undefined,
        comparisonYear ? comparisonYear.nbPersonnesAccueillies : undefined,
        false,
        (yearFilter.singleYearFilter.value === getCurrentYear())
          ? (yearFilter.singleYearFilter.value - 2).toString()
          : (yearFilter.singleYearFilter.value - 1).toString()
      )

    const detailedData = [
      {
        name: "scolaires",
        data: [
          {
            name: "Personnes accueillies",
            value: selectedYear ? selectedYear.nbScolaires : undefined,
            comparator: percentComparator(
                selectedYear ? selectedYear.nbScolaires : undefined,
                comparisonYear ? comparisonYear.nbScolaires : undefined,
                false,
                (yearFilter.singleYearFilter.value === getCurrentYear())
                    ? (yearFilter.singleYearFilter.value - 2).toString()
                    : (yearFilter.singleYearFilter.value - 1).toString()
            ),
          },
          {
            name: "Structures accueillantes",
            value: selectedYear ? selectedYear.nbStrScolaires : undefined,
            comparator: percentComparator(
                selectedYear ? selectedYear.nbStrScolaires : undefined,
                comparisonYear ? comparisonYear.nbStrScolaires : undefined,
                false,
                (yearFilter.singleYearFilter.value === getCurrentYear())
                    ? (yearFilter.singleYearFilter.value - 2).toString()
                    : (yearFilter.singleYearFilter.value - 1).toString()
            ),
          },
        ],
      },
      {
        name: "personnes en situation de handicap",
        data: [
          {
            name: "Personnes accueillies",
            value: selectedYear ? selectedYear.nbHdPersonnes : undefined,
            comparator: percentComparator(
                selectedYear ? selectedYear.nbHdPersonnes : undefined,
                comparisonYear ? comparisonYear.nbHdPersonnes : undefined,
                false,
                (yearFilter.singleYearFilter.value === getCurrentYear())
                    ? (yearFilter.singleYearFilter.value - 2).toString()
                    : (yearFilter.singleYearFilter.value - 1).toString()
            ),
          },
          {
            name: "Structures accueillantes",
            value: selectedYear ? selectedYear.nbStrHandi : undefined,
            comparator: percentComparator(
                selectedYear ? selectedYear.nbStrHandi : undefined,
                comparisonYear ? comparisonYear.nbStrHandi : undefined,
                false,
                (yearFilter.singleYearFilter.value === getCurrentYear())
                    ? (yearFilter.singleYearFilter.value - 2).toString()
                    : (yearFilter.singleYearFilter.value - 1).toString()
            ),
          },
        ],
      },
      {
        name: "personnes en difficulté",
        data: [
          {
            name: "Personnes accueillies",
            value: selectedYear ? selectedYear.nombrePublieDiffNB : undefined,
            comparator: percentComparator(
                selectedYear ? selectedYear.nombrePublieDiffNB : undefined,
                comparisonYear ? comparisonYear.nombrePublieDiffNB : undefined,
                false,
                (yearFilter.singleYearFilter.value === getCurrentYear())
                    ? (yearFilter.singleYearFilter.value - 2).toString()
                    : (yearFilter.singleYearFilter.value - 1).toString()
            ),
          },
          {
            name: "Structures accueillantes",
            value: selectedYear ? selectedYear.nbStrDiff : undefined,
            comparator: percentComparator(
                selectedYear ? selectedYear.nbStrDiff : undefined,
                comparisonYear ? comparisonYear.nbStrDiff : undefined,
                false,
                (yearFilter.singleYearFilter.value === getCurrentYear())
                    ? (yearFilter.singleYearFilter.value - 2).toString()
                    : (yearFilter.singleYearFilter.value - 1).toString()
            ),
          },
        ],
      },
    ]

    return { value, comparator, detailedData }
  },

})

const hasError = computed(() => widget.error.value !== null)
const hasApiData = computed(() =>  !!widget.data.value)
const loaded = computed(() => widget.loaded.value)
</script>
