import {toRef} from "vue"


const CATEGORIES = [
  { id: 'all', label: 'Tout âge',  ageBegin: null,  ageEnd: null, ordre: 0, isSelected: true, isCategory: true },
  { id: 'Jeunes', label: 'Jeunes',    ageBegin: 0 ,  ageEnd: 18, ordre: 1, isSelected: false, isCategory: true },
  { id: 'Adultes', label: 'Adultes',   ageBegin: 19,  ageEnd: 150, ordre: 2, isSelected: false, isCategory: true },
  { id: 'Poussins', label: 'Poussins',  ageBegin: 0 ,  ageEnd: 9, ordre: 1, isSelected: false, isSubCategory: true },
  { id: 'Benjamins', label: 'Benjamins', ageBegin: 10,  ageEnd: 11, ordre: 2, isSelected: false, isSubCategory: true },
  { id: 'Minimes', label: 'Minimes',   ageBegin: 12,  ageEnd: 14, ordre: 3, isSelected: false, isSubCategory: true },
  { id: 'Cadets', label: 'Cadets',    ageBegin: 15,  ageEnd: 18, ordre: 4, isSelected: false, isSubCategory: true },
  { id: 'Juniors', label: 'Juniors',   ageBegin: 19,  ageEnd: 21, ordre: 5, isSelected: false, isSubCategory: true },
  { id: 'Espoir', label: 'Espoir',    ageBegin: 22,  ageEnd: 25, ordre: 6, isSelected: false, isSubCategory: true },
  { id: 'Senior1', label: 'Senior1',   ageBegin: 26,  ageEnd: 30, ordre: 7, isSelected: false, isSubCategory: true },
  { id: 'Senior2', label: 'Senior2',   ageBegin: 31,  ageEnd: 35, ordre: 8, isSelected: false, isSubCategory: true },
  { id: 'Senior3', label: 'Senior3',   ageBegin: 36,  ageEnd: 45, ordre: 9, isSelected: false, isSubCategory: true },
  { id: 'Senior4', label: 'Senior4',   ageBegin: 46,  ageEnd: 55, ordre: 10, isSelected: false, isSubCategory: true },
  { id: 'Senior5', label: 'Senior5',   ageBegin: 56,  ageEnd: 65, ordre: 11, isSelected: false, isSubCategory: true },
  { id: 'Vétérans', label: 'Vétérans',  ageBegin: 66,  ageEnd: 150, ordre: 12, isSelected: false, isSubCategory: true },
]
const useAgeFilter = (categories = CATEGORIES.map(c => Object.assign({}, c))) => {

  const formatAgeRange = (minAge, maxAge) => {
    if ([0, null].includes(minAge) && [150, null].includes(maxAge)) {
      return ''
    }
    if ([0, null].includes(minAge)) {
      return '\u2264 ' + maxAge.toString()
    } else if ([150, null].includes(maxAge)) {
      return '\u2265 ' + minAge.toString()
    } else {
      return minAge.toString() + ' - ' + maxAge.toString()
    }
  }
  const getLongLabel = (e) => { // e: {label: string, ageBegin: number, ageEnd: number }
    const ageRange = formatAgeRange(e.ageBegin, e.ageEnd)
    return ageRange !== '' ? e.label + ' (' + formatAgeRange(e.ageBegin, e.ageEnd) + ')' : e.label
  }
  /**
   * STATE
   */
  const ageCategories = toRef(categories)
  const selectedMinAge = toRef(null)
  const selectedMaxAge = toRef(null)
  const tmpSelectedMinAge = toRef(null)
  const tmpSelectedMaxAge = toRef(null)

  /**
   * GETTERS
   */
  const getFilterTag = () => {
    const selectedCategories = ageCategories.value.filter(c => c.isSelected)
    return selectedCategories.length <= 1
      ? selectedCategories.map(c => getLongLabel(c)).join(', ')
      : `Âge : ${formatAgeRange(tmpSelectedMinAge.value, tmpSelectedMaxAge.value)}`
  }

  const isAllAgeSelected = () => {
    return [0, null].includes(selectedMinAge.value) && [150, null].includes(selectedMaxAge.value)
  }

  const isCategorySelected = () => {
    return ageCategories.value.filter(c => c.isCategory && c.isSelected).length > 0
  }

  function select(category) {
    let isSubSelected = false
    if (category.isCategory || isCategorySelected()) {
      tmpSelectedMinAge.value = category.ageBegin
      tmpSelectedMaxAge.value = category.ageEnd
      isSubSelected = !category.isCategory

    } else if (category.isSubCategory) {
      isSubSelected = true
      if (!category.isSelected) {
        if (tmpSelectedMinAge.value === category.ageEnd + 1
          || tmpSelectedMaxAge.value === category.ageBegin - 1
        ) {
          tmpSelectedMinAge.value = Math.min(tmpSelectedMinAge.value, category.ageBegin)
          tmpSelectedMaxAge.value = Math.max(tmpSelectedMaxAge.value, category.ageEnd)
        } else {
          tmpSelectedMinAge.value = category.ageBegin
          tmpSelectedMaxAge.value = category.ageEnd
        }
      } else {
        const selectedCategories = ageCategories.value.filter(c => c.id !== category.id && c.isSelected)
        if (selectedCategories.length === 0) {
          tmpSelectedMinAge.value = null
          tmpSelectedMaxAge.value = null
          isSubSelected = false
        } else if (tmpSelectedMinAge.value < category.ageBegin && tmpSelectedMaxAge.value > category.ageEnd) {
          tmpSelectedMinAge.value = category.ageBegin
          tmpSelectedMaxAge.value = category.ageEnd
        } else {
          tmpSelectedMinAge.value = Math.min(...selectedCategories.map(c => c.ageBegin))
          tmpSelectedMaxAge.value = Math.max(...selectedCategories.map(c => c.ageEnd))
        }
      }
    }

    ageCategories.value.forEach(c => {
      if (c.isCategory && !isSubSelected) {
        c.isSelected = (c.ageBegin === tmpSelectedMinAge.value && c.ageEnd === tmpSelectedMaxAge.value)
      } else if (c.isSubCategory && isSubSelected) {
        c.isSelected = (c.ageBegin <= tmpSelectedMaxAge.value && c.ageEnd >= tmpSelectedMinAge.value)
      } else {
        c.isSelected = false
      }
    })
  }

  const confirmSelection = () => {
    selectedMinAge.value = tmpSelectedMinAge.value
    selectedMaxAge.value = tmpSelectedMaxAge.value
  }

  function reset() {
    ageCategories.value.forEach((c, i) => c.isSelected = i === 0)
    selectedMinAge.value = null
    selectedMaxAge.value = null
    tmpSelectedMinAge.value = null
    tmpSelectedMaxAge.value = null
  }

  return {
    ageCategories, selectedMinAge, selectedMaxAge, // state
    isAllAgeSelected, getLongLabel, getFilterTag, // getters
    select, confirmSelection, reset // methods
  }
}

export default useAgeFilter