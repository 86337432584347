<template>
  <DonutWidget
    :title-object="{ title: 'Titres FFVoile' }"
    :composable="widget"
    :a-date="yearFilter.singleYearFilter.value === getCurrentYear()"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, buildDataset, getCurrentYear, percentComparator} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";

const widget = useWidget('LicenceWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub
  ],

  query: {
    url: 'indicateurs/global/titres',
    params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.A_DATE
    ],
    otherParams: {
      withPrimo: { withPrimo: true },
    }
  },

  response: ([comparisonYear, selectedYear]) => {
    const sumForCurrentYearLicences = selectedYear.reduce((partialSum, data) => partialSum + data.quantity, 0)
    const sumForComparisonYear = comparisonYear.reduce((partialSum, data) => partialSum + data.quantity, 0)

    const total = {
      value: sumForCurrentYearLicences,
      comparator: percentComparator(
          selectedYear ? sumForCurrentYearLicences : 0,
          comparisonYear ? sumForComparisonYear : 0
      )
    }

    const legend = buildDataset(
        selectedYear,
        comparisonYear,
        'licenceClasseLibelle',
        'quantity',
        true,
        false,
        'licenceClasseOrdre',
        false,
    )

    return {
      total,
      graphData: legend.map(l => ({...l, label: l.legend })),
    }
  },
})
</script>