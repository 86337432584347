<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{
          title: 'Nombre de personnes accueillies',
          isDeclarative: true,
        }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value - 1
          : undefined
      "
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, getCurrentYear, percentComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('Accueil Widget', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
  ],

  query: {
    url: 'indicateurs/dev/publicscibles',
    params: [API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE]
  },

  response: ([comparisonYear, selectedYear]) => {
    comparisonYear = comparisonYear[0]
    selectedYear = selectedYear[0]

    const value = selectedYear ? selectedYear.nbPersonnesAccueillies : undefined

    const comparator = percentComparator(
      selectedYear ? selectedYear.nbPersonnesAccueillies : undefined,
      comparisonYear ? comparisonYear.nbPersonnesAccueillies : undefined,
      false,
      (yearFilter.singleYearFilter.value === getCurrentYear())
          ? (yearFilter.singleYearFilter.value - 2).toString()
          : (yearFilter.singleYearFilter.value - 1).toString()
    )

    const additionalData = [
      {
        value: selectedYear ? selectedYear.nbScolaires : undefined,
        text: "scolaires",
        relatif: "dont",
        comparator: percentComparator(
          selectedYear ? selectedYear.nbScolaires : undefined,
          comparisonYear ? comparisonYear.nbScolaires : undefined,
          false,
          (yearFilter.singleYearFilter.value === getCurrentYear())
              ? (yearFilter.singleYearFilter.value - 2).toString()
              : (yearFilter.singleYearFilter.value - 1).toString()
        )
      },
      {
        value: selectedYear ? selectedYear.nbHdPersonnes : undefined,
        text: "en situation de handicap",
        comparator: percentComparator(
          selectedYear ? selectedYear.nbHdPersonnes : undefined,
          comparisonYear ? comparisonYear.nbHdPersonnes : undefined,
          false,
          (yearFilter.singleYearFilter.value === getCurrentYear())
              ? (yearFilter.singleYearFilter.value - 2).toString()
              : (yearFilter.singleYearFilter.value - 1).toString()
        )
      },
      {
        value: selectedYear ? selectedYear.nombrePublieDiffNB : undefined,
        text: "personnes en difficulté",
        comparator: percentComparator(
          selectedYear ? selectedYear.nombrePublieDiffNB : undefined,
          comparisonYear ? comparisonYear.nombrePublieDiffNB : undefined,
          false,
          (yearFilter.singleYearFilter.value === getCurrentYear())
              ? (yearFilter.singleYearFilter.value - 2).toString()
              : (yearFilter.singleYearFilter.value - 1).toString()
        )
      }
    ]

    return { value, comparator, additionalData }
  },

})
</script>
