<template>
  <DonutWidget
      :title-object="{
          title: 'Répartition des dirigeants',
          isDeclarative: true,
        }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value - 1
          : undefined
      "
      :composable="widget"
  />

</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, getCurrentYear, percentComparator} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";

const widget = useWidget('DirigeantsWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub
  ],

  query: {
    url: 'indicateurs/dev/rh/benevoles',
    params: [ API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE ]
  },

  response: ([comparisonYear, selectedYear]) => {
    comparisonYear = comparisonYear[0]
    selectedYear = selectedYear[0]

    const selectedTotal = selectedYear ? selectedYear.nbDirigeantsBenevoles : undefined
    const comparisonTotal = comparisonYear ? comparisonYear.nbDirigeantsBenevoles : undefined

    const total = {
      value: selectedTotal,
      hide: true,
      comparator: percentComparator(
          selectedTotal,
          comparisonTotal,
          false,
          (yearFilter.singleYearFilter.value === getCurrentYear())
              ? (yearFilter.singleYearFilter.value - 2).toString()
              : (yearFilter.singleYearFilter.value - 1).toString()
      )
    }

    const graphData = [
      {
        label: "Femme",
        value: selectedYear ? selectedYear.nbDirigeantsFeminins : undefined,
        comparator: percentComparator(
          selectedYear ? selectedYear.nbDirigeantsFeminins : undefined,
          comparisonYear ? comparisonYear.nbDirigeantsFeminins : undefined,
          false,
          (yearFilter.singleYearFilter.value === getCurrentYear())
              ? (yearFilter.singleYearFilter.value - 2).toString()
              : (yearFilter.singleYearFilter.value - 1).toString()
        )
      },
      {
        label: "Homme",
        value: selectedYear ? selectedYear.nbDirigeantsMasculins : undefined,
        comparator: percentComparator(
            selectedYear ? selectedYear.nbDirigeantsMasculins : undefined,
            comparisonYear ? comparisonYear.nbDirigeantsMasculins : undefined,
            false,
            (yearFilter.singleYearFilter.value === getCurrentYear())
                ? (yearFilter.singleYearFilter.value - 2).toString()
                : (yearFilter.singleYearFilter.value - 1).toString()
        )
      },
    ]

    return { total, graphData }
  }
})
</script>