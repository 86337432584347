import axios from 'axios'

const instance = axios.create({
  withCredentials: true,
  baseURL: window.VUE_APP_OLDAPI,
  headers: {
    "Content-Type": "application/json",
    Authorization: window.VUE_APP_OLDAPI_KEY
  }
})

const useApi = () => {
  async function get(url, options = {}) {
    try {
      return await instance.get(url, options)
    }
    catch(err) {
      return Promise.reject(err.response ? err.response.data.message : err)
    }
  }
  
  async function post(url, data) {
    try {
      return await instance.post(url, data)
    }
    catch(err) {
      return Promise.reject(err.response ? err.response.data.message : err)
    }
  }

  return {
    get, post
  }
}

export default useApi()