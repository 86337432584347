<template>
  <BaseWidget class="flex flex-col gap-y-4">
    <RadioButton
      :elements="[{label: 'Genre'}, {label: 'Catégorie d\'âge'}]"
      :on-button-click="(e) => toggleAffichageDonut = e.label === 'Genre'"
      :is-selected="(e) => e.label === 'Genre' ? toggleAffichageDonut : !toggleAffichageDonut"
    />
      <PSRepartitionClassesGenreWidget v-if="toggleAffichageDonut" transparent />
      <PSRepartitionClassesAgeWidget v-else transparent />
  </BaseWidget>
</template>

<script setup>
import { ref } from 'vue'
import RadioButton from "@/components/common/RadioButton.vue";
import PSRepartitionClassesGenreWidget
  from "@/components/pages/pratiques-sportives/widgets/repartition-classes/PSRepartitionClassesGenreWidget.vue";
import PSRepartitionClassesAgeWidget
  from "@/components/pages/pratiques-sportives/widgets/repartition-classes/PSRepartitionClassesAgeWidget.vue";
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
const toggleAffichageDonut = ref(true)


</script>

