<template>
  <BaseWidget
      :title-object="{ title: 'Titres FFVoile', alignLeft: true }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value
          : undefined
      "
      :a-date="yearFilter.singleYearFilter.value === getCurrentYear()"
      class="z-30"
  >
    <div class="flex flex-col gap-y-4">
      <div class="flex flex-col gap-y-4 -mt-1">
        <div class="flex justify-between items-center gap-x-2 gap-y-4 phone-all-column-start">
          <LicenceFilter />
          <div class="flex gap-x-2">
            <GenreFilter alignContentBox="right" />
            <AgeFilter alignContentBox="right" />
          </div>
        </div>
        <div
            v-if="getFilters().length > 0"
            class="flex gap-x-1 gap-y-1 wrap justify-end"
        >
          <FilterLabel
              v-for="[index, filter] in getFilters().entries()"
              :key="index"
              :label="filter[0]"
              :remove="filter[1]"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <LicenceTotalWidget :is-sub-container="true" />
        <RenouvellementWidget :is-sub-container="true" />
        <RepartitionGenreWidget :is-sub-container="true" />
        <RepartitionAgeWidget :is-sub-container="true"/>
      </div>
    </div>
  </BaseWidget>
</template>

<script setup>
import LicenceFilter from "@/components/pages/developpement/filtres/LicenceFilter.vue";
import GenreFilter from "@/components/pages/developpement/filtres/GenreFilter.vue";
import AgeFilter from "@/components/pages/developpement/filtres/AgeFilter.vue";
import FilterLabel from "@/components/common/FilterLabel.vue";
import LicenceTotalWidget from "@/components/pages/developpement/widgets/licence-widget/LicenceTotalWidget.vue";
import RenouvellementWidget from "@/components/pages/developpement/widgets/licence-widget/RenouvellementWidget.vue";
import RepartitionGenreWidget from "@/components/pages/developpement/widgets/licence-widget/RepartitionGenreWidget.vue";
import RepartitionAgeWidget from "@/components/pages/developpement/widgets/licence-widget/RepartitionAgeWidget.vue";

import {ageFilterDev, genreFilterDev, yearFilter} from '@/composables/useFilters'
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import {getCurrentYear} from "@/composables/widgets/_widgetUtils";
const getFilters = () => {
  const filters = []
  if (!genreFilterDev.isAllSelected()) {
    genreFilterDev.selectedGenre.value.forEach((g) => {
      filters.push([g.label, () => genreFilterDev.select(g)])
    })

  }
  if (!ageFilterDev.isAllAgeSelected()) {
    filters.push([
      ageFilterDev.getFilterTag(),
      ageFilterDev.reset
    ])
  }
  return filters
}
</script>