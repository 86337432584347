<template>
  <DonutWidget
      :title-object="{
        title: 'Structures affiliées et indépendants conventionnés',
      }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value
          : undefined
      "
      :composable="widget"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, buildDataset, getCurrentYear, rawComparator} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";

const widget = useWidget('StructureWidget', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue
  ],

  query: {
    url: 'indicateurs/dev/structuresaffiliees',
    params: [ API_PARAMS.ARRAY_YEARS, API_PARAMS.LIGUE ]
  },

  response: ([comparisonYear, selectedYear]) => {
    const total = {
      value: selectedYear.reduce((a, b) => a + b.count, 0),
      comparator: {
        ...rawComparator(
          selectedYear.reduce((a, b) => a + b.count, 0),
          comparisonYear.reduce((a, b) => a + b.count, 0),
          0,
          false,
          (yearFilter.singleYearFilter.value - 1).toString()
        ),
      }
    }

    const legend = buildDataset(
        selectedYear,
        comparisonYear,
        'nomTypeStructure',
        'count',
        false,
        false,
        'count',
        true,
        false,
        true,
        (yearFilter.singleYearFilter.value - 1).toString()
    )

    return {
      total,
      graphData: legend.map(l => ({...l, label: l.legend})),
    }
  }
})
</script>