<template>
  <ToggleComponent
      text="Handivoile"
      :checked="isOn"
      :on-click="toggle"
      class="px-4 py-3.5 rounded-lg bg-white shadow font-regular"
  />
</template>

<script setup>

import ToggleComponent from "@/components/common/ToggleComponent.vue";
import {handiFilter} from "@/composables/useFilters";

const { isOn, toggle } = handiFilter
</script>