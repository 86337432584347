<!-- WIP -->
<template>
  <BaseWidget :composable="widget">
    <slot />
  </BaseWidget>
</template>
  
  <script setup>
  import useWidget from '@/composables/useWidget'
  import {clubFilter, yearFilter} from '@/composables/useFilters'
  import { API_PARAMS, percentComparator } from '@/composables/widgets/_widgetUtils'
  import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
  
  const widget = useWidget('AlimentationStructureNiveauSup', {
  
    watchers: [
      yearFilter.singleYearFilter,
      clubFilter.selectedPPFStructure
    ],
  
    query: {
      url: 'stats/IndicateursPerformance/alimentationstructures',
      params: [ API_PARAMS.YEARS ],
      otherParams: {structures: {structureCodes : [clubFilter.selectedPPFStructure.value.code]}}
    },
  
    response: ([comparisonYear, selectedYear]) => {
      const sumForCurrentYear = selectedYear.reduce((partialSum, a) => partialSum + a.alimStructNivSup, 0);
      const sumForComparisonYear = comparisonYear.reduce((partialSum, a) => partialSum + a.alimStructNivSup, 0);

      const comparator = percentComparator(
        sumForCurrentYear,
        sumForComparisonYear
      )
  
      const data = {
        value: sumForCurrentYear,
          comparator: percentComparator(
          selectedYear ? selectedYear.alimStructNivSup : 0,
          comparisonYear ? comparisonYear.alimStructNivSup : 0
        )
      }
  
      return (data, comparator)
    }
  })
  </script>