<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{
          title: 'Nombre d\'épreuves courues',
          isDeclarative: handiFilter.isOn.value,
          declarativeText: 'Chaque licencié est libre de déclarer son handicap ou non.',
        }"
  />
</template>


<script setup>
import useWidget from '@/composables/useWidget'
import {
  clubFilter,
  regionFilter,
  yearFilter,
  ageFilterPS,
  pratiqueFilter,
  genreFilterPS,
  handiFilter
} from '@/composables/useFilters'
import {API_PARAMS, percentComparator, rawComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";
import {computed} from "vue";

const widget = useWidget('PSNbrEpreuvesCouruesWidget', {
  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    ageFilterPS.selectedMinAge,
    ageFilterPS.selectedMaxAge,
    pratiqueFilter.selectedPratique,
    genreFilterPS.selectedGenre,
    handiFilter.isOn,
  ],

  query: [
    {
      name: "epreuvesCourues",
      url: 'indicateurs/ps/epreuves/courues',
      params: [
          API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
          API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS, API_PARAMS.AGE_PS,
          API_PARAMS.HANDI, API_PARAMS.A_DATE,
      ]
    },
    {
      name: "epreuvesCouruesHandi",
      url: 'indicateurs/ps/epreuves/courues',
      params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS, API_PARAMS.AGE_PS,
        API_PARAMS.A_DATE,
      ],
      otherParams: {
        handi: { handi: true }
      },
      requestCondition: computed(() => handiFilter.isOn.value === false),
    },
    {
      name: "licenciesClasses",
      url: 'indicateurs/ps/regatiers',
      params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.AGE_PS, API_PARAMS.PRATIQUE, API_PARAMS.GENRE_PS,
        API_PARAMS.HANDI, API_PARAMS.A_DATE,
      ]
    }
  ],

  response: (response) => {
    // Nombre d'épreuves courues
    const { responses: resultsEpreuvesCourues } = response.find(r => r.name === "epreuvesCourues")

    const comparisonYearData = resultsEpreuvesCourues[0][0]
    let selectedYearData = resultsEpreuvesCourues[1][0]
    const ecCurrentYear = selectedYearData?.nombreCoursesCourues || 0;
    const ecComparisionYear = comparisonYearData?.nombreCoursesCourues || 0;
    const ecComparator = percentComparator( ecCurrentYear, ecComparisionYear )

    // Nombre de licenciés régatiers
    const { responses: resultsLicenciesClasses } = response.find(r => r.name === "licenciesClasses")
    const sumLicenciesClasses = (resultsLicenciesClasses[1].reduce((partialSum, a) => partialSum + a.nbRegatiers, 0));
    const sumLicenciesClassesComp = (resultsLicenciesClasses[0].reduce((partialSum, a) => partialSum + a.nbRegatiers, 0));
    const additionalData = [
      {
        value: Math.round(ecCurrentYear * 10 / sumLicenciesClasses) / 10,
        text: handiFilter.isOn.value ? "épreuves par régatier en situation de handicap" : "épreuves par régatier en moyenne",
        relatif: "soit",
        comparator: rawComparator(
            Math.round(ecCurrentYear * 10 / sumLicenciesClasses) / 10,
            Math.round(ecComparisionYear * 10 / sumLicenciesClassesComp) / 10,
            1
        )
      }
    ]

    //Nombre d'épreuves courues handi
    if (handiFilter.isOn.value === false) {
      const {responses: resultsEpreuvesCouruesHandi} = response.find(r => r.name === "epreuvesCouruesHandi")
      let comparisonYearDataHandi = resultsEpreuvesCouruesHandi.at(0).at(0)
      let selectedYearDataHandi = resultsEpreuvesCouruesHandi.at(1).at(0)
      const ecHandiCurrentYear = selectedYearDataHandi?.nombreCoursesCourues || 0;
      const ecHandiComparisionYear = comparisonYearDataHandi?.nombreCoursesCourues || 0;
      const ecHandicomparator = percentComparator(ecHandiCurrentYear, ecHandiComparisionYear)

      additionalData.push({
        value: ecHandiCurrentYear,
        text: "par des régatiers en situation de handicap",
        relatif: "dont",
        comparator: ecHandicomparator,
        isDeclarative: true,
        declarativeText: 'Chaque licencié est libre de déclarer son handicap ou non.',
      })
    }
    return { value: ecCurrentYear, comparator: ecComparator, additionalData }
  }

})
</script>
