import {toRef} from "vue";
import OldApi from '@/composables/useOldApi'


const useAuthorization = () => {
    const isAuthorized = toRef(null)
    const mustRedirect = toRef(false)
    const displayNoAccessPopup = toRef(false)

    async function checkAuthorization () {
        try {
            const { data } = await OldApi.get('/Identifications/UserInfo')
            isAuthorized.value = data.Acces
            displayNoAccessPopup.value = !data.Acces
            mustRedirect.value = false
        }
        catch (e) {
            displayNoAccessPopup.value = true
            mustRedirect.value = true
        }
    }

    return {
        isAuthorized,
        mustRedirect,
        displayNoAccessPopup,
        checkAuthorization
    }

}

export const authorization = useAuthorization()