<!--

  HORIZONTALE BARRE

-->

<template>
  <Bar
      id="my-chart-id"
      :data="{
        labels: data.map(x => x.legend),
          datasets: [
            {
              backgroundColor: ['#1F2F6B'],
              data: data,
              parsing: {
                yAxisKey: 'value',
                xAxisKey: 'value'
              },
              datalabels: {
                formatter: (value) => {
                  if (!value.comparator || (!value.comparator.value  && value.comparator.value !== 0) ) {
                    return value.value.toString()
                  } else {
                    return value.value.toString() + `\u00A0(${value.comparator.value >= 0 ? '+' : ''}${value.comparator.value}${value.comparator.percent ? '%' : ''})`
                  }
                }
              }
            }
          ]
      }"
      :options="horizontalBarOptions"
  />
</template>
  
<script setup>

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs'
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ChartDataLabels, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

defineProps({
  data: Object // { legend: String[],
})


const horizontalBarOptions = {
  responsive: true,

  indexAxis: "y",
  borderColor: 'white',
  borderWidth: 1,
  borderRadius: 5,
  minBarThickness: 25,
  maxBarThickness: 25,
  interaction: {
    intersect: false,
    mode: 'index',
    axis: 'x',
  },

  scales: {
    x: {
      grid: { display: false },
    }
  },
  plugins: {
    tooltip: {enabled: false},
    legend: { display: false },
    datalabels: {
      anchor: 'end',
      align: (context) => {
        const index = context.dataIndex;
        const maxValue = Math.max(...context.dataset.data.map(x => x.value))
        const value = context.dataset.data[index]
        return value.value < maxValue * 0.20 ? 'right' : 'left'
      },
      color: (context) => {
        const index = context.dataIndex;
        const maxValue = Math.max(...context.dataset.data.map(x => x.value))
        const value = context.dataset.data[index]
        return value.value < maxValue * 0.20 ? '#1F2F6B' : 'white'
      },
      font: {
        family: 'Inter-Medium',
      },
    }
  }

}
</script>
