<template>
  <DonutWidget
      v-show="genreFilterDev.selectedGenre.value.length !== 1"
      :title-object="{
        title: 'Genre',
      }"
      :composable="widget"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {
  clubFilter,
  regionFilter,
  yearFilter,
  licenceFilter,
  ageFilterDev,
  genreFilterDev
} from '@/composables/useFilters'
import {API_PARAMS, buildDataset} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";

function processLabel(label) {
  if(label === "M") return "Homme"
  else if(label === "F") return "Femme"
  else return "Non renseigné"
}

const widget = useWidget('RepartitionGenreWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    licenceFilter.selectedLicence,
    ageFilterDev.selectedMinAge,
    ageFilterDev.selectedMaxAge,
  ],

  query: {
    url: 'indicateurs/global/titres/repartition/sexe',
    params: [
      API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
      API_PARAMS.AGE_DEV, API_PARAMS.LICENCE,
      API_PARAMS.A_DATE,
    ],
  },

  response: ([comparisonYear, selectedYear]) => {

    const selectedTotal = selectedYear.length
      ? selectedYear.reduce((a, b) => a + b.quantity, 0)
      : 0

    const total = {
      value: selectedTotal,
      hide: true,
    }
    const legend = buildDataset(
        selectedYear.filter(
            data => data.classeLicence === licenceFilter.selectedLicence.value.apiId
        ).map(data => ({ sexe: processLabel(data.sexe), nbLicencies: data.quantity })),
        comparisonYear.filter(
            data => data.classeLicence === licenceFilter.selectedLicence.value.apiId
        ).map(data => ({ sexe: processLabel(data.sexe), nbLicencies: data.quantity })),
        'sexe',
        'nbLicencies',
        true,
        false,
        'sexe',
    )

    return {
      total,
      graphData: legend.map(l => ({...l, label: l.legend})),
    }
  }
})
</script>