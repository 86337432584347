<template>
  <BaseWidget
      :title-object="{...titleObject, alignLeft: true }"
      :front-error="hasError"
      :api-error="!hasApiData"
      :is-loading="!composable.loaded.value"
      class="!gap-y-2"
  >
    <div class="container-progressbar relative">
      <div class="progressvalue" :style="`width:${progressValue}%`">
        <span class="label flex items-center gap-x-1" :style="progressBarStyle">
          {{ progressValue }} %
          <EvolutionTag
              v-if="!!comparator"
              :comparator-value="comparator.value"
              :comparison="comparator.comparison || (yearFilter.singleYearFilter.value - 1).toString()"
              :is-subtag="true"
          />
        </span>

      </div>
      <div class="progressbar"></div>
    </div>
  </BaseWidget>
</template>
  
<script setup>
import { toRefs, computed } from 'vue'
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
import EvolutionTag from "@/components/common/EvolutionTag.vue";
import {yearFilter} from "@/composables/useFilters";
const props = defineProps({ composable: Object, titleObject: Object })
const { composable, titleObject } = toRefs(props)

const progressValue = computed(() => composable.value.data.value?.value)
const comparator = computed(() => composable.value.data.value.comparator)
const hasError = computed(() => composable.value.error.value !== null)
const hasApiData = computed(() =>  progressValue.value !== null && progressValue.value !== undefined)
const progressBarStyle = computed(() =>  progressValue.value >= 30 ? 'right: 1rem;' : 'left: calc(100% + 1rem); color:#1F2F6B')


</script>