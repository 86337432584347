<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <SimpleIndicatorWidget
        :title-object="{
          title: handiFilter.isOn.value ? 'Nombre d\'épreuves handivalides organisées' : 'Nombre d\'épreuves organisées',
          info: '• Une épreuve est comptabilisée si les résultats ont été remontés à la FFVoile\n\n' +
          '• Une épreuve handivalide est une épreuve courue sur un support spécifique handivalide',
        }"
        :composable="widget"
        :toggleObject="handiFilter.isOn.value ? undefined : {
          text: 'Répartition par pratique',
          checked: toggleAffichagePratique,
          onClick: () => toggleAffichagePratique = !toggleAffichagePratique,
        }"
    />
    <ImageComponent
        class="max-sm:hidden"
        style="height: unset !important"
        :src="handiFilter.isOn.value
          ? 'https://media.ffvoile.fr/uploads/images/fullscreen/_DSC75475c58.jpg'
          : 'https://media.ffvoile.fr/uploads/images/fullscreen/HD3Mo__CLR65483f27.jpg'" />
  </div>
  <BaseContainer
      v-show="!handiFilter.isOn.value && toggleAffichagePratique"
      transparent
      class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2"
      :class="{
        '!grid-cols-1': filterData.liste_eo.rows.first.length === 1,
        'md:!grid-cols-2': filterData.liste_eo.rows.first.length === 2,
        'xl:!grid-cols-3': filterData.liste_eo.rows.first.length === 3,
        '2xl:!grid-cols-4': filterData.liste_eo.rows.first.length === 4,
      }"
  >
    <FilterButtonIndicator
        v-for="button in getSortedRows()"
        :key="button.name"
        @click="handleSelectFirstRow(button)"
        :name="button.name"
        :value="button.value"
        :evolution-object="button.evolution ? {
          comparatorValue: button.evolution.value,
          isPercent: button.evolution.percent,
          comparison: button.evolution.comparison,
        } : null"
        :selected="isSelected('first', button)"
        :is-sub-indicator="true"
    />
  </BaseContainer>
</template>


<script setup>
import { ref } from 'vue'
import useWidget from '@/composables/useWidget'
import {clubFilter, handiFilter, regionFilter, yearFilter} from '@/composables/useFilters'
import {API_PARAMS, percentComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";
import BaseContainer from "@/components/common/BaseContainer.vue";
import FilterButtonIndicator from "@/components/common/FilterButtonIndicator.vue";
import useButtonFilterPS from "@/composables/filters/useButtonFilterPS";
import ImageComponent from "@/components/common/ImageComponent.vue";

const toggleAffichagePratique = ref(false)

const {
  filterData,
  isSelected,
  selectFirstRow,
  getSortedRows,
} = useButtonFilterPS('PSButtonFilter', { type: 'liste_eo' }, true)

function handleSelectFirstRow(el) {
  selectFirstRow(el)
}

const widget = useWidget('PSNbrEpreuvesOrganiseesWidget', {
  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    handiFilter.isOn,
  ],
  query: {
    url: 'indicateurs/ps/epreuves/organisees',
    params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.HANDI, API_PARAMS.A_DATE,
    ]
  },

  response: ([comparisonYear, selectedYear]) => {

    const sumForEpreuveOrga = selectedYear.reduce((partialSum, a) => partialSum + a.nombreEpreuves, 0);
    const sumForEpreuveOrgaComp = comparisonYear.reduce((partialSum, a) => partialSum + a.nombreEpreuves, 0);
    const sumForBoat = selectedYear.reduce((partialSum, a) => partialSum + a.bateauFourni, 0);
    const sumForBoatComp = comparisonYear.reduce((partialSum, a) => partialSum + a.bateauFourni, 0);
    const sumForHandi = selectedYear.reduce((partialSum, a) => partialSum + a.dontEpreuveHandi, 0);
    const sumForHandiComp = comparisonYear.reduce((partialSum, a) => partialSum + a.dontEpreuveHandi, 0);

    const additionalData = [{
      value: sumForBoat,
      text: "AVEC BATEAU FOURNIS",
      relatif: "dont",
      comparator: percentComparator(
          sumForBoat,
          sumForBoatComp,
      )
    }]

    if (handiFilter.isOn.value === false) {
      additionalData.push({
        value: sumForHandi,
        text: "épreuves handivalides",
        relatif: "dont",
        comparator: percentComparator(
           sumForHandi,
           sumForHandiComp,
        )
      })
    }

    return {
      value: sumForEpreuveOrga,
      comparator: percentComparator(
        sumForEpreuveOrga,
        sumForEpreuveOrgaComp
      ),
      additionalData : additionalData
    }
  },

})
</script>
