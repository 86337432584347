<template>
    <FilterSimple
        name="Pratique"
        :elements="pratiqueFilter.PRATIQUE.value ? (
              pratiqueFilter.PRATIQUE.value.map(
                  (p) => ({...p, isSelected: pratiqueFilter.isPratiqueTmpSelected(p)})
              )) : []"
        :select-element="pratiqueFilter.select"
        :confirm-selection="pratiqueFilter.confirmSelection"
    />
</template>
  
<script setup>
  import {onMounted} from "vue";
  import FilterSimple from "@/components/common/FilterSimple.vue";
  import { pratiqueFilter } from '@/composables/useFilters'

  onMounted(async () => {
    !pratiqueFilter.PRATIQUE.value &&
      await pratiqueFilter.fetchPratiques()
  })
</script>