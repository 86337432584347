<template>
  <FilterSimple
      name="Collectifs"
      :elements="collectifs.map((c) => ({
        ...c,
        label: c.collectif,
        isSelected: isCollectifTmpSelected(c),
      })).sort((c1, c2) => c1.ordre - c2.ordre)"
      :select-element="selectCollectif"
      :confirm-selection="confirmCollectifSelection"
      :reset="resetCollectifs"
  />
</template>
  
<script setup>
import FilterSimple from "@/components/common/FilterSimple.vue";

import { resultatFilter } from '@/composables/useFilters'

const {
  collectifs,
  isCollectifTmpSelected,
  selectCollectif,
  confirmCollectifSelection,
  resetCollectifs,
} = resultatFilter

</script>