<template>
  <BaseWidget
    :title-object="titleObject"
    :front-error="hasError"
    :api-error="!hasApiData"
    :is-loading="!composable.loaded.value"
  >
    <SimpleIndicator
        :indicator-value="composableData.value"
        :evolution-object="composableData.comparator ? {
                  comparatorValue: composableData.comparator.value,
                  isPercent: composableData.comparator.percent,
                  comparison: composableData.comparator.comparison
        } : null"/>

    <!-- ADDITIONAL DATA -->
    <div v-if="composableData.additionalData" class="flex flex-col text-sm -mx-2">
        <span
            v-if="!multipleRelatif && composableData.additionalData[0].relatif"
            class="inter-bold uppercase"
        >
          {{ composableData.additionalData[0].relatif }}
        </span>
        <div
            v-for="data in composableData.additionalData"
            :key="data.id"
            class="flex justify-center items-center"
        >
          <SubIndicator
              :label="data.text"
              :indicator-value="data.value"
              :evolution-object="data.comparator ? {
                    comparatorValue: data.comparator.value,
                    isPercent: data.comparator.percent,
                    comparison: data.comparator.comparison,
              } : null"
              :is-declarative="data.isDeclarative"
              :declarative-text="data.declarativeText"
              :prefix="multipleRelatif && data.relatif
                ? data.relatif
                : undefined
              "
          />
        </div>
    </div>
    <slot />
  </BaseWidget>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import SimpleIndicator from "@/components/common/SimpleIndicator.vue";
import SubIndicator from "@/components/common/SubIndicator.vue";
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";

const props = defineProps({
  composable: Object,
  titleObject: Object, // { title: String, year: Number, isDeclarative: Boolean },
})
const { composable, titleObject } = toRefs(props)

const composableData = computed(() => composable.value.data.value)
const multipleRelatif = computed(() => new Set(composableData.value.additionalData.map(d => d.relatif)).size > 1)
const hasError = computed(() => composable.value.error.value != null)
const hasApiData = computed(() => composable.value.data.value != null)

</script>
