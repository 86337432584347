<template>
  <BaseWidget :is-loading="!loaded">
    <div class="structure-projet--global flex1 row">
      <div class="structure-projet--global__details border-radius30 flex1 column">
        <p class="text-darkGrey text-lg font-inter-medium">Structures</p>
        <p class="text-3xl font-inter-bold mt-1">{{ data.total ? data.total.poles : 0 }}</p>
      </div>
      <div class="structure-projet--global__details border-radius30 flex1 column">
        <p class="text-darkGrey text-lg font-inter-medium">Athlètes</p>
        <p class="text-3xl font-inter-bold mt-1">{{ data.total ? data.total.athletes : 0}}</p>
      </div>
    </div>

    <div class="structure-projet--niveaux column-gap1 need-mT need-mB3">
      <div class="column align-center structure-projet--niveau">
        <div class="bg-blue width100 column align-center border-radius30 structure-projet--niveau--title">
          <p class="bold font-size2 need-pT2">Excellence</p>
          <div class="structure-projet--niveau--title__details width100">
            <div class="bg-white column align-center structure-projet--niveau--title__details--content">
              <p class="inter-regular font-size-8 blue-color need-pT">Structures</p>
              <p class="inter-medium font-size1-5 blue-color need-pT-5">{{ data.totalExcellence ? data.totalExcellence.poles : 0 }}</p>
            </div>
            <div class="bg-white column align-center structure-projet--niveau--title__details--content">
              <p class="inter-regular font-size-8 blue-color need-pT">Athlètes</p>
              <p class="inter-medium font-size1-5 blue-color need-pT-5">{{ data.totalExcellence ? data.totalExcellence.athletes : 0 }}</p>
            </div>
          </div>
        </div>
        <div class="column need-mT3 align-center text-center">
          <div v-for="result in data.excellence" :key="result.id">
            <p class="structure-projet--niveau--donnees__title regular need-pT2 blue-color font-size1-5 ">{{ result.ffvNiveauLibelle }}</p>
            <div class="row structure-projet--niveau--donnees align-end">
              <div class="structure-projet--niveau--donnees__details column align-center">
                <p class="inter-regular font-size-8 blue-color need-pT">Structures</p>
                <p class="inter-medium font-size1-5 blue-color need-pT-5">{{ result.nbPoles }}</p>
              </div>
              <div class="border-right blue-color"></div>
              <div class="structure-projet--niveau--donnees__details column align-center">
                <p class="inter-regular font-size-8 blue-color need-pT">Athlètes</p>
                <p class="inter-medium font-size1-5 blue-color need-pT-5">{{ result.nbAthletes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column align-center structure-projet--niveau">
        <div class="bg-darkblue width100 row center border-radius30 structure-projet--niveau--title">
          <p class="bold font-size2 need-pT2">National</p>
          <div class="structure-projet--niveau--title__details width100">
            <div class="bg-white column align-center structure-projet--niveau--title__details--content">
              <p class="inter-regular font-size-8 darkblue-color need-pT">Structures</p>
              <p class="inter-medium font-size1-5 darkblue-color need-pT-5">{{ data.totalNational ? data.totalNational.poles : 0 }}</p>
            </div>
            <div class="bg-white column align-center structure-projet--niveau--title__details--content">
              <p class="inter-regular font-size-8 darkblue-color need-pT">Athlètes</p>
              <p class="inter-medium font-size1-5 darkblue-color need-pT-5">{{ data.totalNational ? data.totalNational.athletes : 0 }}</p>
            </div>
          </div>
        </div>
        <div class="column need-mT3 align-center text-center">
          <div v-for="result in data.national" :key="result.id">
            <p class="structure-projet--niveau--donnees__title need-pT2 darkblue-color regular font-size1-5">{{ result.ffvNiveauLibelle }}</p>
            <div class="row structure-projet--niveau--donnees align-end center">
              <div class="structure-projet--niveau--donnees__details column align-center">
                <p class="inter-regular font-size-8 darkblue-color need-pT">Structures</p>
                <p class="inter-medium font-size1-5 darkblue-color need-pT-5">{{ result.nbPoles }}</p>
              </div>
              <div class="border-right darkblue-color"></div>
              <div class="structure-projet--niveau--donnees__details column align-center">
                <p class="inter-regular font-size-8 darkblue-color need-pT">Athlètes</p>
                <p class="inter-medium font-size1-5 darkblue-color need-pT-5">{{ result.nbAthletes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column align-center structure-projet--niveau">
        <div class="bg-red width100 row center border-radius30 structure-projet--niveau--title">
          <p class="bold font-size2 need-pT2">Territorial</p>
          <div class="structure-projet--niveau--title__details width100">
            <div class="bg-white column align-center structure-projet--niveau--title__details--content">
              <p class="inter-regular font-size-8 text-red need-pT">Structures</p>
              <p class="inter-medium font-size1-5 text-red need-pT-5">{{ data.totalTerritorial ? data.totalTerritorial.poles : 0 }}</p>
            </div>
            <div class="bg-white column align-center structure-projet--niveau--title__details--content">
              <p class="inter-regular font-size-8 text-red need-pT">Athlètes</p>
              <p class="inter-medium font-size1-5 text-red need-pT-5">{{ data.totalTerritorial ? data.totalTerritorial.athletes : 0 }}</p>
            </div>
          </div>
        </div>
        <div class="column need-mT3 align-center text-center">
          <div v-for="result in data.territorial" :key="result.id">
            <p class="structure-projet--niveau--donnees__title need-pT2 text-red regular font-size1-5 text-center">{{ result.ffvNiveauLibelle }}</p>
            <div class="row structure-projet--niveau--donnees align-end center">
              <div class="structure-projet--niveau--donnees__details column align-center">
                <p class="inter-regular font-size-8 text-red need-pT">Structures</p>
                <p class="inter-medium font-size1-5 text-red need-pT-5">{{ result.nbPoles }}</p>
              </div>
              <div class="border-right text-red"></div>
              <div class="structure-projet--niveau--donnees__details column align-center">
                <p class="inter-regular font-size-8 text-red need-pT">Athlètes</p>
                <p class="inter-medium font-size1-5 text-red need-pT-5">{{ result.nbAthletes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseWidget>
</template>

<script setup>
import usePerfWidget from '@/composables/widgets/usePerfWidget'
import BaseWidget from "@/components/widgets/templates/BaseWidget.vue";
const { data, loaded } = usePerfWidget()
</script>