<template>
  <DonutWidget
      :title-object="{
        title: 'Catégorie d\'âge',
      }"
      :composable="widget"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import {
  clubFilter,
  regionFilter,
  yearFilter,
  licenceFilter,
  genreFilterDev, ageFilterDev
} from '@/composables/useFilters'
import { API_PARAMS, percentComparator } from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";

const widget = useWidget('RepartitionAgeWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
    licenceFilter.selectedLicence,
    genreFilterDev.selectedGenre,
    ageFilterDev.selectedMinAge,
    ageFilterDev.selectedMaxAge,
  ],

  query: {
    url: 'indicateurs/global/titres/repartition/age',
    params: [
        API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE,
        API_PARAMS.AGE_DEV, API_PARAMS.GENRE_DEV, API_PARAMS.LICENCE,
        API_PARAMS.A_DATE,
    ],
  },

  response: ([cYear, sYear]) => {
    const selectedYear = sYear.filter(
        data => data.classeLicence === licenceFilter.selectedLicence.value.apiId
    )
    const comparisonYear = cYear.filter(
        data => data.classeLicence === licenceFilter.selectedLicence.value.apiId
    )
    const isYoung = (data) => ["POUSSINS", "BENJAMINS", "MINIMES", "CADETS"].includes(data.categorie)
    const isAdult = (data) => ["JUNIORS", "ESPOIRS", "SENIORS 1", "SENIORS 2", "SENIORS 3", "SENIORS 4", "SENIORS 5", "VETERANS"].includes(data.categorie)

    const isNaN = (data) => !isYoung(data) && !isAdult(data)

    const selectedTotal = selectedYear.length
      ? selectedYear.reduce((a, b) => a + b.quantity, 0)
      : 0

    const total = {
      value: selectedTotal,
      hide: true,
    }
    
    const sJeunes = selectedYear.filter(isYoung)
    const sJeuneTotal = sJeunes.length ? sJeunes.reduce((a, b) => a + b.quantity, 0) : 0
    const cJeunes = comparisonYear.filter(isYoung)
    const cJeuneTotal = cJeunes.length ? cJeunes.reduce((a, b) => a + b.quantity, 0) : 0
    
    const sAdultes = selectedYear.filter(isAdult)
    const sAdulteTotal = sAdultes.length ? sAdultes.reduce((a, b) => a + b.quantity, 0) : 0
    const cAdultes = comparisonYear.filter(isAdult)
    const cAdulteTotal = cAdultes.length ? cAdultes.reduce((a, b) => a + b.quantity, 0) : 0

    const sNaN = selectedYear.filter(isNaN)
    const sNaNTotal = sNaN.length ? sNaN.reduce((a, b) => a + b.quantity, 0) : 0
    const cNaN = comparisonYear.filter(isAdult)
    const cNaNTotal = cNaN.length ? cNaN.reduce((a, b) => a + b.quantity, 0) : 0

    const graphData = [
      {
        label: "Adulte",
        value: sAdulteTotal,
        comparator: percentComparator(sAdulteTotal, cAdulteTotal)
      },
      {
        label: "Jeune",
        value: sJeuneTotal,
        comparator: percentComparator(sJeuneTotal, cJeuneTotal)
      },
    ]

    if (sNaNTotal > 0 || cNaNTotal > 0) {
      graphData.push({
        label: "Non renseigné",
        value: sNaNTotal,
        comparator: percentComparator(sNaNTotal, cNaNTotal)
      })
    }

    return { total, graphData }
  }
})
</script>