<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{ title: 'Nombre de sportifs listés' }" />
</template>

<script setup>
import {clubFilter, regionFilter, yearFilter} from "@/composables/useFilters";

import {API_PARAMS, percentComparator} from "@/composables/widgets/_widgetUtils";
import useWidget from "@/composables/useWidget";
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('ListeHNWidget Simple',{
  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub,
  ],

  query: {
    url: '/stats/indicateursperformance/disciplineandsupport',
    params: [ API_PARAMS.YEARS, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE ],
    otherParams : { type : { type: 'liste_hn' } }
  },

  response: ([comparisonYear, selectedYear]) => {
    const sumForCurrentYearListeHN = selectedYear.reduce((acc, val) => acc + val.nbAthletes, 0)
    const sumForComparisonYear = comparisonYear.reduce((acc, val) => acc + val.nbAthletes, 0)

    const nbAthletesEspoirsCurrentYear = selectedYear.reduce((acc, val) => val.categorieId !== "ES" ? acc + val.nbAthletes : acc, 0)
    const nbAthletesEspoirsComparaisonYear = comparisonYear.reduce((acc, val) => val.categorieId !== "ES" ? acc + val.nbAthletes : acc, 0)


    const value = sumForCurrentYearListeHN
    const comparator = percentComparator(
          selectedYear ? sumForCurrentYearListeHN : 0,
          comparisonYear ? sumForComparisonYear : 0,
          false,
          (yearFilter.singleYearFilter.value - 1).toString()
      )
    const additionalData = [
        {
          value: selectedYear ? nbAthletesEspoirsCurrentYear : 0,
          text: "sportifs de haut niveau",
          relatif: "dont",
          comparator: percentComparator(
              selectedYear ? nbAthletesEspoirsCurrentYear : 0,
              comparisonYear ? nbAthletesEspoirsComparaisonYear : 0,
              false,
              (yearFilter.singleYearFilter.value - 1).toString()
          )
        },
      ]

    return {value, comparator, additionalData}
  }
})

</script>