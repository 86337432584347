<template>
    <DonutWidget
        :title-object="{
          title: 'Types de plan d\'eau',
          isDeclarative: true,
        }"
        :composable="widget"
        :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value - 1
          : undefined
        "
    />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, getCurrentYear, sortAlphabetically} from '@/composables/widgets/_widgetUtils'
import DonutWidget from "@/components/widgets/templates/DonutWidget.vue";

function processLabel(label) {
  if(label === "nbPlanDeauMer") return "Plan d'eau maritime"
  if(label === "nbPlanDeauInt") return "Plan d'eau intérieur"
  if(label === "nbPlanDeauMerEtInt") return "Les deux"
}

const widget = useWidget('PlandeauWidget Simple', {
  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue
  ],

  query: {
    url: 'indicateurs/dev/plansdeau',
    params: [ API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT, API_PARAMS.LIGUE ]
  },

  response: ([comparisonYear, selectedYear]) => {

    comparisonYear = comparisonYear.at(0) || {}
    if(comparisonYear) delete comparisonYear.anneeLicence
    
    selectedYear = selectedYear.at(0) || {}
    if(selectedYear) delete selectedYear.anneeLicence

    const legendLabels = Array.from(new Set(
      Array.from(Object.keys({...selectedYear, ...comparisonYear}))
    )).sort((l1, l2) => sortAlphabetically(l1, l2))
      
    const graphData = legendLabels.map(labelName => {
      const selectedData = selectedYear ? selectedYear[labelName] : undefined

      return {
        label: processLabel(labelName),
        value: selectedData,
      }
    })

    return { graphData }
  }
})
</script>