import { ref, onMounted, watch } from "vue"
import { yearFilter,regionFilter } from '../useFilters'
import Api from "../useApi"

export const usePerfWidget = () => {

  const watchers = ref([])
  const data = ref({})
  const loaded = ref(false)

  onMounted(() => {
    fetchData()
    watchers.value.push(watch(yearFilter.singleYearFilter, () => fetchData()))
    watchers.value.push(watch(regionFilter.selectedLigue, () => fetchData()))
  })

  async function fetchData() {
    loaded.value = false
    const res = await Api.post('/stats/IndicateursPerformance/structuresandlevels', {
      annees: {
        anneeDebut: yearFilter.singleYearFilter.value,
        anneeFin: yearFilter.singleYearFilter.value
      },
      supports: {
        support: []
      },
      discipline: {
        discipline: []
      },
      ligue: {
        ligue: regionFilter.selectedLigue.value ? regionFilter.selectedLigue.value.id: "",
      }
    })

    data.value = { 
      total: {
        poles: res.data.reduce((acc, val) => acc + val.nbPoles, 0),
        athletes: res.data.reduce((acc, val) => acc + val.nbAthletes, 0)
      },
      excellence: res.data.filter((el) => el.ansNiveau === "Excellence"),
      totalExcellence : {
        poles: res.data.filter((el) => el.ansNiveau === "Excellence").reduce((acc, val) => acc + val.nbPoles, 0),
        athletes: res.data.filter((el) => el.ansNiveau === "Excellence").reduce((acc, val) => acc + val.nbAthletes, 0),
      },
      national: res.data.filter((el) => el.ansNiveau === "National"),
      totalNational: {
        poles: res.data.filter((el) => el.ansNiveau === "National").reduce((acc, val) => acc + val.nbPoles, 0),
        athletes: res.data.filter((el) => el.ansNiveau === "National").reduce((acc, val) => acc + val.nbAthletes, 0),
      },
      territorial: res.data.filter((el) => el.ansNiveau === "Territorial"),
      totalTerritorial: {
        poles: res.data.filter((el) => el.ansNiveau === "Territorial").reduce((acc, val) => acc + val.nbPoles, 0),
        athletes: res.data.filter((el) => el.ansNiveau === "Territorial").reduce((acc, val) => acc + val.nbAthletes, 0)
      },
    }
    loaded.value = true
  }

  return {
    data,
    loaded
  }
}

export default usePerfWidget