<template>
  <SimpleIndicatorWidget
      :composable="widget"
      :title-object="{
          title: 'Chiffre d\'affaires',
          isDeclarative: true,
        }"
      :yearInTitle="yearFilter.singleYearFilter.value === getCurrentYear()
          ? yearFilter.singleYearFilter.value - 1
          : undefined
      "
      class="!bg-darkBlue !text-white !fill-white"
  />
</template>

<script setup>
import useWidget from '@/composables/useWidget'
import { clubFilter, regionFilter, yearFilter } from '@/composables/useFilters'
import {API_PARAMS, formatNumber, getCurrentYear, percentComparator} from '@/composables/widgets/_widgetUtils'
import SimpleIndicatorWidget from "@/components/widgets/templates/SimpleIndicatorWidget.vue";

const widget = useWidget('RevenueWidget Simple', {

  watchers: [
    yearFilter.apiParameterYears,
    regionFilter.selectedLigue,
    clubFilter.selectedClub
  ],

  query: {
    url: 'indicateurs/dev/ca',
    params: [API_PARAMS.YEARS_WITH_PREVIOUS_FOR_CURRENT, API_PARAMS.LIGUE, API_PARAMS.STRUCTURE]
  },

  response: ([comparisonYear, selectedYear]) => {
    comparisonYear = comparisonYear.at(0) ? comparisonYear.at(0) : []
    selectedYear = selectedYear.at(0) ? selectedYear.at(0) : []

    let percentSub = Math.floor((selectedYear.subventions) / (selectedYear.budget) * 100)
    if (isNaN(percentSub)) percentSub = 0

    const value = (formatNumber(selectedYear.budget) || 0) + "\u00A0€"

    const comparator = percentComparator(
        selectedYear.budget || 0,
        comparisonYear.budget || 0,
        false,
        (yearFilter.singleYearFilter.value === getCurrentYear())
            ? (yearFilter.singleYearFilter.value - 2).toString()
            : (yearFilter.singleYearFilter.value - 1).toString()
    )

    const additionalData = [{
      value: percentSub + "\u00A0%",
      text: "de subventions",
      relatif: "dont",
    }]

    return { value, comparator, additionalData }
  }
})
</script>
